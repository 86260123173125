import apiClient from "@/api/apiClient";
import { ApplicationFilters } from "@/components/Applications/ApplicationFilters";
import { ApplicationGroupedViewItem } from "@/components/Applications/ApplicationGroupedViewItem";
import { GroupedView } from "@/components/GroupedView";
import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { ApplicationStatusContext } from "@/context/ApplicationStatusContext";
import { ViewMode } from "@/enums/ViewModeEnum";
import { useApplicationsTableColumns } from "@/hooks/table/useApplicationsTableColumns";
import { keepPreviousData, useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useContext, useMemo, useState } from "react";

export const ApplicationsTable = ({ viewMode }) => {
  const queryClient = useQueryClient();
  const { statuses } = useContext(ApplicationStatusContext);
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [columnVisibility, setColumnVisibility] = useState({});

  const handleDelete = (applicationId) => {
    deleteApplicationMutation.mutate(applicationId);
  };

  const handleEdit = async (applicationId, data) => {
    updateApplicationMutation.mutate({ applicationId, data });
  };

  const deleteApplicationMutation = useMutation({
    mutationFn: apiClient.deleteApplication,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["applications"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const updateApplicationMutation = useMutation({
    mutationFn: apiClient.updateApplication,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["applications"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zapisano" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const {
    isFetching,
    isLoading,
    data: response,
  } = useQuery({
    queryKey: ["applications", pagination, sorting, columnFilters],
    queryFn: () => apiClient.getApplications({ pagination, sorting, columnFilters }),
    placeholderData: keepPreviousData,
  });

  const { columns } = useApplicationsTableColumns(handleEdit, handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onColumnVisibilityChange: setColumnVisibility,
    getRowId: (row) => row.id,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
    },
  });

  return (
    <div className="w-full">
      {viewMode === ViewMode.TABLE.value && (
        <Table
          table={table}
          Filters={ApplicationFilters}
          meta={response?.meta}
          isLoading={isLoading}
          isFetching={isFetching}
        />
      )}
      {viewMode === ViewMode.GROUPED.value && (
        <GroupedView
          isFetching={isFetching}
          isLoading={isLoading}
          table={table}
          Filters={ApplicationFilters}
          meta={response?.meta}
          groupedData={response?.data?.reduce((acc, item) => {
            const key = item.status.name;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          }, {})}
          groupHeaders={statuses.map((status) => ({ name: status.name, color: status.color }))}
          renderItem={(application) => <ApplicationGroupedViewItem application={application} />}
        />
      )}
    </div>
  );
};
