import apiClient from "@/api/apiClient";
import { ContractFilters } from "@/components/Contracts/ContractFilters";
import { ContractGroupedViewItem } from "@/components/Contracts/ContractGroupedViewItem";
import { GroupedView } from "@/components/GroupedView";
import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { ContractStatusContext } from "@/context/ContractStatusContext";
import { ViewMode } from "@/enums/ViewModeEnum";
import { useContractsTableColumns } from "@/hooks/table/useContractsTableColumns";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useContext, useMemo, useState } from "react";

export const ContractsTable = ({ viewMode }) => {
  const queryClient = useQueryClient();
  const { statuses } = useContext(ContractStatusContext);
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([
    {
      id: "created_at",
      desc: true,
    },
  ]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const handleDelete = (contractId) => {
    deleteContractMutation.mutate(contractId);
  };

  const deleteContractMutation = useMutation({
    mutationFn: apiClient.deleteContract,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const { columns } = useContractsTableColumns(handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["contracts", pagination, sorting, columnFilters],
    queryFn: () => apiClient.getContracts({ pagination, sorting, columnFilters }),
    staleTime: 1000 * 60 * 5,
  });

  const table = useReactTable({
    data: isLoading ? [] : response.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
    },
  });

  return (
    <div className="w-full flex-col flex">
      {viewMode === ViewMode.TABLE.value && (
        <Table
          table={table}
          Filters={ContractFilters}
          meta={response?.meta}
          isFetching={isFetching}
          isLoading={isLoading}
        />
      )}
      {viewMode === ViewMode.GROUPED.value && (
        <GroupedView
          isFetching={isFetching}
          isLoading={isLoading}
          table={table}
          Filters={ContractFilters}
          meta={response?.meta}
          groupedData={response?.data?.reduce((acc, item) => {
            const key = item.status.name;
            if (!acc[key]) {
              acc[key] = [];
            }
            acc[key].push(item);
            return acc;
          }, {})}
          groupHeaders={statuses.map((status) => ({ name: status.name, color: status.color }))}
          renderItem={(contract) => <ContractGroupedViewItem contract={contract} />}
        />
      )}
    </div>
  );
};
