import { Badge } from "@/components/ui/badge";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export const DesktopMenuLink = ({ menuItem, isMenuExpanded, getActive }) => {
  const [expanded, setExpanded] = useState(false);

  useEffect(() => {
    if (!isMenuExpanded) setExpanded(false);
  }, [isMenuExpanded]);

  if (menuItem.visible) {
    if (menuItem.submenu?.length > 0) {
      return (
        <div className="w-full">
          <div
            onClick={() => setExpanded(!expanded)}
            className={cn(
              "flex items-center justify-between gap-2 mx-2 px-2 py-2 font-semibold hover:bg-border transition-colors duration-200 rounded-md cursor-pointer",
              getActive(menuItem) ? "bg-border" : "",
            )}
          >
            <div className="flex flex-row items-center">
              <menuItem.icon className="h-6 w-6 flex-shrink-0" />
              <span className={cn("ml-3 transition-transform duration-1000", isMenuExpanded ? "block" : "hidden")}>
                {menuItem.title}
              </span>
              {menuItem.count > 0 && isMenuExpanded && (
                <span className="flex flex-row items-center gap-2 ml-2">
                  <Separator orientation="vertical" className="bg-foreground h-4" />
                  <Badge>{menuItem.count}</Badge>
                </span>
              )}
            </div>
            {isMenuExpanded ? expanded ? <ChevronUp size={20} /> : <ChevronDown size={20} /> : null}
          </div>
          <div className="w-full flex flex-col">
            {expanded
              ? menuItem.submenu.map(
                  (submenuItem, index) =>
                    submenuItem.visible && (
                      <Link
                        key={submenuItem.title + index}
                        to={submenuItem.to}
                        className={`ml-4 mr-2 min-w-fit max-w-full px-3 mt-2 box-border py-2 hover:bg-border flex flex-row font-semibold items-center gap-2 rounded-md justify-between ${
                          getActive(submenuItem) ? "bg-border" : ""
                        }`}
                      >
                        <span className="flex flex-row gap-2 items-center">
                          <submenuItem.icon className="h-6 w-6 flex-shrink-0" />
                          <span
                            className={cn(
                              "ml-2 transition-transform duration-1000",
                              isMenuExpanded ? "block" : "hidden",
                            )}
                          >
                            {submenuItem.title}
                          </span>
                        </span>
                        {submenuItem.count > 0 && (
                          <>
                            <Separator orientation="vertical" className=" bg-foreground h-4" />
                            <Badge>{submenuItem.count}</Badge>
                          </>
                        )}
                      </Link>
                    ),
                )
              : null}
          </div>
        </div>
      );
    } else {
      return (
        <Link
          to={menuItem.to}
          className={cn(
            "flex items-center mx-2 px-2 py-2 font-semibold hover:bg-border transition-colors duration-200 rounded-md",
            getActive(menuItem) ? "bg-border" : "",
          )}
        >
          <menuItem.icon className="h-6 w-6 flex-shrink-0" />
          <span className={cn("ml-3 transition-transform duration-1000", isMenuExpanded ? "block" : "hidden")}>
            {menuItem.title}
          </span>
          {menuItem.count > 0 && isMenuExpanded && (
            <span className="flex flex-row items-center gap-2 ml-2">
              <Separator orientation="vertical" className="bg-foreground h-4" />
              <Badge>{menuItem.count}</Badge>
            </span>
          )}
        </Link>
      );
    }
  } else return null;
};
