import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { Form } from "@/components/forms/Form";
import { FormField } from "@/components/forms/FormField";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { FormRangesField } from "@/components/forms/FormRangesField";
import { FormSelect } from "@/components/forms/FormSelect";
import { ErrorToast } from "@/components/toast/ErrorToast";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";
import { toast } from "@/components/ui/use-toast";
import { attributeOptions } from "@/constants/attributeOptions";
import { operatorOptions } from "@/constants/operatorOptions";
import { CalculatorAdditionalCostType } from "@/enums/CalculatorAdditionalCostTypeEnum";
import i18n from "@/i18n";
import { useCalculatorPolicy } from "@/policies/useCalculatorPolicy";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Check, Pencil, X } from "lucide-react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

const validationSchema = Yup.object({
  name: Yup.string().required(i18n.t("Pole jest wymagane")),
  type: Yup.string().required(i18n.t("Pole jest wymagane")),
  price: Yup.number()
    .nullable()
    .when("type", {
      is: CalculatorAdditionalCostType.FIXED.value,
      then: () => Yup.number().required(i18n.t("Pole jest wymagane")),
    }),
  field: Yup.string().when("type", {
    is: (val) =>
      val === CalculatorAdditionalCostType.RANGE.value || val === CalculatorAdditionalCostType.CONDITIONAL.value,
    then: () => Yup.string().required(i18n.t("Pole jest wymagane")),
  }),
  range_conditions: Yup.array()
    .of(
      Yup.object({
        from: Yup.number().required(i18n.t("Wartość początkowa zakresu jest wymagana")),
        to: Yup.number().required(i18n.t("Wartość końcowa zakresu jest wymagana")),
        value: Yup.number().required(i18n.t("Wartość dla zakresu jest wymagana")),
      }),
    )
    .nullable()
    .when("type", {
      is: CalculatorAdditionalCostType.RANGE.value,
      then: () => Yup.array().min(1, i18n.t("Wymagane jest dodanie co najmniej jednego zakresu")),
    }),
  attribute: Yup.string().when("type", {
    is: CalculatorAdditionalCostType.CONDITIONAL.value,
    then: () => Yup.string().required(i18n.t("Pole jest wymagane")),
  }),
  operator: Yup.string().nullable().optional(),
  condition_price: Yup.string().when("type", {
    is: CalculatorAdditionalCostType.CONDITIONAL.value,
    then: () => Yup.string().required(i18n.t("Pole jest wymagane")),
  }),
  value: Yup.string().nullable().optional(),
  calculation_formula: Yup.string()
    .optional()
    .nullable()
    .when("type", {
      is: CalculatorAdditionalCostType.DYNAMIC.value,
      then: () =>
        Yup.string()
          .required("Pole jest wymagane")
          .matches(
            /^[a-zA-Z0-9_ *+/\-.]*$/,
            i18n.t("Formuła może zawierać tylko litery, cyfry i operatory matematyczne"),
          ),
    }),
  margin: Yup.number().min(0, i18n.t("Marża musi być większa lub równa 0")).required(i18n.t("Pole jest wymagane")),
});

export const CalculatorAdditionalCostEditDialog = ({ cost }) => {
  const queryClient = useQueryClient();
  const { id: calculatorId } = useParams();
  const calculatorPolicy = useCalculatorPolicy();

  const [isOpen, setIsOpen] = useState(false);

  const updateAdditionalCostMutation = useMutation({
    mutationFn: apiClient.updateCalculatorAdditionalCost,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["calculator", calculatorId] });
      if (res.ok) {
        form.reset();
        setIsOpen(false);
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const onSubmit = (values) => {
    let data = {};
    if (values.type === CalculatorAdditionalCostType.RANGE.value) {
      data = {
        range_conditions: {
          field: values.field,
          ranges: values.range_conditions.map((range) => ({
            from: range.from,
            to: range.to,
            price: range.value,
          })),
        },
        name: values.name,
        margin: values.margin,
        type: values.type,
      };
    }
    if (values.type === CalculatorAdditionalCostType.CONDITIONAL.value) {
      data = {
        condition: {
          field: values.field,
          operator: values.operator,
          attribute: values.attribute,
          value: values.value,
          price: values.condition_price,
        },
        name: values.name,
        margin: values.margin,
        type: values.type,
      };
    }
    if (
      values.type === CalculatorAdditionalCostType.FIXED.value ||
      values.type === CalculatorAdditionalCostType.DYNAMIC.value
    ) {
      data = values;
    }
    updateAdditionalCostMutation.mutate({ calculatorId, calculatorAdditionalCostId: cost.id, data });
  };

  const defaultValues = {
    name: cost.name,
    type: cost.type,
    price: cost.price,
    calculation_formula: cost.calculation_formula,
    range_conditions: cost.range_conditions?.ranges?.map((range) => ({
      from: range.from,
      to: range.to,
      value: range.price,
    })),
    margin: cost.margin,
    value: cost.condition?.value || cost.range_conditions?.price,
    operator: cost.condition?.operator,
    field: cost.condition?.field || cost.range_conditions?.field,
    attribute: cost.condition?.attribute,
    condition_price: cost.condition?.price,
  };

  const form = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: "onBlur",
  });
  const { type, attribute } = form.watch();

  useEffect(() => {
    if (type === CalculatorAdditionalCostType.FIXED.value) {
      form.setValue("range_conditions", undefined);
      form.setValue("calculation_formula", undefined);
      form.setValue("attribute", undefined);
      form.setValue("condition_price", undefined);
      form.setValue("value", undefined);
      form.setValue("operator", undefined);
      form.setValue("field", undefined);
    }
    if (type === CalculatorAdditionalCostType.RANGE.value) {
      form.setValue("calculation_formula", undefined);
      form.setValue("attribute", undefined);
      form.setValue("condition_price", undefined);
      form.setValue("value", undefined);
      form.setValue("operator", undefined);
      form.setValue("price", undefined);
    }
    if (type === CalculatorAdditionalCostType.CONDITIONAL.value) {
      form.setValue("range_conditions", undefined);
      form.setValue("condition_price", undefined);
      form.setValue("price", undefined);
    }
    if (type === CalculatorAdditionalCostType.DYNAMIC.value) {
      form.setValue("range_conditions", undefined);
      form.setValue("attribute", undefined);
      form.setValue("price", undefined);
      form.setValue("value", undefined);
      form.setValue("operator", undefined);
      form.setValue("field", undefined);
    }
  }, [type]);

  if (!calculatorPolicy.update()) return null;

  return (
    <Dialog open={isOpen} onOpenChange={() => setIsOpen(!isOpen)}>
      <DialogTrigger asChild>
        <Button variant="outline" title="Edytuj" leftIcon={<Pencil size={16} />} />
      </DialogTrigger>
      <DialogContent className="max-w-3xl">
        <DialogHeader>
          <DialogTitle>{i18n.t("Edycja kosztu dodatkowego")}</DialogTitle>
          <DialogDescription>{cost.name}</DialogDescription>
        </DialogHeader>
        <Form onSubmit={onSubmit} form={form}>
          <ScrollArea className="h-[40vh] pr-4">
            <div className="flex flex-col gap-4 px-1">
              <FormField label="Nazwa" placeholder="Nazwa kosztu" name="name" autoComplete="off" />
              <FormSelect options={CalculatorAdditionalCostType.getValues()} label="Typ kosztu" name="type" />
              {type === CalculatorAdditionalCostType.DYNAMIC.value && (
                <FormField
                  label="Wzór na liczenie wartości pola"
                  type="string"
                  name="calculation_formula"
                  autoComplete="off"
                />
              )}
              {type === CalculatorAdditionalCostType.FIXED.value && <FormNumberField label="Dolicz" name="price" />}
              {(type === CalculatorAdditionalCostType.RANGE.value ||
                type === CalculatorAdditionalCostType.CONDITIONAL.value) && (
                <FormField label="Jeśli" placeholder="identyfikator_pola" name="field" autoComplete="off" />
              )}
              {type === CalculatorAdditionalCostType.RANGE.value && (
                <FormRangesField
                  name="range_conditions"
                  label="Zakresy i ceny"
                  actionTitle="Dolicz (wzór lub wartość)"
                />
              )}
              {type === CalculatorAdditionalCostType.CONDITIONAL.value && (
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 items-center">
                  <FormSelect name="attribute" label="Atrybut" options={attributeOptions} />
                  {attribute !== "has_value" && attribute !== "doesnt_have_value" && (
                    <>
                      <FormSelect name="operator" label="Operator" options={operatorOptions} />
                      <FormField name="value" label="Wartość" placeholder="np. 10" />
                    </>
                  )}
                  <FormField name="condition_price" label="Dolicz" />
                </div>
              )}
              <FormNumberField label="Marża w %" name="margin" autoComplete="off" />
              <DialogFooter className="mt-5">
                <Button
                  type="submit"
                  title="Zapisz"
                  leftIcon={<Check size={20} />}
                  isLoading={updateAdditionalCostMutation.isPending}
                />
                <Button
                  type="button"
                  title="Anuluj"
                  leftIcon={<X size={20} />}
                  variant="destructive"
                  onClick={() => setIsOpen(false)}
                />
              </DialogFooter>
            </div>
          </ScrollArea>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
