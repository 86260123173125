import apiClient from "@/api/apiClient";
import { ProductFilters } from "@/components/Product/ProductFilters";
import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { useWarehouseProductsTableColumns } from "@/hooks/table/useWarehouseProductsTableColumns";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const WarehouseProductsTable = ({ warehouse }) => {
  const queryClient = useQueryClient();
  const { id: warehouseId } = useParams();
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const handleDelete = (productId) => {
    deleteWarehouseProductMutation.mutate({ warehouseId, productId });
  };

  const deleteWarehouseProductMutation = useMutation({
    mutationFn: apiClient.deleteWarehouseProduct,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      queryClient.invalidateQueries({ queryKey: ["warehouse", warehouse.id, "products"] });
      queryClient.invalidateQueries({ queryKey: ["warehouse", warehouse.id, "operations"] });
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto z magazynu." /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
    },
  });

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["warehouse", warehouseId, "products", pagination, sorting, columnFilters],
    queryFn: () => apiClient.getWarehouseProducts({ warehouseId, filters: { pagination, sorting, columnFilters } }),
  });

  const { columns } = useWarehouseProductsTableColumns(warehouse, handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
    },
  });

  return (
    <Table
      table={table}
      Filters={(props) => <ProductFilters withWarehouse={false} {...props} />}
      meta={response?.meta}
      isFetching={isFetching}
      isLoading={isLoading}
    />
  );
};
