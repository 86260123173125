import i18n from "@/i18n";
import { TriangleAlert } from "lucide-react";

export const WarningToast = ({ title }) => {
  return (
    <div className="flex flex-row gap-3">
      <TriangleAlert size={30} className="text-yellow-500 shrink-0" />
      {i18n.t(title)}
    </div>
  );
};
