import { ApplicationStatusCreateDialog } from "@/components/Applications/Settings/Statuses/ApplicationStatusCreateDialog";
import { ApplicationStatuses } from "@/components/Applications/Settings/Statuses/ApplicationStatuses";
import { ApplicationTypeCreateDialog } from "@/components/Applications/Settings/Types/ApplicationTypeCreateDialog";
import { ApplicationTypes } from "@/components/Applications/Settings/Types/ApplicationTypes";
import { ProcessCreateDialog } from "@/components/Process/ProcessCreateDialog";
import { ProcessSettings } from "@/components/Process/ProcessSettings";
import { SettingsTable } from "@/components/Settings/SettingsTable";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SettingGroup } from "@/enums/SettingGroupEnum";
import { useSettings } from "@/hooks/useSettings";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { useApplicationStatusPolicy } from "@/policies/application/useApplicationStatusPolicy";
import { useApplicationTypePolicy } from "@/policies/application/useApplicationTypePolicy";
import { useProcessPolicy } from "@/policies/useProcessPolicy";
import { useSettingPolicy } from "@/policies/useSettingPolicy";

export const ApplicationSettings = () => {
  const { activeTab, setActiveTab } = useTabs("statuses");
  const { getSettingGroup } = useSettings();
  const applicationStatusPolicy = useApplicationStatusPolicy();
  const applicationTypePolicy = useApplicationTypePolicy();
  const processPolicy = useProcessPolicy();
  const settingPolicy = useSettingPolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList className="mb-6">
        {applicationStatusPolicy.create() && <TabsTrigger value="statuses">{i18n.t("Statusy")}</TabsTrigger>}
        {applicationTypePolicy.create() && <TabsTrigger value="sources">{i18n.t("Typy")}</TabsTrigger>}
        {processPolicy.create() && <TabsTrigger value="processes">{i18n.t("Procesy")}</TabsTrigger>}
        {settingPolicy.update() && <TabsTrigger value="settings">{i18n.t("Pozostałe")}</TabsTrigger>}
      </TabsList>
      {applicationStatusPolicy.create() && (
        <TabsContent value="statuses">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <ApplicationStatusCreateDialog />
          </div>
          <ApplicationStatuses />
        </TabsContent>
      )}
      {applicationTypePolicy.create() && (
        <TabsContent value="sources">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Typy wniosków")}</h2>
            <ApplicationTypeCreateDialog />
          </div>
          <ApplicationTypes />
        </TabsContent>
      )}
      {processPolicy.create() && (
        <TabsContent value="processes">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Application" />
          </div>
          <ProcessSettings model="Application" />
        </TabsContent>
      )}
      {settingPolicy.update() && (
        <TabsContent value="settings">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Pozostałe ustawienia")}</h2>
          </div>
          <SettingsTable settings={getSettingGroup(SettingGroup.APPLICATION)} />
        </TabsContent>
      )}
    </Tabs>
  );
};
