import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { DepartmentUsers } from "@/components/Departments/Details/DepartmentUsers";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { Button } from "@/components/Button";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { DepartmentEditDialog } from "@/components/Departments/DepartmentEditDialog";
import { DepartmentInformationCard } from "@/components/Departments/Details/DepartmentInformationCard";
import { DepartmentStats } from "@/components/Departments/Stats/DepartmentStats";
import { DateRangePicker } from "@/components/forms/DateRangePicker";
import { Layout } from "@/components/layout/Layout";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { useDepartmentPolicy } from "@/policies/useDepartmentPolicy";
import { routes } from "@/routes";
import { Pencil, Trash } from "lucide-react";

export const DepartmentDetailsPage = () => {
  const { id: departmentId } = useParams();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { activeTab, setActiveTab } = useTabs("information");
  const departmentPolicy = useDepartmentPolicy();

  const [dateRange, setDateRange] = useState({
    from: null,
    to: null,
  });

  const { isLoading, data: response } = useQuery({
    queryKey: ["department", departmentId],
    queryFn: () => apiClient.getDepartment(departmentId),
  });
  useDocumentTitle(response?.data ? `Oddział ${response?.data?.name}` : null);

  const deleteDepartmentMutation = useMutation({
    mutationFn: apiClient.deleteDepartment,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["departments"] });
      if (res.ok) {
        return navigate(routes.departments);
      }
    },
  });

  const handleDelete = () => {
    if (departmentPolicy.destroy(response.data)) {
      deleteDepartmentMutation.mutate(departmentId);
    }
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={response?.data?.name} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {activeTab === "stats" && (
            <DateRangePicker onSelect={setDateRange} selected={dateRange} placeholder="Wybierz zakres" />
          )}
          {activeTab === "information" && (
            <DepartmentEditDialog
              department={response?.data}
              trigger={<Button variant="outline" leftIcon={<Pencil size={16} />} title="Edytuj" />}
            />
          )}
          {departmentPolicy.destroy(response?.data) && (
            <DeleteAlertDialog
              trigger={
                <Button
                  variant="outline"
                  title="Usuń oddział"
                  isLoading={deleteDepartmentMutation.isPending}
                  leftIcon={<Trash size={16} />}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć ten oddział? Tej operacji nie można cofnąć"
            />
          )}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        {(departmentPolicy.viewStats(response?.data) || departmentPolicy.viewStats()) && (
          <TabsList className="mb-6">
            <TabsTrigger value="information">{i18n.t("Informacje")}</TabsTrigger>
            {departmentPolicy.viewStats(response?.data) && (
              <TabsTrigger value="stats">{i18n.t("Statystyki")}</TabsTrigger>
            )}
          </TabsList>
        )}
        <TabsContent value="information">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <DepartmentInformationCard department={response?.data} />
            {departmentPolicy.viewUsers(response?.data) && <DepartmentUsers department={response?.data} />}
          </div>
        </TabsContent>
        {departmentPolicy.viewStats() && (
          <TabsContent value="stats">
            <DepartmentStats id={departmentId} dateRange={dateRange} />
          </TabsContent>
        )}
      </Tabs>
    </Layout>
  );
};
