import { Button } from "@/components/Button";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "@/components/ui/hover-card";
import { Skeleton } from "@/components/ui/skeleton";
import { daysOfTheWeek } from "@/helpers/actions/groupActions";
import { capitalize } from "@/helpers/capitalize";
import { cn } from "@/lib/utils";
import {
  addDays,
  addMonths,
  addWeeks,
  endOfMonth,
  endOfWeek,
  format,
  isAfter,
  isSameDay,
  isSameMonth,
  startOfMonth,
  startOfWeek,
  subMonths,
  subWeeks,
} from "date-fns";
import { ArrowLeft, ArrowRight, CalendarDays } from "lucide-react";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

export const CalendarView = ({
  events,
  variant = "month",
  Filters = null,
  setFilters = () => {},
  isLoading = false,
}) => {
  const navigate = useNavigate();
  const [currentDate, setCurrentDate] = useState(new Date());

  const nextPeriod = () => {
    setCurrentDate(variant === "month" ? addMonths(currentDate, 1) : addWeeks(currentDate, 1));
  };

  const prevPeriod = () => {
    setCurrentDate(variant === "month" ? subMonths(currentDate, 1) : subWeeks(currentDate, 1));
  };

  const days = useMemo(() => {
    let startDate, endDate;
    if (variant === "month") {
      startDate = startOfWeek(startOfMonth(currentDate));
      endDate = endOfWeek(endOfMonth(currentDate));
    } else {
      startDate = startOfWeek(currentDate);
      endDate = endOfWeek(currentDate);
    }

    const daysArray = [];
    let day = startDate;
    while (day <= endDate) {
      daysArray.push(day);
      day = addDays(day, 1);
    }

    return { startDate, endDate, daysArray };
  }, [currentDate, variant]);

  useEffect(() => {
    const startDateFormatted = format(days.startDate, "dd-MM-yyyy");
    const endDateFormatted = format(days.endDate, "dd-MM-yyyy");

    setFilters({ startDate: startDateFormatted, endDate: endDateFormatted });
  }, [currentDate, setFilters, days.startDate, days.endDate]);

  const renderHeader = () => (
    <>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold">{capitalize(format(currentDate, "LLLL yyyy"))}</h2>
        <div className="flex flex-row gap-2 flex-wrap">
          {Filters && Filters}
          <Button variant="outline" title="Dzisiaj" onClick={() => setCurrentDate(new Date())} />
          <Button variant="outline" onClick={prevPeriod} leftIcon={<ArrowLeft size={20} />} />
          <Button variant="outline" onClick={nextPeriod} leftIcon={<ArrowRight size={20} />} />
        </div>
      </div>
      <div className="grid grid-cols-7 gap-2 text-center mb-1 text-muted-foreground">
        {daysOfTheWeek.map((day) => (
          <span key={day}>{day}</span>
        ))}
      </div>
    </>
  );

  const renderDays = () => (
    <div className="grid grid-cols-7 gap-2">
      {days.daysArray.map((day) => (
        <div
          key={day}
          className={cn(
            "p-2 border border-border rounded-md min-h-28",
            isSameMonth(day, currentDate) || variant === "week" ? "" : "bg-muted text-muted-foreground",
            isSameDay(day, new Date()) ? "border-primary border-2" : "",
          )}
        >
          <div className={cn("text-center font-bold", isSameDay(day, new Date()) ? "text-primary" : "")}>
            {format(day, "d")}
          </div>
          <div className="mt-2 flex flex-col gap-1">
            {events
              .filter((event) => isSameDay(new Date(event.date), day))
              .map((event, idx) => (
                <HoverCard key={"event_" + idx}>
                  <HoverCardTrigger asChild>
                    <Button
                      className={cn(
                        "w-full py-1 h-fit flex flex-row gap-1 items-center whitespace-break-spaces",
                        isSameMonth(event.date, currentDate) && isAfter(event.date, new Date())
                          ? event.className
                          : "bg-none",
                      )}
                      onClick={() => (event?.link ? navigate(event.link) : {})}
                      variant={"outline"}
                      title={event?.name}
                      leftIcon={event.icon && <event.icon size={20} />}
                    />
                  </HoverCardTrigger>
                  <HoverCardContent className="w-[300px]">
                    <div className="flex justify-between items-start space-x-4">
                      <div className="space-y-3">
                        <h4 className="text-sm font-semibold">{event.title}</h4>
                        <p className="text-sm">{event.description}</p>
                        <div className="flex items-center pt-2">
                          <CalendarDays className="mr-2 h-4 w-4 opacity-70" />{" "}
                          <span className="text-xs text-muted-foreground">{format(event.date, "PPPP, HH:mm")}</span>
                        </div>
                      </div>
                    </div>
                  </HoverCardContent>
                </HoverCard>
              ))}
          </div>
        </div>
      ))}
    </div>
  );

  const skeletonDays = () => (
    <div className="grid grid-cols-7 gap-2">
      {days.daysArray.map((day) => (
        <div
          key={day}
          className={cn(
            "p-2 border border-border rounded-md min-h-28",
            isSameMonth(day, currentDate) || variant === "week" ? "" : "bg-muted text-muted-foreground",
            isSameDay(day, new Date()) ? "border-primary border-2" : "",
          )}
        >
          <div className={cn("text-center font-bold", isSameDay(day, new Date()) ? "text-primary" : "")}>
            {format(day, "d")}
          </div>
          <div className="mt-2 flex flex-col gap-1">
            <Skeleton className="w-full h-7" />
            <Skeleton className="w-full h-7" />
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="mx-auto w-full">
      {renderHeader()}
      {isLoading ? skeletonDays() : renderDays()}
    </div>
  );
};
