import { useAuth } from "@/hooks/useAuth";

export const useLeadPolicy = () => {
  const { user, hasPermission, hasAnyPermission } = useAuth();

  const viewAny = () => {
    return hasAnyPermission(["access_all_leads", "access_department_leads", "access_own_leads"]);
  };

  const view = () => {
    return hasAnyPermission(["show_any_lead", "show_department_lead", "show_own_lead"]);
  };

  const create = () => {
    return hasPermission("create_lead");
  };

  const update = () => {
    return hasAnyPermission(["update_any_lead", "update_department_lead", "update_own_lead"]);
  };

  const updateStatus = () => {
    return hasPermission("update_leads_status");
  };

  const destroy = (lead) => {
    if (hasPermission("delete_any_lead")) {
      return true;
    }
    if (hasPermission("delete_department_lead")) {
      return lead?.user?.department?.id === user?.department?.id;
    }
    if (hasPermission("delete_own_lead")) {
      return user?.id === lead?.created_by?.id;
    }
  };

  const importLeads = () => {
    return hasPermission("import_leads");
  };

  const viewStats = () => {
    return hasPermission("access_lead_stats");
  };

  const bulkEditStatus = () => {
    return hasPermission("bulk_edit_lead_status");
  };

  const bulkDelete = () => {
    return hasPermission("bulk_delete_leads");
  };

  const bulkUnassignUser = () => {
    return hasPermission("bulk_unassign_lead_user");
  };

  const assignUserLeads = () => {
    return hasPermission("assign_user_leads");
  };

  const viewProcesses = () => {
    return hasPermission("access_lead_processes");
  };

  const updateProcess = () => {
    return hasPermission("update_lead_process");
  };

  return {
    viewAny,
    view,
    create,
    update,
    updateStatus,
    destroy,
    importLeads,
    viewStats,
    bulkEditStatus,
    bulkDelete,
    bulkUnassignUser,
    assignUserLeads,
    viewProcesses,
    updateProcess,
  };
};
