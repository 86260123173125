import apiClient from "@/api/apiClient";
import { BillingGroupFilters } from "@/components/Billings/Groups/BillingGroupFilters";
import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { toast } from "@/components/ui/use-toast";
import { useBillingGroupsTableColumns } from "@/hooks/table/useBillingGroupsTableColumns";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";

export const BillingGroupsTable = () => {
  const queryClient = useQueryClient();
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const [columnVisibility, setColumnVisibility] = useState({});

  const deleteBillingGroupMutation = useMutation({
    mutationFn: apiClient.deleteBillingGroup,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["billingGroups"] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Błąd podczas usuwania." /> });
      }
    },
  });

  const updateBillingGroupMutation = useMutation({
    mutationFn: apiClient.updateBillingGroup,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["billingGroups"] });
      queryClient.invalidateQueries({ queryKey: ["billings"] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleDelete = async (billingGroupId) => {
    deleteBillingGroupMutation.mutate({ billingGroupId });
  };

  const handleEdit = async (billingGroupId, data) => {
    updateBillingGroupMutation.mutate({ billingGroupId, data });
  };

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["billingGroups", pagination, columnFilters, sorting],
    queryFn: () => apiClient.getBillingGroups({ pagination, columnFilters, sorting }),
  });

  const { columns } = useBillingGroupsTableColumns(handleDelete, handleEdit);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onColumnVisibilityChange: setColumnVisibility,
    getRowId: (row) => row.id,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
      columnVisibility,
    },
  });

  return (
    <Table
      table={table}
      meta={response?.meta}
      isFetching={isFetching}
      isLoading={isLoading}
      Filters={BillingGroupFilters}
    />
  );
};
