import { Breadcrumbs } from "@/components/Breadcrumbs";
import { InstallationCrewCreateDialog } from "@/components/Installations/Crews/InstallationCrewCreateDialog";
import { InstallationCrewsTable } from "@/components/Installations/Crews/InstallationCrewsTable";
import { InstallationCreateDialog } from "@/components/Installations/InstallationCreateDialog";
import { InstallationsCalendar } from "@/components/Installations/InstallationsCalendar";
import { InstallationsTable } from "@/components/Installations/InstallationsTable";
import { InstallationsServicesMap } from "@/components/Installations/Map/InstallationsServicesMap";
import { InstallationServiceCreateDialog } from "@/components/Installations/Services/InstallationServiceCreateDialog";
import { InstallationServicesTable } from "@/components/Installations/Services/InstallationServicesTable";
import { InstallationSettings } from "@/components/Installations/Settings/InstallationSettings";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { ViewMode } from "@/enums/ViewModeEnum";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import { useViewModes } from "@/hooks/useVIewModes";
import i18n from "@/i18n";
import { useInstallationCrewPolicy } from "@/policies/installation/useInstallationCrewPolicy";
import { useInstallationStatusPolicy } from "@/policies/installation/useInstallationStatusPolicy";
import { useRef } from "react";

export const InstallationsAndServicesPage = () => {
  useDocumentTitle("Montaże i serwisy");
  const { activeTab, setActiveTab } = useTabs("installations");
  const { renderModeSwitcher, viewMode } = useViewModes(ViewMode.TABLE, [
    ViewMode.TABLE,
    ViewMode.MAP,
    ViewMode.GROUPED,
  ]);
  const installationStatusPolicy = useInstallationStatusPolicy();
  const installationCrewPolicy = useInstallationCrewPolicy();
  const bottomRef = useRef(null);

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {activeTab === "installations" && <InstallationCreateDialog />}
          {activeTab === "services" && <InstallationServiceCreateDialog />}
          {activeTab === "crews" && <InstallationCrewCreateDialog />}
          {activeTab === "installations" && renderModeSwitcher()}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        {(installationStatusPolicy.create() || installationCrewPolicy.viewAny()) && (
          <TabsList className="mb-6">
            <TabsTrigger value="installations">
              {viewMode === ViewMode.MAP.value ? i18n.t("Montaże i serwisy") : i18n.t("Montaże")}
            </TabsTrigger>
            {viewMode !== ViewMode.MAP.value && viewMode !== ViewMode.GROUPED.value && (
              <TabsTrigger value="services">{i18n.t("Serwisy")}</TabsTrigger>
            )}
            <TabsTrigger value="calendar">{i18n.t("Kalendarz")}</TabsTrigger>
            {installationCrewPolicy.viewAny() && <TabsTrigger value="crews">{i18n.t("Ekipy montażowe")}</TabsTrigger>}
            {installationStatusPolicy.create() && <TabsTrigger value="settings">{i18n.t("Ustawienia")}</TabsTrigger>}
          </TabsList>
        )}
        <TabsContent value="installations">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <InstallationsTable viewMode={viewMode} />
            {(activeTab === "installations" || activeTab === "services") && viewMode === ViewMode.MAP.value && (
              <InstallationsServicesMap />
            )}
          </div>
        </TabsContent>
        <TabsContent value="services">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <InstallationServicesTable />
          </div>
        </TabsContent>
        <TabsContent value="calendar">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <InstallationsCalendar />
          </div>
        </TabsContent>
        {installationCrewPolicy.viewAny() && (
          <TabsContent value="crews">
            <div className="flex flex-col lg:flex-row w-full gap-5">
              <InstallationCrewsTable />
            </div>
          </TabsContent>
        )}
        {installationStatusPolicy.create() && (
          <TabsContent value="settings">
            <InstallationSettings />
          </TabsContent>
        )}
      </Tabs>
      <div ref={bottomRef}></div>
    </Layout>
  );
};
