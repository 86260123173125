import { ProductsContext } from "@/context/ProductsContext";
import { ProductTypeContext } from "@/context/ProductTypeContext";
import { CalculatorFieldType } from "@/enums/CalculatorFieldTypeEnum";
import { Metadata } from "@/enums/MetadataEnum";
import { ProductType } from "@/enums/ProductTypeEnum";
import i18n from "@/i18n";
import { useContext } from "react";

export const useCalculatorCustomFields = () => {
  const { products } = useContext(ProductsContext);
  const { types: productTypes } = useContext(ProductTypeContext);

  const calculateSumForType = (fields, productTypeName, metadataName) => {
    const productType = productTypes.find((type) => type.name === productTypeName);
    if (!productType) return 0;

    return fields
      .filter((field) => field.type === CalculatorFieldType.PRODUCT.value && field.product_type === productType.id)
      .reduce((total, field) => {
        const product = products.find((product) => product.id === field.value);
        if (!product) return total;
        const metadata = product.metadata?.find((metadata) => metadata.name === metadataName);
        if (!metadata) return total;

        const quantity = field.quantity ?? 1;
        return total + parseFloat(metadata.value) * quantity;
      }, 0)
      .toFixed(2);
  };

  return {
    moc_instalacji: {
      name: i18n.t("Moc instalacji"),
      visible: true,
      description: i18n.t(
        `Pole dodatkowe. Wyliczane na podstawie pól typu Produkt z typem produktu "${ProductType.SOLAR_PANEL}" poprzez metadane o nazwie "${Metadata.POWER}".`,
      ),
      calculate: (fields) => calculateSumForType(fields, ProductType.SOLAR_PANEL, Metadata.POWER),
      productType: ProductType.SOLAR_PANEL,
      unit: "kWp",
    },
    pojemnosc_magazynow: {
      name: i18n.t("Pojemność magazynów energii"),
      visible: true,
      description: i18n.t(
        `Pole dodatkowe. Wyliczane na podstawie pól typu Produkt z typem produktu "${ProductType.ENERGY_STORAGE}" poprzez metadane o nazwie "${Metadata.CAPACITY}".`,
      ),
      calculate: (fields) => calculateSumForType(fields, ProductType.ENERGY_STORAGE, Metadata.CAPACITY),
      productType: ProductType.ENERGY_STORAGE,
      unit: "kWh",
    },
    bufory_pc_glikol: {
      name: i18n.t("Ilość glikolu wynikająca z bufora"),
      visible: false,
      description: i18n.t(
        `Pole dodatkowe. Wyliczane na podstawie pól typu Produkt z typem produktu "${ProductType.HEAT_PUMP_BUFFER}" poprzez metadane o nazwie "${Metadata.GLIKOL}".`,
      ),
      calculate: (fields) => calculateSumForType(fields, ProductType.HEAT_PUMP_BUFFER, Metadata.GLIKOL),
      productType: ProductType.HEAT_PUMP_BUFFER,
      unit: "L",
    },
  };
};
