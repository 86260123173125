import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { DateRangeFilter } from "@/components/Filters/DateRangeFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { PostalCodeFilter } from "@/components/Filters/PostalCodeFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { VoivodeshipFilter } from "@/components/Filters/VoivodeshipFilter";
import { Combobox } from "@/components/forms/Combobox";
import { Select } from "@/components/forms/Select";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { WarningAlertDialog } from "@/components/WarningAlertDialog";
import { LeadSelectedContext } from "@/context/LeadSelectedContext";
import { LeadSourceContext } from "@/context/LeadSourceContext";
import { LeadStatusContext } from "@/context/LeadStatusContext";
import { UsersContext } from "@/context/UsersContext";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import { useFilters } from "@/hooks/table/useFilters";
import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { useLeadSourcePolicy } from "@/policies/lead/useLeadSourcePolicy";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { CircleAlert, Pencil, User } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { FaUserLargeSlash } from "react-icons/fa6";

export const LeadFilters = ({ table, withActions = true, withColumns = true }) => {
  const leadPolicy = useLeadPolicy();
  const leadSourcePolicy = useLeadSourcePolicy();
  const { hasAnyPermission } = useAuth();
  const { statusOptions } = useContext(LeadStatusContext);
  const { sourceOptions, isLoading: isLoadingSources } = useContext(LeadSourceContext);
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);
  const { selected: mapSelected } = useContext(LeadSelectedContext);
  const tableSelected = table.getSelectedRowModel()?.flatRows;
  const queryClient = useQueryClient();
  const { filter, setFilter } = useFilters(table);

  const [showMassActions, setShowMassActions] = useState(false);
  const [assignedUserId, setAssignedUserId] = useState(undefined);

  const assignLeadsToUserMutation = useMutation({
    mutationFn: apiClient.assignLeadsToUser,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie przypisano kontakty do użytkownika" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
      queryClient.invalidateQueries({ queryKey: ["leads"] });
    },
  });

  const bulkDeleteLeadsMutation = useMutation({
    mutationFn: apiClient.bulkDeleteLeads,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
      queryClient.invalidateQueries({ queryKey: ["leads"] });
    },
  });

  const bulkEditStatusLeadsMutation = useMutation({
    mutationFn: apiClient.bulkEditStatusLeads,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zmieniono status" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
      queryClient.invalidateQueries({ queryKey: ["leads"] });
    },
  });

  const bulkUnassignUserLeadsMutation = useMutation({
    mutationFn: apiClient.bulkUnassignUserLeads,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie odpięto od użytkownika" /> });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak." /> });
      }
      queryClient.invalidateQueries({ queryKey: ["leads"] });
    },
  });

  const handleAssignLeadsToUser = (userId) => {
    if (tableSelected?.length > 0 || mapSelected?.length > 0) {
      assignLeadsToUserMutation.mutate({ userId, leadIds: tableSelected.map((s) => s.id).concat(mapSelected) });
      setAssignedUserId(userId);
    }
  };

  const handleBulkDelete = () => {
    if (tableSelected?.length > 0 || mapSelected?.length > 0) {
      bulkDeleteLeadsMutation.mutate({ leadIds: tableSelected.map((s) => s.id).concat(mapSelected) });
    }
  };

  const handleBulkEditStatus = (leadStatusId) => {
    if (tableSelected?.length > 0 || mapSelected?.length > 0) {
      bulkEditStatusLeadsMutation.mutate({
        leadIds: tableSelected.map((s) => s.id).concat(mapSelected),
        leadStatusId,
      });
    }
  };

  const handleUnassignUser = () => {
    if (tableSelected?.length > 0 || mapSelected?.length > 0) {
      bulkUnassignUserLeadsMutation.mutate({ leadIds: tableSelected.map((s) => s.id).concat(mapSelected) });
    }
  };

  useEffect(() => {
    if (assignLeadsToUserMutation.status === "success" || assignLeadsToUserMutation.status === "error") {
      const timeout = setTimeout(() => {
        setAssignedUserId(undefined);
      }, 2000);

      return () => clearTimeout(timeout);
    }
  }, [assignLeadsToUserMutation.status]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row flex-wrap gap-3 mb-3">
        <div className="flex flex-row gap-3 w-full lg:max-w-sm">
          <ClearFiltersButton table={table} />
          <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
        </div>
        <MultipleSelectFilter
          options={statusOptions}
          setValue={(value) => setFilter("status", value)}
          title="Status"
          value={filter("status")}
        />
        <VoivodeshipFilter filter={filter} setFilter={setFilter} />
        <PostalCodeFilter value={filter("postalCode")} onChange={(value) => setFilter("postalCode", value)} />
        {leadPolicy.viewStats() && (
          <DateRangeFilter
            value={filter("created")}
            setValue={(value) => setFilter("created", value)}
            placeholder="Zakres utworzenia"
          />
        )}
        {leadSourcePolicy.viewAny() && (
          <MultipleSelectFilter
            options={sourceOptions}
            setValue={(value) => setFilter("source", value)}
            title="Źródło"
            isLoading={isLoadingSources}
            value={filter("source")}
          />
        )}
        {leadPolicy.assignUserLeads() && (
          <MultipleSelectFilter
            options={appendEmptyOption(userOptions, "Nieprzypisane")}
            setValue={(value) => setFilter("user", value)}
            title="Użytkownik"
            isLoading={isLoadingUsers}
            value={filter("user")}
          />
        )}
        <div className="ml-auto flex flex-row gap-3">
          {withColumns && <ColumnFilter table={table} />}
          {withActions &&
            hasAnyPermission([
              "bulk_delete_leads",
              "bulk_unassign_lead_user",
              "bulk_edit_lead_status",
              "assign_user_leads",
            ]) && (
              <Button
                variant="outline"
                title="Akcje masowe"
                className="w-full lg:w-fit"
                onClick={() => setShowMassActions(!showMassActions)}
              />
            )}
        </div>
      </div>
      {showMassActions && (
        <div className="flex flex-row flex-wrap gap-3 mb-3">
          {leadPolicy.bulkUnassignUser() && (
            <WarningAlertDialog
              trigger={
                <Button
                  title="Odepnij od użytkownika"
                  className="flex-1 lg:flex-initial"
                  variant="outline"
                  leftIcon={<FaUserLargeSlash size={16} />}
                  disabled={tableSelected.length === 0 && mapSelected.length === 0}
                  isLoading={bulkUnassignUserLeadsMutation.isPending}
                />
              }
              message={
                <span>
                  <p>{i18n.t("Czy na pewno chcesz odpiąć zaznaczone kontakty od użytkowników?")}</p>
                  <p>
                    {i18n.t("Ilość zaznaczonych kontaktów to: ")}
                    {tableSelected.length + mapSelected.length}
                  </p>
                </span>
              }
              onConfirm={handleUnassignUser}
            />
          )}
          {leadPolicy.bulkEditStatus() && (
            <Select
              className="flex-1 lg:flex-initial w-fit font-medium"
              placeholder="Zmien status zaznaczonym"
              leftIcon={<Pencil size={16} className="mr-1" />}
              disabled={tableSelected.length === 0 && mapSelected.length === 0}
              options={statusOptions}
              setValue={handleBulkEditStatus}
              isLoading={bulkEditStatusLeadsMutation.isPending}
            />
          )}
          {leadPolicy.assignUserLeads() && (
            <Combobox
              placeholder="Przypisz do użytkownika"
              className="flex-1 lg:flex-initial lg:w-80 dark:text-foreground dark:bg-opacity-70 justify-center"
              variant="outline"
              leftIcon={<User size={16} />}
              options={userOptions}
              value={assignedUserId}
              disabled={tableSelected.length === 0 && mapSelected.length === 0}
              isLoading={isLoadingUsers || assignLeadsToUserMutation.isPending}
              setValue={handleAssignLeadsToUser}
            />
          )}
          {leadPolicy.bulkDelete() && (
            <DeleteAlertDialog
              trigger={
                <Button
                  title="Usuń zaznaczone"
                  className="flex-1 lg:flex-initial"
                  variant="outline"
                  leftIcon={<CircleAlert size={16} />}
                  disabled={tableSelected.length === 0 && mapSelected.length === 0}
                  isLoading={bulkDeleteLeadsMutation.isPending}
                />
              }
              message={
                <span>
                  <p>{i18n.t("Czy na pewno chcesz usunąć zaznaczone kontakty?")}</p>
                  <p>
                    {i18n.t("Ilość zaznaczonych kontaktów to: ")}
                    {tableSelected.length + mapSelected.length}
                  </p>
                </span>
              }
              onConfirm={handleBulkDelete}
            />
          )}
        </div>
      )}
    </div>
  );
};
