import { ApplicationContractsCard } from "@/components/Applications/ApplicationDetails/ApplicationContractsCard";
import { ApplicationInformationCard } from "@/components/Applications/ApplicationDetails/ApplicationInformationCard";
import { ApplicationNotesCard } from "@/components/Applications/ApplicationDetails/ApplicationNotesCard";
import { ApplicationProcessesCard } from "@/components/Applications/ApplicationDetails/ApplicationProcessesCard";
import { ApplicationFilesTable } from "@/components/Applications/ApplicationDetails/Files/ApplicationFilesTable";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { useApplicationFilePolicy } from "@/policies/application/useApplicationFilePolicy";
import { useApplicationNotePolicy } from "@/policies/application/useApplicationNotePolicy";

export const ApplicationDetails = ({ application }) => {
  const applicationNotePolicy = useApplicationNotePolicy();
  const applicationFilePolicy = useApplicationFilePolicy();
  const { activeTab, setActiveTab } = useTabs("information");

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      {applicationFilePolicy.viewAny() && (
        <TabsList className="mb-6">
          <TabsTrigger value="information">{i18n.t("Informacje")}</TabsTrigger>
          {applicationFilePolicy.viewAny() && <TabsTrigger value="files">{i18n.t("Pliki")}</TabsTrigger>}
        </TabsList>
      )}
      <TabsContent value="information">
        <div className="flex flex-col lg:flex-row w-full gap-5">
          <ApplicationInformationCard application={application} />
          <div className="flex flex-1 flex-col gap-5 h-fit">
            <ApplicationProcessesCard />
            {applicationNotePolicy.viewAny() && <ApplicationNotesCard />}
          </div>
          <div className="flex flex-1 flex-col gap-5 h-fit">
            <ApplicationContractsCard application={application} />
          </div>
        </div>
      </TabsContent>
      {applicationFilePolicy.viewAny() && (
        <TabsContent value="files">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ApplicationFilesTable key="files_list" />
          </div>
        </TabsContent>
      )}
    </Tabs>
  );
};
