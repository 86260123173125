import { Card } from "@/components/ui/card";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";

export const CalculationPriceSummaryCardContent = ({ calculator }) => {
  const appliedGrants = calculator?.grants?.filter((grant) => grant.applied) ?? [];
  const hasCustomValues = Object.values(calculator.customFields).some((field) => field.value);

  return (
    <div className="flex flex-col gap-3">
      <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
        <Card className="flex flex-col p-4">
          <p className="capitalize">{i18n.t("Netto")}</p>
          <p className="text-3xl font-bold">{formatMoney(calculator.netPrice)}</p>
        </Card>
        <Card className="flex flex-col p-4">
          <p className="capitalize">{appliedGrants.length > 0 ? i18n.t("Brutto bez dotacji") : i18n.t("Brutto")}</p>
          <p className="text-3xl font-bold">{formatMoney(calculator.grossPrice)}</p>
        </Card>
        <Card className="flex flex-col p-4">
          <p className="capitalize">{i18n.t("Stawka podatku VAT")}</p>
          <p className="text-3xl font-bold">{calculator.vat ? calculator.vat + " %" : i18n.t("Brak")}</p>
        </Card>
        {appliedGrants.length > 0 ? (
          <Card className="flex flex-col p-4 border-primary border-2">
            <p className="capitalize">{i18n.t("Brutto z dotacjami")}</p>
            <p className="text-3xl font-bold">{formatMoney(calculator.grossPriceAfterGrants)}</p>
          </Card>
        ) : (
          <Card className="flex flex-col p-4">
            <p className="capitalize">{i18n.t("Wartość podatku VAT")}</p>
            <p className="text-3xl font-bold">{formatMoney(calculator.grossPrice - calculator.netPrice)}</p>
          </Card>
        )}
      </div>
      {appliedGrants.length > 0 && (
        <div className="flex flex-col gap-1">
          <h2 className="text-sm font-semibold text-muted-foreground">{i18n.t("Dotacje")}</h2>
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
            {Object.values(calculator.groupedGrants).map((grant) => (
              <>
                <Card className="flex flex-col p-4 border-primary border-2">
                  <p className="capitalize">{`${i18n.t("Dotacja")} "${grant.name}"`}</p>
                  <p className="text-3xl font-bold">{formatMoney(grant.calculatedPrice)}</p>
                </Card>
              </>
            ))}
          </div>
        </div>
      )}
      {hasCustomValues && (
        <div className="flex flex-col gap-1">
          <h2 className="text-sm font-semibold text-muted-foreground">{i18n.t("Parametry")}</h2>
          <div className="grid grid-cols-1 gap-3 sm:grid-cols-2">
            {Object.values(calculator.customFields).map((field, index) =>
              field.value && field.visible
                ? field.value > 0 && (
                    <Card key={index} className="flex flex-col p-4">
                      <p className="capitalize">{field.name}</p>
                      <p className="text-3xl font-bold">
                        {field.value} {field.unit}
                      </p>
                    </Card>
                  )
                : null,
            )}
          </div>
        </div>
      )}
    </div>
  );
};
