import apiClient from "@/api/apiClient";
import { FileFilters } from "@/components/File/Filters/FileFilters";
import { ProductFileCreateDialog } from "@/components/Product/ProductDetails/Files/ProductFileCreateDialog";
import { Table } from "@/components/Table/Table";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { useProductFilesTableColumns } from "@/hooks/table/useProductFilesTableColumns";
import i18n from "@/i18n";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";

export const ProductFilesCard = () => {
  const { id: productId } = useParams();
  const queryClient = useQueryClient();

  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([
    {
      id: "original_name",
      desc: false,
    },
  ]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const deleteProductFileMutation = useMutation({
    mutationFn: apiClient.deleteProductFile,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["product", productId, "files"] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Błąd podczas usuwania." /> });
      }
    },
  });

  const handleDelete = async (fileId) => {
    deleteProductFileMutation.mutate({ productId, fileId });
  };

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["product", productId, "files", pagination, sorting, columnFilters],
    queryFn: () => apiClient.getProductFiles({ productId, filters: { pagination, sorting, columnFilters } }),
  });

  const { columns } = useProductFilesTableColumns(handleDelete);
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
    },
  });

  return (
    <Card className="flex-1 h-fit">
      <CardHeader className="flex flex-row justify-between items-center">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{i18n.t("Pliki")}</CardTitle>
          <CardDescription>{i18n.t("Pliki dołączone do tego produktu")}</CardDescription>
        </div>
        <ProductFileCreateDialog />
      </CardHeader>
      <CardContent className="">
        <Table
          table={table}
          Filters={FileFilters}
          meta={response?.meta}
          isFetching={isFetching}
          isLoading={isLoading}
        />
      </CardContent>
    </Card>
  );
};
