import api from "@/api/api";
import { formatDate, formatDateTime, formatFromToDates } from "@/helpers/formatDate";

const USERS_ENDPOINT = "/users/";
const DEPARTMENTS_ENDPOINT = "/departments/";
const LEADS_ENDPOINT = "/leads/";
const NOTES_ENDPOINT = "/notes/";
const ACTIONS_ENDPOINT = "/actions/";
const STATS_ENDPOINT = "/stats/";
const FILES_ENDPOINT = "/files/";
const TASKS_ENDPOINT = "/tasks/";
const CONTRACTS_ENDPOINT = "/contracts/";
const CALCULATIONS_ENDPOINT = "/calculations/";
const CHANGES_ENDPOINT = "/changes/";
const PAYMENTS_ENDPOINT = "/payments/";
const NOTIFICATIONS_ENDPOINT = "/notifications/";
const MESSAGES_ENDPOINT = "/messages/";
const DASHBOARD_ENDPOINT = "/dashboard/";
const PROCESSES_ENDPOINT = "/processes/";
const PROCESS_STEPS_ENDPOINT = "/steps/";
const COSTS_ENDPOINT = "/costs/";
const LEVELS_ENDPOINT = "/userLevels/";
const WAREHOUSES_ENDPOINT = "/warehouses/";
const PRODUCTS_ENDPOINT = "/products/";
const RELATIONS_ENDPOINT = "/relations/";
const BUNDLES_ENDPOINT = "/bundles/";
const OPERATIONS_ENDPOINT = "/operations/";
const ORDERS_ENDPOINT = "/orders/";
const ITEMS_ENDPOINT = "/items/";
const BILLINGS_ENDPOINT = "/billings/";
const BILLING_SETTINGS_ENDPOINT = "/billingSettings/";
const BILLING_SETTING_RULES_ENDPOINT = "/billingSettingRules/";
const BILLING_GROUPS_ENDPOINT = "/billingGroups/";
const METADATA_ENDPOINT = "/metadata/";
const CALCULATORS_ENDPOINT = "/calculators/";
const SECTIONS_ENDPOINT = "/sections/";
const CALCULATOR_SECTIONS_ENDPOINT = "/calculators/sections/";
const FIELDS_ENDPOINT = "/fields/";
const DEPENDENCIES_ENDPOINT = "/dependencies/";
const ADDITIONAL_COSTS_ENDPOINT = "/additional-costs/";
const CALENDAR_ENDPOINT = "/calendar/";
const INSTALLATIONS_ENDPOINT = "/installations/";
const INSTALLATION_SERVICES_ENDPOINT = "/installationServices/";
const INSTALLATION_CREWS_ENDPOINT = "/installationCrews/";
const CONTRACTS_PRODUCTS_ENDPOINT = "/contracts/products/";
const CONTRACTS_STATUSES_ENDPOINT = "/contracts/statuses/";
const LEADS_STATUSES_ENDPOINT = "/leads/statuses/";
const LEADS_SOURCES_ENDPOINT = "/leads/sources/";
const PRODUCTS_TYPES_ENDPOINT = "/products/types/";
const FILES_CATEGORIES_ENDPOINT = "/files/categories/";
const INSTALLATIONS_STATUSES_ENDPOINT = "/installations/statuses/";
const INSTALLATIONS_TYPES_ENDPOINT = "/installations/types/";
const AUDITS_ENDPOINT = "/audits/";
const AUDITS_STATUSES_ENDPOINT = "/audits/statuses/";
const APPLICATIONS_ENDPOINT = "/applications/";
const APPLICATION_STATUSES_ENDPOINT = "/applications/statuses/";
const APPLICATION_TYPES_ENDPOINT = "/applications/types/";
const CLIENTS_ENDPOINT = "/clients/";
const SETTINGS_ENDPOINT = "/settings/";
const CONTRACT_FILE_CATEGORIES_ENDPOINT = "/contractFileCategories/";
const SURVEYS_ENDPOINT = "/surveys/";
const QUESTIONS_ENDPOINT = "/questions/";
const SURVEY_SECTIONS_ENDPOINT = "/surveys/sections/";
const ENTRIES_ENDPOINT = "/entries/";
const SURVEY_ENTRIES_ENDPOINT = "/surveyEntries/";
const SIGNATURES_ENDPOINT = "/signatures/";
const CONTRACT_TEMPLATES_ENDPOINT = "/contractTemplates/";
const DISCOUNTS_ENDPOINT = "/discounts/";
const GRANTS_ENDPOINT = "/grants/";
const MODEL_VIEWS_ENDPOINT = "/modelViews/";

const transformFilters = (filters) => {
  if (!filters) return {};
  if (filters.pagination || filters.columnFilters || filters.sorting) {
    let queryFilters = {};
    queryFilters.page = filters?.pagination?.pageIndex ? filters?.pagination?.pageIndex + 1 : undefined;
    queryFilters.limit = filters?.pagination?.pageSize;
    filters?.columnFilters?.forEach((filter) => {
      if (typeof filter.value === "object" && !Array.isArray(filter.value) && filter.value !== null) {
        queryFilters[filter.id] = formatFromToDates(filter.value);
      } else {
        queryFilters[filter.id] = filter.value;
      }
    });
    filters.sorting?.forEach((sort) => {
      queryFilters.sort = sort.id;
      queryFilters.order = sort.desc === true ? "desc" : sort.desc === false ? "asc" : undefined;
    });
    return queryFilters;
  } else {
    return filters;
  }
};

const login = (email, password) => api.post("/auth/login", { email, password });

const impersonate = (userId) => api.post(`/users/${userId}/impersonate`);

const getPermissions = () => api.get("/permissions");

const logout = () => api.post("/auth/logout");

const authCheck = () => api.post("/auth/check");

const forgotPassword = ({ email }) => api.post("/forgot-password", { email });

const resetPassword = (data) => api.post("/reset-password", data);

const changePassword = (data) => api.put("/change-password", data);

const getUsers = (filters) => api.get(USERS_ENDPOINT, transformFilters(filters));

const getUser = (id) => api.get(USERS_ENDPOINT + id);

const getUserStats = (userId, filters) => {
  const queryFilters = {
    from: filters?.from && formatDate(filters.from),
    to: filters?.to && formatDate(filters.to),
  };
  return api.get(`${USERS_ENDPOINT}${userId}${STATS_ENDPOINT}`, queryFilters);
};

const createUser = ({ data }) => api.post(USERS_ENDPOINT, data);

const deleteUser = (id) => api.delete(USERS_ENDPOINT + id);

const updateUser = ({ id, data }) => api.put(USERS_ENDPOINT + id, data);

const putUserAvatar = ({ id, data }) =>
  api.post(USERS_ENDPOINT + id + "/avatar", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const getDepartments = (filters = null) => api.get(DEPARTMENTS_ENDPOINT, transformFilters(filters));

const getDepartment = (id) => api.get(DEPARTMENTS_ENDPOINT + id);

const getDepartmentStats = (id, filters) => {
  const queryFilters = {
    from: filters?.from && formatDate(filters.from),
    to: filters?.to && formatDate(filters.to),
  };
  return api.get(DEPARTMENTS_ENDPOINT + id + STATS_ENDPOINT, queryFilters);
};

const createDepartment = ({ data }) => api.post(DEPARTMENTS_ENDPOINT, data);

const deleteDepartment = (id) => api.delete(DEPARTMENTS_ENDPOINT + id);

const updateDepartment = ({ id, data }) => api.put(DEPARTMENTS_ENDPOINT + id, data);

const getLeads = (filters = null) => {
  return api.get(LEADS_ENDPOINT, transformFilters(filters));
};

const importLeads = ({ data }) =>
  api.post(LEADS_ENDPOINT + "import", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const assignLeadsToUser = ({ userId, leadIds }) =>
  api.post(USERS_ENDPOINT + userId + "/assignLeads", { lead_ids: leadIds });

const assignAuditsToUser = ({ userId, auditIds }) =>
  api.post(USERS_ENDPOINT + userId + "/assignAudits", { audit_ids: auditIds });

const getLeadsStats = (filters) => {
  const queryFilters = {
    from: filters?.from && formatDate(filters.from),
    to: filters?.to && formatDate(filters.to),
  };
  return api.get(LEADS_ENDPOINT + "stats", queryFilters);
};

const getLeadsSources = () => api.get(LEADS_SOURCES_ENDPOINT);

const deleteLeadSource = ({ leadSourceId }) => api.delete(LEADS_SOURCES_ENDPOINT + leadSourceId);

const updateLeadSource = ({ leadSourceId, data }) => api.patch(LEADS_SOURCES_ENDPOINT + leadSourceId, data);

const createLeadSource = (data) => api.post(LEADS_SOURCES_ENDPOINT, data);

const getLeadStatuses = () => api.get(LEADS_STATUSES_ENDPOINT);

const updateLeadStatus = ({ leadStatusId, data }) => api.patch(LEADS_STATUSES_ENDPOINT + leadStatusId, data);

const deleteLeadStatus = ({ leadStatusId }) => api.delete(LEADS_STATUSES_ENDPOINT + leadStatusId);

const updateLeadStatusOrder = (data) => api.put(LEADS_STATUSES_ENDPOINT + "order", data);

const createLeadStatus = (data) => api.post(LEADS_STATUSES_ENDPOINT, data);

const getLead = (leadId) => api.get(LEADS_ENDPOINT + leadId);

const createLead = ({ data }) => api.post(LEADS_ENDPOINT, data);

const deleteLead = (leadId) => api.delete(LEADS_ENDPOINT + leadId);

const bulkDeleteLeads = ({ leadIds }) => api.post(LEADS_ENDPOINT + "bulkDelete", { lead_ids: leadIds });

const bulkEditStatusLeads = ({ leadIds, leadStatusId }) =>
  api.post(LEADS_ENDPOINT + "bulkEditStatus", { lead_ids: leadIds, lead_status_id: leadStatusId });

const bulkUnassignUserLeads = ({ leadIds }) => api.post(LEADS_ENDPOINT + "bulkUnassignUser", { lead_ids: leadIds });

const updateLead = ({ id, data }) => api.patch(LEADS_ENDPOINT + id, data);

const updateLeadsStatus = ({ id, data }) => api.put(LEADS_ENDPOINT + id + "/status", data);

const getLeadNotes = (leadId) => api.get(LEADS_ENDPOINT + leadId + NOTES_ENDPOINT);

const createLeadNote = ({ leadId, note }) => api.post(LEADS_ENDPOINT + leadId + NOTES_ENDPOINT, { note });

const updateLeadNote = ({ leadId, noteId, note }) => api.put(LEADS_ENDPOINT + leadId + NOTES_ENDPOINT + noteId, note);

const deleteLeadNote = ({ leadId, noteId }) => api.delete(LEADS_ENDPOINT + leadId + NOTES_ENDPOINT + noteId);

const getLeadActions = (leadId) => api.get(LEADS_ENDPOINT + leadId + ACTIONS_ENDPOINT);

const createLeadAction = ({ leadId, action }) => {
  const data = {
    ...action,
    action_at: formatDateTime(action.action_at),
  };
  return api.post(LEADS_ENDPOINT + leadId + ACTIONS_ENDPOINT, data);
};

const updateLeadAction = ({ leadId, actionId, action }) => {
  const data = {
    ...action,
    action_at: action?.action_at ? formatDateTime(action.action_at) : undefined,
  };
  return api.put(LEADS_ENDPOINT + leadId + ACTIONS_ENDPOINT + actionId, data);
};

const deleteLeadAction = ({ leadId, actionId }) => api.delete(LEADS_ENDPOINT + leadId + ACTIONS_ENDPOINT + actionId);

const getCalendar = (filters) => api.get(CALENDAR_ENDPOINT, filters);

const getLeadFiles = ({ leadId, filters }) =>
  api.get(LEADS_ENDPOINT + leadId + FILES_ENDPOINT, transformFilters(filters));

const downloadFile = (fileId) => api.get(FILES_ENDPOINT + fileId + "/download", {}, { responseType: "blob" });

const deleteFile = (fileId) => api.delete(FILES_ENDPOINT + fileId);

const deleteLeadFile = ({ leadId, fileId }) => api.delete(LEADS_ENDPOINT + leadId + FILES_ENDPOINT + fileId);

const getFiles = (filters = null) => api.get(FILES_ENDPOINT, transformFilters(filters));

const updateFile = ({ fileId, data }) => api.patch(FILES_ENDPOINT + fileId, data);

const getFileCategories = () => api.get(FILES_CATEGORIES_ENDPOINT);

const createFileCategory = (data) => api.post(FILES_CATEGORIES_ENDPOINT, data);

const updateFileCategory = ({ fileCategoryId, data }) => api.put(FILES_CATEGORIES_ENDPOINT + fileCategoryId, data);

const deleteFileCategory = ({ fileCategoryId, data }) => api.delete(FILES_CATEGORIES_ENDPOINT + fileCategoryId, data);

const createFile = ({ data }) =>
  api.post(FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const createLeadFiles = ({ leadId, data }) =>
  api.post(LEADS_ENDPOINT + leadId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const editFileType = ({ type, fileId }) => api.put(FILES_ENDPOINT + fileId, { type });

const getTasks = (filters = null) => api.get(TASKS_ENDPOINT, transformFilters(filters));

const getTasksStats = (filters) => {
  const queryFilters = {
    from: filters?.from && formatDate(filters.from),
    to: filters?.to && formatDate(filters.to),
  };
  return api.get(TASKS_ENDPOINT + "stats", queryFilters);
};

const getDelegatedTasks = (filters = null) => api.get(TASKS_ENDPOINT + "delegated", transformFilters(filters));

const deleteTask = (taskId) => api.delete(TASKS_ENDPOINT + taskId);

const updateTask = ({ id, data }) => api.put(TASKS_ENDPOINT + id, data);

const createTask = ({ data: task }) => {
  const data = {
    ...task,
    due_date: task?.due_date && formatDateTime(task.due_date),
  };
  return api.post(TASKS_ENDPOINT, data);
};

const getContracts = (filters = null) => api.get(CONTRACTS_ENDPOINT, transformFilters(filters));

const getLeadContracts = ({ leadId, filters = null }) =>
  api.get(LEADS_ENDPOINT + leadId + CONTRACTS_ENDPOINT, transformFilters(filters));

const getContract = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId);

const createContract = ({ data: contract }) => {
  const data = {
    ...contract,
    signed_at: contract?.signed_at && formatDate(contract.signed_at),
  };
  return api.post(CONTRACTS_ENDPOINT, data);
};

const getContractFiles = ({ contractId, filters }) =>
  api.get(CONTRACTS_ENDPOINT + contractId + "/files", transformFilters(filters));

const createContractFiles = ({ contractId, data }) =>
  api.post(CONTRACTS_ENDPOINT + contractId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const deleteContractFile = ({ contractId, fileId }) =>
  api.delete(CONTRACTS_ENDPOINT + contractId + FILES_ENDPOINT + fileId);

const updateContract = ({ contractId, data: contract }) => {
  const data = {
    ...contract,
    signed_at: contract?.signed_at && formatDate(contract.signed_at),
  };
  return api.patch(CONTRACTS_ENDPOINT + contractId, data);
};

const deleteContract = (contractId) => api.delete(CONTRACTS_ENDPOINT + contractId);

const getContractBillings = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + BILLINGS_ENDPOINT);

const getContractStatuses = () => api.get(CONTRACTS_STATUSES_ENDPOINT);

const updateContractStatus = ({ contractStatusId, data }) =>
  api.patch(CONTRACTS_STATUSES_ENDPOINT + contractStatusId, data);

const deleteContractStatus = ({ contractStatusId }) => api.delete(CONTRACTS_STATUSES_ENDPOINT + contractStatusId);

const createContractStatus = (data) => api.post(CONTRACTS_STATUSES_ENDPOINT, data);

const updateContractStatusOrder = (data) => api.put(CONTRACTS_STATUSES_ENDPOINT + "order", data);

const getContractProducts = () => api.get(CONTRACTS_PRODUCTS_ENDPOINT);

const updateContractProduct = ({ contractProductId, data }) =>
  api.patch(CONTRACTS_PRODUCTS_ENDPOINT + contractProductId, data);

const deleteContractProduct = ({ contractProductId }) => api.delete(CONTRACTS_PRODUCTS_ENDPOINT + contractProductId);

const createContractProduct = (data) => api.post(CONTRACTS_ENDPOINT + "products", data);

const updateContractsStatus = ({ contractId, data }) => api.put(CONTRACTS_ENDPOINT + contractId + "/status", data);

const createLeadCalculation = ({ leadId, data }) => api.post(LEADS_ENDPOINT + leadId + CALCULATIONS_ENDPOINT, data);

const deleteCalculation = (calculationId) => api.delete(CALCULATIONS_ENDPOINT + calculationId);

const deleteLeadCalculation = ({ leadId, calculationId }) =>
  api.delete(LEADS_ENDPOINT + leadId + CALCULATIONS_ENDPOINT + calculationId);

const getLeadCalculations = ({ leadId, filters = null }) =>
  api.get(LEADS_ENDPOINT + leadId + CALCULATIONS_ENDPOINT, transformFilters(filters));

const getCalculation = (calculationId) => api.get(CALCULATIONS_ENDPOINT + calculationId);

const downloadCalculationOffer = (calculationId) =>
  api.get(CALCULATIONS_ENDPOINT + calculationId + "/offer", {}, { responseType: "blob" });

const getContractNotes = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + NOTES_ENDPOINT);

const createContractNote = ({ contractId, note }) =>
  api.post(CONTRACTS_ENDPOINT + contractId + NOTES_ENDPOINT, { note });

const updateContractNote = ({ contractId, noteId, note }) =>
  api.put(CONTRACTS_ENDPOINT + contractId + NOTES_ENDPOINT + noteId, note);

const deleteContractNote = ({ contractId, noteId }) =>
  api.delete(CONTRACTS_ENDPOINT + contractId + NOTES_ENDPOINT + noteId);

const getContractTasks = ({ contractId, filters }) =>
  api.get(CONTRACTS_ENDPOINT + contractId + TASKS_ENDPOINT, transformFilters(filters));

const getContractPayments = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + PAYMENTS_ENDPOINT);

const createContractPayment = ({ contractId, contractPayment }) =>
  api.post(CONTRACTS_ENDPOINT + contractId + PAYMENTS_ENDPOINT, {
    ...contractPayment,
    date: contractPayment.date ? formatDate(contractPayment.date) : undefined,
  });

const updateContractPayment = ({ contractId, contractPaymentId, contractPayment }) =>
  api.put(CONTRACTS_ENDPOINT + contractId + PAYMENTS_ENDPOINT + contractPaymentId, {
    ...contractPayment,
    date: contractPayment.date ? formatDate(contractPayment.date) : undefined,
  });

const deleteContractPayment = ({ contractId, contractPaymentId }) =>
  api.delete(CONTRACTS_ENDPOINT + contractId + PAYMENTS_ENDPOINT + contractPaymentId);

const getContractChanges = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + CHANGES_ENDPOINT);

const createContractPaymentFiles = ({ contractId, contractPaymentId, data }) =>
  api.post(CONTRACTS_ENDPOINT + contractId + PAYMENTS_ENDPOINT + contractPaymentId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const getNotifications = (filters) => api.get(NOTIFICATIONS_ENDPOINT, filters);

const getUnreadNotifications = (filters) => api.get(NOTIFICATIONS_ENDPOINT + "unread", filters);

const updateNotification = ({ notificationId }) => api.put(NOTIFICATIONS_ENDPOINT + notificationId);

const getContractMessages = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + MESSAGES_ENDPOINT);

const createContractMessage = ({ contractId, content }) =>
  api.post(CONTRACTS_ENDPOINT + contractId + MESSAGES_ENDPOINT, { content });

const updateContractMessage = ({ contractId, contractMessageId, content }) =>
  api.put(CONTRACTS_ENDPOINT + contractId + MESSAGES_ENDPOINT + contractMessageId, { content });

const deleteContractMessage = ({ contractId, contractMessageId }) =>
  api.delete(CONTRACTS_ENDPOINT + contractId + MESSAGES_ENDPOINT + contractMessageId);

const getContractUsers = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + USERS_ENDPOINT);

const createContractUser = ({ contractId, data }) => api.post(CONTRACTS_ENDPOINT + contractId + USERS_ENDPOINT, data);

const deleteContractUser = ({ contractId, userId }) =>
  api.delete(CONTRACTS_ENDPOINT + contractId + USERS_ENDPOINT + userId);

const getContractsStats = (filters) => {
  const queryFilters = {
    from: filters?.from && formatDate(filters.from),
    to: filters?.to && formatDate(filters.to),
  };
  return api.get(CONTRACTS_ENDPOINT + "stats", queryFilters);
};

const getContractCalculation = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + "/calculation");

const getDashboardOverview = () => api.get(DASHBOARD_ENDPOINT);

const getProcesses = () => api.get(PROCESSES_ENDPOINT);

const createProcess = ({ data }) => api.post(PROCESSES_ENDPOINT, data);

const updateProcess = ({ processId, data }) => api.put(PROCESSES_ENDPOINT + processId, data);

const deleteProcess = (processId) => api.delete(PROCESSES_ENDPOINT + processId);

const createProcessStep = ({ processId, data }) =>
  api.post(PROCESSES_ENDPOINT + processId + PROCESS_STEPS_ENDPOINT, data);

const deleteProcessStep = ({ processId, processStepId }) =>
  api.delete(PROCESSES_ENDPOINT + processId + PROCESS_STEPS_ENDPOINT + processStepId);

const updateProcessStep = ({ processId, processStepId, data }) =>
  api.put(PROCESSES_ENDPOINT + processId + PROCESS_STEPS_ENDPOINT + processStepId, data);

const getLeadProcesses = (leadId) => api.get(LEADS_ENDPOINT + leadId + PROCESSES_ENDPOINT);

const updateLeadProcessProgress = ({ leadId, processId, data }) =>
  api.put(LEADS_ENDPOINT + leadId + PROCESSES_ENDPOINT + processId, data);

const getContractProcesses = (contractId) => api.get(CONTRACTS_ENDPOINT + contractId + PROCESSES_ENDPOINT);

const updateContractProcessProgress = ({ contractId, processId, data }) =>
  api.put(CONTRACTS_ENDPOINT + contractId + PROCESSES_ENDPOINT + processId, data);

const getInstallationProcesses = (installationId) =>
  api.get(INSTALLATIONS_ENDPOINT + installationId + PROCESSES_ENDPOINT);

const updateInstallationProcessProgress = ({ installationId, processId, data }) =>
  api.put(INSTALLATIONS_ENDPOINT + installationId + PROCESSES_ENDPOINT + processId, data);

const getAuditProcesses = (auditId) => api.get(AUDITS_ENDPOINT + auditId + PROCESSES_ENDPOINT);

const updateAuditProcessProgress = ({ auditId, processId, data }) =>
  api.put(AUDITS_ENDPOINT + auditId + PROCESSES_ENDPOINT + processId, data);

const getApplicationProcesses = (applicationId) => api.get(APPLICATIONS_ENDPOINT + applicationId + PROCESSES_ENDPOINT);

const updateApplicationProcessProgress = ({ applicationId, processId, data }) =>
  api.put(APPLICATIONS_ENDPOINT + applicationId + PROCESSES_ENDPOINT + processId, data);

const getUserCosts = (userId) => api.get(USERS_ENDPOINT + userId + COSTS_ENDPOINT);

const createUserCost = ({ userId, data }) => api.post(USERS_ENDPOINT + userId + COSTS_ENDPOINT, data);

const deleteUserCost = ({ userId, costId }) => api.delete(USERS_ENDPOINT + userId + COSTS_ENDPOINT + costId);

const getUserLevels = () => api.get(LEVELS_ENDPOINT);

const createUserLevel = ({ data }) => api.post(LEVELS_ENDPOINT, data);

const updateUserLevel = ({ levelId, data }) => api.patch(LEVELS_ENDPOINT + levelId, data);

const deleteUserLevel = ({ levelId }) => api.delete(LEVELS_ENDPOINT + levelId);

const getWarehouses = (filters) => api.get(WAREHOUSES_ENDPOINT, transformFilters(filters));

const getWarehouse = (warehouseId) => api.get(WAREHOUSES_ENDPOINT + warehouseId);

const createWarehouse = ({ data }) => api.post(WAREHOUSES_ENDPOINT, data);

const deleteWarehouse = (warehouseId) => api.delete(WAREHOUSES_ENDPOINT + warehouseId);

const updateWarehouse = ({ warehouseId, data }) => api.put(WAREHOUSES_ENDPOINT + warehouseId, data);

const getProductsPotentialDemand = (filters) =>
  api.get(PRODUCTS_ENDPOINT + "potentialDemand", transformFilters(filters));

const getProductsActualDemand = (filters) => api.get(PRODUCTS_ENDPOINT + "actualDemand", transformFilters(filters));

const exportProducts = () => api.get(PRODUCTS_ENDPOINT + "export", {}, { responseType: "blob" });

const importProducts = ({ data }) =>
  api.post(PRODUCTS_ENDPOINT + "import", data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const getProducts = (filters) => api.get(PRODUCTS_ENDPOINT, transformFilters(filters));

const getProduct = (productId) => api.get(PRODUCTS_ENDPOINT + productId);

const createProduct = ({ data }) => api.post(PRODUCTS_ENDPOINT, data);

const deleteProduct = (productId) => api.delete(PRODUCTS_ENDPOINT + productId);

const updateProduct = ({ productId, data }) => api.patch(PRODUCTS_ENDPOINT + productId, data);

const getProductRelations = (productId) => api.get(PRODUCTS_ENDPOINT + productId + RELATIONS_ENDPOINT);

const createProductRelation = ({ productId, data }) =>
  api.post(PRODUCTS_ENDPOINT + productId + RELATIONS_ENDPOINT, data);

const getProductBundles = (productId) => api.get(PRODUCTS_ENDPOINT + productId + BUNDLES_ENDPOINT);

const createProductBundle = ({ productId, data }) => api.post(PRODUCTS_ENDPOINT + productId + BUNDLES_ENDPOINT, data);

const getProductTypes = () => api.get(PRODUCTS_TYPES_ENDPOINT);

const updateProductType = ({ productTypeId, data }) => api.patch(PRODUCTS_TYPES_ENDPOINT + productTypeId, data);

const createProductType = (data) => api.post(PRODUCTS_TYPES_ENDPOINT, data);

const deleteProductType = ({ productTypeId }) => api.delete(PRODUCTS_TYPES_ENDPOINT + productTypeId);

const getMetadata = () => api.get(METADATA_ENDPOINT);

const createMetadata = ({ data }) => api.post(METADATA_ENDPOINT, data);

const createProductMetadata = ({ productId, data }) =>
  api.post(PRODUCTS_ENDPOINT + productId + METADATA_ENDPOINT, data);

const updateProductMetadata = ({ productId, metadataId, data }) =>
  api.put(PRODUCTS_ENDPOINT + productId + METADATA_ENDPOINT + metadataId, data);

const deleteProductMetadata = ({ productId, metadataId }) =>
  api.delete(PRODUCTS_ENDPOINT + productId + METADATA_ENDPOINT + metadataId);

const getProductFiles = ({ productId, filters }) => api.get(PRODUCTS_ENDPOINT + productId + "/files", filters);

const createProductFiles = ({ productId, data }) =>
  api.post(PRODUCTS_ENDPOINT + productId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const deleteProductFile = ({ productId, fileId }) =>
  api.delete(PRODUCTS_ENDPOINT + productId + FILES_ENDPOINT + fileId);

const getProductWarehouses = ({ productId, filters = {} }) =>
  api.get(PRODUCTS_ENDPOINT + productId + WAREHOUSES_ENDPOINT, transformFilters(filters));

const getWarehouseProducts = ({ filters, warehouseId }) =>
  api.get(WAREHOUSES_ENDPOINT + warehouseId + PRODUCTS_ENDPOINT, transformFilters(filters));

const createWarehouseProduct = ({ warehouseId, data }) =>
  api.post(WAREHOUSES_ENDPOINT + warehouseId + PRODUCTS_ENDPOINT, data);

const createManyWarehouseProducts = ({ warehouseId, data }) =>
  api.post(WAREHOUSES_ENDPOINT + warehouseId + PRODUCTS_ENDPOINT + "createMany", data);

const deleteWarehouseProduct = ({ warehouseId, productId }) =>
  api.delete(WAREHOUSES_ENDPOINT + warehouseId + PRODUCTS_ENDPOINT + productId);

const getWarehouseOperations = ({ warehouseId, filters }) =>
  api.get(WAREHOUSES_ENDPOINT + warehouseId + OPERATIONS_ENDPOINT, transformFilters(filters));

const getOrders = (filters) => api.get(ORDERS_ENDPOINT, transformFilters(filters));

const getOrder = (orderId) => api.get(ORDERS_ENDPOINT + orderId);

const createOrder = ({ data }) => api.post(ORDERS_ENDPOINT, data);

const deleteOrder = (orderId) => api.delete(ORDERS_ENDPOINT + orderId);

const updateOrder = ({ orderId, data }) => api.patch(ORDERS_ENDPOINT + orderId, data);

const getOrderFiles = ({ orderId, filters }) => api.get(ORDERS_ENDPOINT + orderId + "/files", filters);

const createOrderFiles = ({ orderId, data }) =>
  api.post(ORDERS_ENDPOINT + orderId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const deleteOrderFile = ({ orderId, fileId }) => api.delete(ORDERS_ENDPOINT + orderId + FILES_ENDPOINT + fileId);

const getOrderNotes = (orderId) => api.get(ORDERS_ENDPOINT + orderId + NOTES_ENDPOINT);

const createOrderNote = ({ orderId, note }) => api.post(ORDERS_ENDPOINT + orderId + NOTES_ENDPOINT, { note });

const updateOrderNote = ({ orderId, noteId, note }) =>
  api.put(ORDERS_ENDPOINT + orderId + NOTES_ENDPOINT + noteId, note);

const deleteOrderNote = ({ orderId, noteId }) => api.delete(ORDERS_ENDPOINT + orderId + NOTES_ENDPOINT + noteId);

const getOrderItems = (orderId) => api.get(ORDERS_ENDPOINT + orderId + ITEMS_ENDPOINT);

const createOrderItem = ({ orderId, data }) => api.post(ORDERS_ENDPOINT + orderId + ITEMS_ENDPOINT, data);

const deleteOrderItem = ({ orderId, itemId }) => api.delete(ORDERS_ENDPOINT + orderId + ITEMS_ENDPOINT + itemId);

const updateOrderItem = ({ orderId, itemId, data }) =>
  api.patch(ORDERS_ENDPOINT + orderId + ITEMS_ENDPOINT + itemId, data);

const getBillings = (filters) => api.get(BILLINGS_ENDPOINT, transformFilters(filters));

const createBilling = ({ data }) => api.post(BILLINGS_ENDPOINT, data);

const updateBilling = ({ billingId, data }) => api.patch(BILLINGS_ENDPOINT + billingId, data);

const deleteBilling = ({ billingId }) => api.delete(BILLINGS_ENDPOINT + billingId);

const getBillingGroups = (filters) => api.get(BILLING_GROUPS_ENDPOINT, transformFilters(filters));

const createBillingGroup = ({ data }) =>
  api.post(BILLING_GROUPS_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const updateBillingGroup = ({ billingGroupId, data }) => api.put(BILLING_GROUPS_ENDPOINT + billingGroupId, data);

const deleteBillingGroup = ({ billingGroupId }) => api.delete(BILLING_GROUPS_ENDPOINT + billingGroupId);

const getBillingSettings = (filters) => api.get(BILLING_SETTINGS_ENDPOINT, transformFilters(filters));

const getBillingSetting = (billingSettingId) => api.get(BILLING_SETTINGS_ENDPOINT + billingSettingId);

const createBillingSetting = ({ data }) => api.post(BILLING_SETTINGS_ENDPOINT, data);

const updateBillingSetting = ({ billingSettingId, data }) =>
  api.patch(BILLING_SETTINGS_ENDPOINT + billingSettingId, data);

const deleteBillingSetting = ({ billingSettingId }) => api.delete(BILLING_SETTINGS_ENDPOINT + billingSettingId);

const createBillingSettingRule = ({ billingSettingId, data }) =>
  api.post(BILLING_SETTINGS_ENDPOINT + billingSettingId + BILLING_SETTING_RULES_ENDPOINT, data);

const updateBillingSettingRule = ({ billingSettingId, billingSettingRuleId, data }) =>
  api.patch(BILLING_SETTINGS_ENDPOINT + billingSettingId + BILLING_SETTING_RULES_ENDPOINT + billingSettingRuleId, data);

const deleteBillingSettingRule = ({ billingSettingId, billingSettingRuleId }) =>
  api.delete(BILLING_SETTINGS_ENDPOINT + billingSettingId + BILLING_SETTING_RULES_ENDPOINT + billingSettingRuleId);

const getCalculators = () => api.get(CALCULATORS_ENDPOINT);

const createCalculator = ({ data }) => api.post(CALCULATORS_ENDPOINT, data);

const getCalculator = (calculatorId) => api.get(CALCULATORS_ENDPOINT + calculatorId);

const updateCalculator = ({ calculatorId, data }) => api.put(CALCULATORS_ENDPOINT + calculatorId, data);

const updateCalculatorVisibility = ({ calculatorId, data }) =>
  api.put(CALCULATORS_ENDPOINT + calculatorId + "/updateVisibility", data);

const updateCalculatorSurvey = ({ calculatorId, data }) =>
  api.put(CALCULATORS_ENDPOINT + calculatorId + "/surveys", data);

const deleteCalculator = ({ calculatorId }) => api.delete(CALCULATORS_ENDPOINT + calculatorId);

const duplicateCalculator = ({ calculatorId }) => api.post(CALCULATORS_ENDPOINT + calculatorId + "/duplicate");

const createCalculatorAdditionalCost = ({ calculatorId, data }) =>
  api.post(CALCULATORS_ENDPOINT + calculatorId + ADDITIONAL_COSTS_ENDPOINT, data);

const updateCalculatorAdditionalCost = ({ calculatorId, calculatorAdditionalCostId, data }) =>
  api.patch(CALCULATORS_ENDPOINT + calculatorId + ADDITIONAL_COSTS_ENDPOINT + calculatorAdditionalCostId, data);

const deleteCalculatorAdditionalCost = ({ calculatorId, calculatorAdditionalCostId }) =>
  api.delete(CALCULATORS_ENDPOINT + calculatorId + ADDITIONAL_COSTS_ENDPOINT + calculatorAdditionalCostId);

const createCalculatorSection = ({ calculatorId, data }) =>
  api.post(CALCULATORS_ENDPOINT + calculatorId + SECTIONS_ENDPOINT, data);

const updateCalculatorSection = ({ calculatorId, calculatorSectionId, data }) =>
  api.put(CALCULATORS_ENDPOINT + calculatorId + SECTIONS_ENDPOINT + calculatorSectionId, data);

const updateCalculatorSectionOrder = ({ calculatorId, data }) =>
  api.put(CALCULATORS_ENDPOINT + calculatorId + SECTIONS_ENDPOINT + "order", data);

const deleteCalculatorSection = ({ calculatorId, calculatorSectionId }) =>
  api.delete(CALCULATORS_ENDPOINT + calculatorId + SECTIONS_ENDPOINT + calculatorSectionId);

const createCalculatorField = ({ calculatorSectionId, data }) =>
  api.post(CALCULATOR_SECTIONS_ENDPOINT + calculatorSectionId + FIELDS_ENDPOINT, data);

const updateCalculatorField = ({ calculatorSectionId, calculatorFieldId, data }) =>
  api.put(CALCULATOR_SECTIONS_ENDPOINT + calculatorSectionId + FIELDS_ENDPOINT + calculatorFieldId, data);

const updateCalculatorFieldOrder = ({ calculatorSectionId, data }) =>
  api.put(SECTIONS_ENDPOINT + calculatorSectionId + FIELDS_ENDPOINT + "order", data);

const deleteCalculatorField = ({ calculatorSectionId, calculatorFieldId }) =>
  api.delete(CALCULATOR_SECTIONS_ENDPOINT + calculatorSectionId + FIELDS_ENDPOINT + calculatorFieldId);

const duplicateCalculatorField = ({ calculatorSectionId, calculatorFieldId }) =>
  api.post(CALCULATOR_SECTIONS_ENDPOINT + calculatorSectionId + FIELDS_ENDPOINT + calculatorFieldId + "/duplicate");

const createCalculatorFieldDependency = ({ calculatorFieldId, data }) =>
  api.post(FIELDS_ENDPOINT + calculatorFieldId + DEPENDENCIES_ENDPOINT, data);

const deleteCalculatorFieldDependency = ({ calculatorFieldId, calculatorFieldDependencyId }) =>
  api.delete(FIELDS_ENDPOINT + calculatorFieldId + DEPENDENCIES_ENDPOINT + calculatorFieldDependencyId);

const createCalculatorGrant = ({ calculatorId, data }) =>
  api.post(CALCULATORS_ENDPOINT + calculatorId + GRANTS_ENDPOINT, data);

const updateCalculatorGrant = ({ calculatorId, calculatorGrantId, data }) =>
  api.patch(CALCULATORS_ENDPOINT + calculatorId + GRANTS_ENDPOINT + calculatorGrantId, data);

const deleteCalculatorGrant = ({ calculatorId, calculatorGrantId }) =>
  api.delete(CALCULATORS_ENDPOINT + calculatorId + GRANTS_ENDPOINT + calculatorGrantId);

const updateCalculatorGrantOrder = ({ calculatorId, data }) =>
  api.put(CALCULATORS_ENDPOINT + calculatorId + GRANTS_ENDPOINT + "order", data);

const createCalculatorDiscount = ({ calculatorId, data }) =>
  api.post(CALCULATORS_ENDPOINT + calculatorId + DISCOUNTS_ENDPOINT, data);

const updateCalculatorDiscount = ({ calculatorId, calculatorDiscountId, data }) =>
  api.patch(CALCULATORS_ENDPOINT + calculatorId + DISCOUNTS_ENDPOINT + calculatorDiscountId, data);

const deleteCalculatorDiscount = ({ calculatorId, calculatorDiscountId }) =>
  api.delete(CALCULATORS_ENDPOINT + calculatorId + DISCOUNTS_ENDPOINT + calculatorDiscountId);

const getInstallationStatuses = () => api.get(INSTALLATIONS_STATUSES_ENDPOINT);

const createInstallationStatus = (data) => api.post(INSTALLATIONS_STATUSES_ENDPOINT, data);

const updateInstallationStatus = ({ installationStatusId, data }) =>
  api.patch(INSTALLATIONS_STATUSES_ENDPOINT + installationStatusId, data);

const updateInstallationStatusOrder = (data) => api.put(INSTALLATIONS_STATUSES_ENDPOINT + "order", data);

const deleteInstallationStatus = ({ installationStatusId }) =>
  api.delete(INSTALLATIONS_STATUSES_ENDPOINT + installationStatusId);

const getInstallationTypes = () => api.get(INSTALLATIONS_TYPES_ENDPOINT);

const createInstallationType = (data) => api.post(INSTALLATIONS_TYPES_ENDPOINT, data);

const updateInstallationType = ({ installationTypeId, data }) =>
  api.patch(INSTALLATIONS_TYPES_ENDPOINT + installationTypeId, data);

const deleteInstallationType = ({ installationTypeId }) =>
  api.delete(INSTALLATIONS_TYPES_ENDPOINT + installationTypeId);

const getInstallations = (filters = null) => api.get(INSTALLATIONS_ENDPOINT, transformFilters(filters));

const getInstallation = (installationId) => api.get(INSTALLATIONS_ENDPOINT + installationId);

const createInstallation = ({ data: installation }) => {
  const data = {
    ...installation,
    date: installation?.date && formatDate(installation.date),
  };
  return api.post(INSTALLATIONS_ENDPOINT, data);
};

const updateInstallation = ({ installationId, data: installation }) => {
  const data = {
    ...installation,
    date: installation?.date && formatDate(installation.date),
  };
  return api.patch(INSTALLATIONS_ENDPOINT + installationId, data);
};

const updateInstallationsStatus = ({ installationId, data }) =>
  api.put(INSTALLATIONS_ENDPOINT + installationId + "/status", data);

const deleteInstallation = (installationId) => api.delete(INSTALLATIONS_ENDPOINT + installationId);

const getInstallationServices = (filters = null) => api.get(INSTALLATION_SERVICES_ENDPOINT, transformFilters(filters));

const getInstallationService = (installationServiceId) =>
  api.get(INSTALLATION_SERVICES_ENDPOINT + installationServiceId);

const createInstallationService = ({ data: installationService }) => {
  const data = {
    ...installationService,
    service_date: installationService?.service_date && formatDate(installationService.service_date),
  };
  return api.post(INSTALLATION_SERVICES_ENDPOINT, data);
};

const updateInstallationService = ({ installationServiceId, data: installationService }) => {
  const data = {
    ...installationService,
    service_date: installationService?.service_date && formatDate(installationService.service_date),
  };
  return api.patch(INSTALLATION_SERVICES_ENDPOINT + installationServiceId, data);
};

const deleteInstallationService = (installationServiceId) =>
  api.delete(INSTALLATION_SERVICES_ENDPOINT + installationServiceId);

const getInstallationServiceNotes = (installationServiceId) =>
  api.get(INSTALLATION_SERVICES_ENDPOINT + installationServiceId + NOTES_ENDPOINT);

const createInstallationServiceNote = ({ installationServiceId, note }) =>
  api.post(INSTALLATION_SERVICES_ENDPOINT + installationServiceId + NOTES_ENDPOINT, { note });

const updateInstallationServiceNote = ({ installationServiceId, noteId, note }) =>
  api.put(INSTALLATION_SERVICES_ENDPOINT + installationServiceId + NOTES_ENDPOINT + noteId, note);

const deleteInstallationServiceNote = ({ installationServiceId, noteId }) =>
  api.delete(INSTALLATION_SERVICES_ENDPOINT + installationServiceId + NOTES_ENDPOINT + noteId);

const getInstallationFiles = ({ installationId, filters }) =>
  api.get(INSTALLATIONS_ENDPOINT + installationId + "/files", transformFilters(filters));

const createInstallationFiles = ({ installationId, data }) =>
  api.post(INSTALLATIONS_ENDPOINT + installationId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const deleteInstallationFile = ({ installationId, fileId }) =>
  api.delete(INSTALLATIONS_ENDPOINT + installationId + FILES_ENDPOINT + fileId);

const getInstallationNotes = (installationId) => api.get(INSTALLATIONS_ENDPOINT + installationId + NOTES_ENDPOINT);

const createInstallationNote = ({ installationId, note }) =>
  api.post(INSTALLATIONS_ENDPOINT + installationId + NOTES_ENDPOINT, { note });

const updateInstallationNote = ({ installationId, noteId, note }) =>
  api.put(INSTALLATIONS_ENDPOINT + installationId + NOTES_ENDPOINT + noteId, note);

const deleteInstallationNote = ({ installationId, noteId }) =>
  api.delete(INSTALLATIONS_ENDPOINT + installationId + NOTES_ENDPOINT + noteId);

const getInstallationActions = (installationId) => api.get(INSTALLATIONS_ENDPOINT + installationId + ACTIONS_ENDPOINT);

const createInstallationAction = ({ installationId, action }) => {
  const data = {
    ...action,
    action_at: formatDateTime(action.action_at),
  };
  return api.post(INSTALLATIONS_ENDPOINT + installationId + ACTIONS_ENDPOINT, data);
};

const updateInstallationAction = ({ installationId, actionId, action }) => {
  const data = {
    ...action,
    action_at: action?.action_at ? formatDateTime(action.action_at) : undefined,
  };
  return api.put(INSTALLATIONS_ENDPOINT + installationId + ACTIONS_ENDPOINT + actionId, data);
};

const deleteInstallationAction = ({ installationId, actionId }) =>
  api.delete(INSTALLATIONS_ENDPOINT + installationId + ACTIONS_ENDPOINT + actionId);

const getInstallationCrews = (filters = null) => api.get(INSTALLATION_CREWS_ENDPOINT, transformFilters(filters));

const getInstallationCrew = (installationCrewId) => api.get(INSTALLATION_CREWS_ENDPOINT + installationCrewId);

const createInstallationCrew = ({ data }) => {
  return api.post(INSTALLATION_CREWS_ENDPOINT, data);
};

const updateInstallationCrew = ({ installationCrewId, data }) => {
  return api.patch(INSTALLATION_CREWS_ENDPOINT + installationCrewId, data);
};

const deleteInstallationCrew = (installationCrewId) => api.delete(INSTALLATION_CREWS_ENDPOINT + installationCrewId);

const createInstallationInstallationCrew = ({ installationId, installationCrewId }) =>
  api.put(INSTALLATIONS_ENDPOINT + installationId + INSTALLATION_CREWS_ENDPOINT + installationCrewId);

const deleteInstallationInstallationCrew = ({ installationId, installationCrewId }) =>
  api.delete(INSTALLATIONS_ENDPOINT + installationId + INSTALLATION_CREWS_ENDPOINT + installationCrewId);

const getAuditStatuses = () => api.get(AUDITS_STATUSES_ENDPOINT);

const createAuditStatus = (data) => api.post(AUDITS_STATUSES_ENDPOINT, data);

const updateAuditStatus = ({ auditStatusId, data }) => api.patch(AUDITS_STATUSES_ENDPOINT + auditStatusId, data);

const updateAuditStatusOrder = (data) => api.put(AUDITS_STATUSES_ENDPOINT + "order", data);

const deleteAuditStatus = ({ auditStatusId }) => api.delete(AUDITS_STATUSES_ENDPOINT + auditStatusId);

const getAudits = (filters = null) => api.get(AUDITS_ENDPOINT, transformFilters(filters));

const getAudit = (auditId) => api.get(AUDITS_ENDPOINT + auditId);

const createAudit = ({ data }) => api.post(AUDITS_ENDPOINT, data);

const updateAudit = ({ auditId, data }) => api.patch(AUDITS_ENDPOINT + auditId, data);

const updateAuditsStatus = ({ auditId, data }) => api.put(AUDITS_ENDPOINT + auditId + "/status", data);

const deleteAudit = (auditId) => api.delete(AUDITS_ENDPOINT + auditId);

const bulkEditStatusAudits = ({ auditIds, auditStatusId }) =>
  api.post(AUDITS_ENDPOINT + "bulkEditStatus", { audit_ids: auditIds, audit_status_id: auditStatusId });

const getAuditFiles = ({ auditId, filters }) =>
  api.get(AUDITS_ENDPOINT + auditId + "/files", transformFilters(filters));

const createAuditFiles = ({ auditId, data }) =>
  api.post(AUDITS_ENDPOINT + auditId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const deleteAuditFile = ({ auditId, fileId }) => api.delete(AUDITS_ENDPOINT + auditId + FILES_ENDPOINT + fileId);

const getAuditNotes = (auditId) => api.get(AUDITS_ENDPOINT + auditId + NOTES_ENDPOINT);

const createAuditNote = ({ auditId, note }) => api.post(AUDITS_ENDPOINT + auditId + NOTES_ENDPOINT, { note });

const updateAuditNote = ({ auditId, noteId, note }) =>
  api.put(AUDITS_ENDPOINT + auditId + NOTES_ENDPOINT + noteId, note);

const deleteAuditNote = ({ auditId, noteId }) => api.delete(AUDITS_ENDPOINT + auditId + NOTES_ENDPOINT + noteId);

const getAuditActions = (auditId) => api.get(AUDITS_ENDPOINT + auditId + ACTIONS_ENDPOINT);

const createAuditAction = ({ auditId, action }) => {
  const data = {
    ...action,
    action_at: formatDateTime(action.action_at),
  };
  return api.post(AUDITS_ENDPOINT + auditId + ACTIONS_ENDPOINT, data);
};

const updateAuditAction = ({ auditId, actionId, action }) => {
  const data = {
    ...action,
    action_at: action?.action_at ? formatDateTime(action.action_at) : undefined,
  };
  return api.put(AUDITS_ENDPOINT + auditId + ACTIONS_ENDPOINT + actionId, data);
};

const deleteAuditAction = ({ auditId, actionId }) =>
  api.delete(AUDITS_ENDPOINT + auditId + ACTIONS_ENDPOINT + actionId);

const getApplicationStatuses = () => api.get(APPLICATION_STATUSES_ENDPOINT);

const createApplicationStatus = (data) => api.post(APPLICATION_STATUSES_ENDPOINT, data);

const updateApplicationStatus = ({ applicationStatusId, data }) =>
  api.patch(APPLICATION_STATUSES_ENDPOINT + applicationStatusId, data);

const updateApplicationStatusOrder = (data) => api.put(APPLICATION_STATUSES_ENDPOINT + "order", data);

const deleteApplicationStatus = ({ applicationStatusId }) =>
  api.delete(APPLICATION_STATUSES_ENDPOINT + applicationStatusId);

const updateApplicationsStatus = ({ applicationId, data }) =>
  api.put(APPLICATIONS_ENDPOINT + applicationId + "/status", data);

const getApplicationTypes = () => api.get(APPLICATION_TYPES_ENDPOINT);

const createApplicationType = (data) => api.post(APPLICATION_TYPES_ENDPOINT, data);

const updateApplicationType = ({ applicationTypeId, data }) =>
  api.patch(APPLICATION_TYPES_ENDPOINT + applicationTypeId, data);

const deleteApplicationType = ({ applicationTypeId }) => api.delete(APPLICATION_TYPES_ENDPOINT + applicationTypeId);

const getApplications = (filters = null) => api.get(APPLICATIONS_ENDPOINT, transformFilters(filters));

const getApplication = (applicationId) => api.get(APPLICATIONS_ENDPOINT + applicationId);

const createApplication = ({ data }) => {
  return api.post(APPLICATIONS_ENDPOINT, data);
};

const updateApplication = ({ applicationId, data }) => {
  return api.patch(APPLICATIONS_ENDPOINT + applicationId, data);
};

const deleteApplication = (applicationId) => api.delete(APPLICATIONS_ENDPOINT + applicationId);

const getApplicationFiles = ({ applicationId, filters }) =>
  api.get(APPLICATIONS_ENDPOINT + applicationId + "/files", transformFilters(filters));

const createApplicationFiles = ({ applicationId, data }) =>
  api.post(APPLICATIONS_ENDPOINT + applicationId + FILES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const deleteApplicationFile = ({ applicationId, fileId }) =>
  api.delete(APPLICATIONS_ENDPOINT + applicationId + FILES_ENDPOINT + fileId);

const getApplicationNotes = (applicationId) => api.get(APPLICATIONS_ENDPOINT + applicationId + NOTES_ENDPOINT);

const createApplicationNote = ({ applicationId, note }) =>
  api.post(APPLICATIONS_ENDPOINT + applicationId + NOTES_ENDPOINT, { note });

const updateApplicationNote = ({ applicationId, noteId, note }) =>
  api.put(APPLICATIONS_ENDPOINT + applicationId + NOTES_ENDPOINT + noteId, note);

const deleteApplicationNote = ({ applicationId, noteId }) =>
  api.delete(APPLICATIONS_ENDPOINT + applicationId + NOTES_ENDPOINT + noteId);

const getClients = (filters = null) => api.get(CLIENTS_ENDPOINT, transformFilters(filters));

const getClient = (clientId) => api.get(CLIENTS_ENDPOINT + clientId);

const updateClient = ({ id, data }) => api.patch(CLIENTS_ENDPOINT + id, data);

const deleteClient = (clientId) => api.delete(CLIENTS_ENDPOINT + clientId);

const getClientApplications = (clientId) => api.get(CLIENTS_ENDPOINT + clientId + APPLICATIONS_ENDPOINT);

const getClientInstallations = (clientId) => api.get(CLIENTS_ENDPOINT + clientId + INSTALLATIONS_ENDPOINT);

const getSettings = () => api.get(SETTINGS_ENDPOINT);

const updateSetting = ({ settingId, data }) => api.put(SETTINGS_ENDPOINT + settingId, data);

const getContractFileCategories = (filters = null) =>
  api.get(CONTRACT_FILE_CATEGORIES_ENDPOINT, transformFilters(filters));

const createContractFileCategory = ({ data }) => {
  return api.post(CONTRACT_FILE_CATEGORIES_ENDPOINT, data);
};

const deleteContractFileCategory = ({ contractFileCategoryId }) =>
  api.delete(CONTRACT_FILE_CATEGORIES_ENDPOINT + contractFileCategoryId);

const getSurveys = () => api.get(SURVEYS_ENDPOINT);

const createSurvey = ({ data }) => api.post(SURVEYS_ENDPOINT, data);

const getSurvey = (surveyId) => api.get(SURVEYS_ENDPOINT + surveyId);

const updateSurvey = ({ surveyId, data }) => api.put(SURVEYS_ENDPOINT + surveyId, data);

const createSurveySection = ({ surveyId, data }) => api.post(SURVEYS_ENDPOINT + surveyId + SECTIONS_ENDPOINT, data);

const updateSurveySection = ({ surveyId, surveySectionId, data }) =>
  api.put(SURVEYS_ENDPOINT + surveyId + SECTIONS_ENDPOINT + surveySectionId, data);

const updateSurveySectionOrder = ({ surveyId, data }) =>
  api.put(SURVEYS_ENDPOINT + surveyId + SECTIONS_ENDPOINT + "order", data);

const deleteSurveySection = ({ surveyId, surveySectionId }) =>
  api.delete(SURVEYS_ENDPOINT + surveyId + SECTIONS_ENDPOINT + surveySectionId);

const createSurveyQuestion = ({ surveySectionId, data }) =>
  api.post(SURVEY_SECTIONS_ENDPOINT + surveySectionId + QUESTIONS_ENDPOINT, data);

const updateSurveyQuestion = ({ surveySectionId, surveyQuestionId, data }) =>
  api.put(SURVEY_SECTIONS_ENDPOINT + surveySectionId + QUESTIONS_ENDPOINT + surveyQuestionId, data);

const updateSurveyQuestionOrder = ({ surveySectionId, data }) =>
  api.put(SURVEY_SECTIONS_ENDPOINT + surveySectionId + QUESTIONS_ENDPOINT + "order", data);

const deleteSurveyQuestion = ({ surveySectionId, surveyQuestionId }) =>
  api.delete(SURVEY_SECTIONS_ENDPOINT + surveySectionId + QUESTIONS_ENDPOINT + surveyQuestionId);

const getCalculationSurveys = (calculationId) => api.get(CALCULATIONS_ENDPOINT + calculationId + SURVEYS_ENDPOINT);

const createSurveyEntry = ({ surveyId, data }) => api.post(SURVEYS_ENDPOINT + surveyId + ENTRIES_ENDPOINT, data);

const createSurveyEntrySignature = ({ surveyEntryId, data }) =>
  api.post(SURVEY_ENTRIES_ENDPOINT + surveyEntryId + SIGNATURES_ENDPOINT, data);

const downloadSurveyEntryPdf = (surveyEntryId) =>
  api.get(SURVEY_ENTRIES_ENDPOINT + surveyEntryId + "/pdf", {}, { responseType: "blob" });

const getClientSurveyEntries = (clientId) => api.get(CLIENTS_ENDPOINT + clientId + SURVEY_ENTRIES_ENDPOINT);

const getContractTemplates = () => api.get(CONTRACT_TEMPLATES_ENDPOINT);

const createContractTemplate = (data) =>
  api.post(CONTRACT_TEMPLATES_ENDPOINT, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });

const deleteContractTemplate = ({ contractTemplateId }) => api.delete(CONTRACT_TEMPLATES_ENDPOINT + contractTemplateId);

const downloadContractTemplate = (contractTemplateId) =>
  api.get(CONTRACT_TEMPLATES_ENDPOINT + contractTemplateId + "/download", {}, { responseType: "blob" });

const getCalculatorContractTemplate = (calculatorId) =>
  api.get(CALCULATORS_ENDPOINT + calculatorId + "/contractTemplate", {}, { responseType: "blob" });

const createModelView = (data) => api.post(MODEL_VIEWS_ENDPOINT, data);

export default {
  login,
  impersonate,
  getPermissions,
  logout,
  authCheck,
  forgotPassword,
  resetPassword,
  changePassword,
  getUsers,
  getUser,
  createUser,
  deleteUser,
  updateUser,
  putUserAvatar,
  getDepartments,
  getDepartment,
  getDepartmentStats,
  createDepartment,
  deleteDepartment,
  updateDepartment,
  getLeads,
  importLeads,
  assignLeadsToUser,
  assignAuditsToUser,
  getLeadsStats,
  getLeadsSources,
  deleteLeadSource,
  updateLeadSource,
  createLeadSource,
  getLeadStatuses,
  updateLeadStatus,
  deleteLeadStatus,
  updateLeadStatusOrder,
  createLeadStatus,
  getLead,
  createLead,
  deleteLead,
  bulkDeleteLeads,
  bulkEditStatusLeads,
  bulkUnassignUserLeads,
  updateLead,
  updateLeadsStatus,
  getLeadNotes,
  createLeadNote,
  updateLeadNote,
  deleteLeadNote,
  getLeadActions,
  createLeadAction,
  updateLeadAction,
  deleteLeadAction,
  getCalendar,
  getUserStats,
  downloadFile,
  deleteFile,
  deleteContractFile,
  getFiles,
  updateFile,
  getFileCategories,
  createFileCategory,
  updateFileCategory,
  deleteFileCategory,
  getLeadFiles,
  createLeadFiles,
  deleteLeadFile,
  createFile,
  editFileType,
  getTasks,
  getTasksStats,
  getDelegatedTasks,
  deleteTask,
  updateTask,
  createTask,
  getContracts,
  getLeadContracts,
  getContract,
  createContract,
  createContractFiles,
  getContractFiles,
  deleteContract,
  updateContract,
  updateContractStatus,
  deleteContractStatus,
  getContractStatuses,
  createContractStatus,
  updateContractProduct,
  deleteContractProduct,
  getContractProducts,
  createContractProduct,
  getContractBillings,
  updateContractsStatus,
  updateContractStatusOrder,
  createLeadCalculation,
  deleteCalculation,
  deleteLeadCalculation,
  getCalculation,
  downloadCalculationOffer,
  getLeadCalculations,
  getContractNotes,
  createContractNote,
  updateContractNote,
  deleteContractNote,
  getContractTasks,
  getContractPayments,
  createContractPayment,
  updateContractPayment,
  deleteContractPayment,
  createContractPaymentFiles,
  getContractChanges,
  getNotifications,
  getUnreadNotifications,
  updateNotification,
  getContractMessages,
  createContractMessage,
  updateContractMessage,
  deleteContractMessage,
  getContractUsers,
  createContractUser,
  deleteContractUser,
  getContractsStats,
  getContractCalculation,
  getDashboardOverview,
  getProcesses,
  createProcess,
  updateProcess,
  deleteProcess,
  createProcessStep,
  updateProcessStep,
  deleteProcessStep,
  getLeadProcesses,
  updateLeadProcessProgress,
  getContractProcesses,
  updateContractProcessProgress,
  getInstallationProcesses,
  updateInstallationProcessProgress,
  getAuditProcesses,
  updateAuditProcessProgress,
  getApplicationProcesses,
  updateApplicationProcessProgress,
  getUserCosts,
  createUserCost,
  deleteUserCost,
  getUserLevels,
  createUserLevel,
  updateUserLevel,
  deleteUserLevel,
  getWarehouses,
  getWarehouse,
  createWarehouse,
  deleteWarehouse,
  updateWarehouse,
  getProductsPotentialDemand,
  getProductsActualDemand,
  exportProducts,
  importProducts,
  getProducts,
  getProduct,
  createProduct,
  deleteProduct,
  updateProduct,
  getProductRelations,
  createProductRelation,
  getProductBundles,
  createProductBundle,
  getProductTypes,
  updateProductType,
  createProductType,
  deleteProductType,
  getMetadata,
  createMetadata,
  createProductMetadata,
  updateProductMetadata,
  deleteProductMetadata,
  getProductFiles,
  createProductFiles,
  deleteProductFile,
  getProductWarehouses,
  getWarehouseProducts,
  createWarehouseProduct,
  createManyWarehouseProducts,
  deleteWarehouseProduct,
  getWarehouseOperations,
  getOrders,
  getOrder,
  createOrder,
  deleteOrder,
  updateOrder,
  getOrderFiles,
  createOrderFiles,
  deleteOrderFile,
  getOrderNotes,
  createOrderNote,
  updateOrderNote,
  deleteOrderNote,
  getOrderItems,
  createOrderItem,
  deleteOrderItem,
  updateOrderItem,
  getBillings,
  createBilling,
  updateBilling,
  deleteBilling,
  getBillingGroups,
  createBillingGroup,
  updateBillingGroup,
  deleteBillingGroup,
  getBillingSettings,
  getBillingSetting,
  createBillingSetting,
  updateBillingSetting,
  deleteBillingSetting,
  createBillingSettingRule,
  updateBillingSettingRule,
  deleteBillingSettingRule,
  getCalculators,
  createCalculator,
  getCalculator,
  updateCalculator,
  updateCalculatorVisibility,
  updateCalculatorSurvey,
  deleteCalculator,
  duplicateCalculator,
  createCalculatorAdditionalCost,
  updateCalculatorAdditionalCost,
  deleteCalculatorAdditionalCost,
  createCalculatorSection,
  updateCalculatorSection,
  updateCalculatorSectionOrder,
  deleteCalculatorSection,
  createCalculatorField,
  updateCalculatorField,
  updateCalculatorFieldOrder,
  deleteCalculatorField,
  duplicateCalculatorField,
  createCalculatorFieldDependency,
  deleteCalculatorFieldDependency,
  createCalculatorGrant,
  updateCalculatorGrant,
  deleteCalculatorGrant,
  updateCalculatorGrantOrder,
  createCalculatorDiscount,
  updateCalculatorDiscount,
  deleteCalculatorDiscount,
  getInstallations,
  getInstallation,
  createInstallation,
  updateInstallation,
  updateInstallationsStatus,
  deleteInstallation,
  getInstallationServices,
  getInstallationService,
  createInstallationService,
  updateInstallationService,
  deleteInstallationService,
  getInstallationServiceNotes,
  createInstallationServiceNote,
  updateInstallationServiceNote,
  deleteInstallationServiceNote,
  getInstallationStatuses,
  createInstallationStatus,
  updateInstallationStatus,
  updateInstallationStatusOrder,
  deleteInstallationStatus,
  getInstallationTypes,
  createInstallationType,
  updateInstallationType,
  deleteInstallationType,
  getInstallationFiles,
  createInstallationFiles,
  deleteInstallationFile,
  getInstallationNotes,
  createInstallationNote,
  updateInstallationNote,
  deleteInstallationNote,
  getInstallationActions,
  createInstallationAction,
  updateInstallationAction,
  deleteInstallationAction,
  getInstallationCrews,
  getInstallationCrew,
  createInstallationCrew,
  updateInstallationCrew,
  deleteInstallationCrew,
  createInstallationInstallationCrew,
  deleteInstallationInstallationCrew,
  getAuditStatuses,
  createAuditStatus,
  updateAuditStatus,
  updateAuditStatusOrder,
  deleteAuditStatus,
  getAudits,
  getAudit,
  createAudit,
  updateAudit,
  updateAuditsStatus,
  deleteAudit,
  bulkEditStatusAudits,
  getAuditFiles,
  createAuditFiles,
  deleteAuditFile,
  getAuditNotes,
  createAuditNote,
  updateAuditNote,
  deleteAuditNote,
  getAuditActions,
  createAuditAction,
  updateAuditAction,
  deleteAuditAction,
  getApplicationStatuses,
  createApplicationStatus,
  updateApplicationStatus,
  updateApplicationStatusOrder,
  deleteApplicationStatus,
  getApplicationTypes,
  createApplicationType,
  updateApplicationType,
  deleteApplicationType,
  updateApplicationsStatus,
  getApplications,
  getApplication,
  createApplication,
  updateApplication,
  deleteApplication,
  getApplicationFiles,
  createApplicationFiles,
  deleteApplicationFile,
  getApplicationNotes,
  createApplicationNote,
  updateApplicationNote,
  deleteApplicationNote,
  getClients,
  getClient,
  updateClient,
  deleteClient,
  getClientApplications,
  getClientInstallations,
  getSettings,
  updateSetting,
  getContractFileCategories,
  createContractFileCategory,
  deleteContractFileCategory,
  getSurveys,
  createSurvey,
  getSurvey,
  updateSurvey,
  createSurveySection,
  updateSurveySection,
  updateSurveySectionOrder,
  deleteSurveySection,
  createSurveyQuestion,
  updateSurveyQuestion,
  updateSurveyQuestionOrder,
  deleteSurveyQuestion,
  getCalculationSurveys,
  createSurveyEntry,
  createSurveyEntrySignature,
  downloadSurveyEntryPdf,
  getClientSurveyEntries,
  getContractTemplates,
  createContractTemplate,
  deleteContractTemplate,
  downloadContractTemplate,
  getCalculatorContractTemplate,
  createModelView,
};
