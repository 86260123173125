import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { CalculatorGrantEditDialog } from "@/components/Calculators/Configuration/Grants/CalculatorGrantEditDialog";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { CalculatorGrantType } from "@/enums/CalculatorGrantTypeEnum";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { useSortable } from "@dnd-kit/sortable";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { GripVertical, Trash } from "lucide-react";
import { useParams } from "react-router-dom";

export const CalculatorGrant = ({ grant }) => {
  const queryClient = useQueryClient();
  const { id: calculatorId } = useParams();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: grant.id });

  const style = {
    transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined,
    transition,
  };

  const deleteCalculatorGrantMutation = useMutation({
    mutationFn: apiClient.deleteCalculatorGrant,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["calculator", calculatorId] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleDelete = () => {
    deleteCalculatorGrantMutation.mutate({ calculatorGrantId: grant.id, calculatorId });
  };

  return (
    <div ref={setNodeRef} style={style} className="flex flex-col gap-2 w-full">
      <Card className="w-full h-fit break-inside-avoid">
        <CardHeader className="flex flex-row justify-between">
          <div className="flex flex-col gap-1.5">
            <CardTitle>
              <div
                className="flex flex-row gap-1 items-center cursor-move font-medium w-fit"
                {...attributes}
                {...listeners}
              >
                <GripVertical size={16} className="shrink-0" />
                {grant.name}
              </div>
            </CardTitle>
            <CardDescription>{CalculatorGrantType[grant.type].name}</CardDescription>
          </div>
          <div className="flex flex-row justify-end items-start">
            <CalculatorGrantEditDialog grant={grant} />
            <DeleteAlertDialog
              trigger={
                <Button
                  leftIcon={<Trash size={16} />}
                  title="Usuń dotację"
                  variant="outline"
                  isLoading={deleteCalculatorGrantMutation.isPending}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć tę dotację? Tej operacji nie można cofnąć"
            />
          </div>
        </CardHeader>
        <CardContent>
          {grant.type === CalculatorGrantType.PERCENT.value && (
            <div className="flex flex-row gap-1 items-center">
              <p className="flex flex-row w items-center font-medium">{i18n.t("Dotacja w wysokości")}</p>
              <p className="font-medium text-primary border py-1 px-1 rounded-md">{grant.value}%</p>
              {grant.max_amount > 0 && (
                <>
                  <p className="flex flex-row w items-center font-medium">{i18n.t("ale nie więcej niż")}</p>
                  <p className="font-medium text-primary border py-1 px-1 rounded-md">
                    {formatMoney(grant.max_amount)}
                  </p>
                </>
              )}
            </div>
          )}
          {grant.type === CalculatorGrantType.FIXED.value && (
            <div className="flex flex-row gap-1 items-center">
              <p className="flex flex-row w items-center font-medium">{i18n.t("Dotacja w wysokości")}</p>
              <p className="font-medium text-primary border py-1 px-1 rounded-md">{formatMoney(grant.value)}</p>
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};
