import apiClient from "@/api/apiClient";
import { ApplicationCreateDialog } from "@/components/Applications/ApplicationCreateDialog";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { ContractDetails } from "@/components/Contracts/Details/ContractDetails";
import { ContractFileCreateDialog } from "@/components/Contracts/Details/Files/ContractFileCreateDialog";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { InstallationCreateDialog } from "@/components/Installations/InstallationCreateDialog";
import { InstallationServiceCreateDialog } from "@/components/Installations/Services/InstallationServiceCreateDialog";
import { Layout } from "@/components/layout/Layout";
import { TaskCreateDialog } from "@/components/Tasks/TaskCreateDialog";
import { ButtonContext } from "@/context/ButtonContext";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useContractPaymentPolicy } from "@/policies/contracts/useContractPaymentPolicy";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { HandCoins, Pencil, Trash } from "lucide-react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const ContractDetailsPage = () => {
  const { id: contractId } = useParams();
  const navigate = useNavigate();
  const contractPolicy = useContractPolicy();
  const contractPaymentPolicy = useContractPaymentPolicy();
  const { buttons } = useContext(ButtonContext);
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["contract", contractId],
    queryFn: () => apiClient.getContract(contractId),
  });
  useDocumentTitle(response?.data ? `Umowa ${response?.data?.identifier}` : null);

  const deleteContractMutation = useMutation({
    mutationFn: apiClient.deleteContract,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["contracts"] });
      if (res.ok) {
        return navigate(routes.contracts);
      }
    },
  });

  const handleEdit = () => {
    return navigate(`${routes.contracts}/${contractId}/edytuj`);
  };

  const handleDelete = () => {
    deleteContractMutation.mutate(contractId);
  };

  const handleAddPayment = () => {
    if (contractPaymentPolicy.create()) {
      return navigate(`${routes.contracts}/${contractId}/platnosci/dodaj`, { state: { contract: response.data } });
    }
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={response?.data?.identifier} />
        <div
          id="contract_buttons"
          className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5"
        >
          {buttons["addContractFiles"] && <ContractFileCreateDialog />}

          {buttons["addContractPayment"] && response?.data?.gross_price !== response?.data?.payment_sum && (
            <Button title="Dodaj płatność" onClick={handleAddPayment} leftIcon={<HandCoins size={20} />} />
          )}
          {buttons["addContractTask"] && (
            <TaskCreateDialog taskableId={contractId} taskableType="Contract" withUser={true} />
          )}
          {buttons["addInstallation"] && <InstallationCreateDialog contract={response?.data} buttonVariant="default" />}
          {buttons["addInstallationService"] && (
            <InstallationServiceCreateDialog contract={response?.data} buttonVariant="default" />
          )}
          {buttons["addApplication"] && <ApplicationCreateDialog lead={response?.data?.lead} buttonVariant="default" />}
          {contractPolicy.update(response?.data) && (
            <Button variant="outline" title="Edytuj umowę" leftIcon={<Pencil size={20} />} onClick={handleEdit} />
          )}
          {contractPolicy.destroy() && (
            <DeleteAlertDialog
              trigger={
                <Button
                  variant="outline"
                  title="Usuń umowę"
                  isLoading={deleteContractMutation.isPending}
                  leftIcon={<Trash size={20} />}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć tę umowę? Tej operacji nie można cofnąć"
            />
          )}
        </div>
      </div>
      <ContractDetails contract={response?.data} />
    </Layout>
  );
};
