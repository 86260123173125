import { useAuth } from "@/hooks/useAuth";
import i18n from "@/i18n";
import { cn } from "@/lib/utils";
import { TriangleAlert } from "lucide-react";
import { useEffect, useState } from "react";

export const ImpersonationBanner = () => {
  const [visible, setVisible] = useState(false);
  const { user, impersonatingUser } = useAuth();

  useEffect(() => {
    if (impersonatingUser) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  }, [impersonatingUser]);

  return (
    <div
      className={cn(
        "fixed top-0 left-0 w-full h-fit py-1 bg-red-600 z-50 text-foreground flex flex-row gap-3 justify-center items-center font-semibold",
        !visible && "hidden",
      )}
    >
      <TriangleAlert className="h-6 w-6" />
      <p className="flex flex-row gap-1">
        {i18n.t("Uwaga! Jesteś zalogowany jako")} <p className="font-bold">{`${user.name}.`}</p>
        {i18n.t("Postępuj z rozwagą")}
      </p>
      <TriangleAlert className="h-6 w-6" />
    </div>
  );
};
