import { AuditInformationCard } from "@/components/Audits/AuditDetails/AuditInformationCard";
import { ClientActionsCard } from "@/components/Clients/ClientDetails/ClientActionsCard";
import { ClientApplicationsTab } from "@/components/Clients/ClientDetails/ClientApplicationsTab";
import { ClientCalculationsTable } from "@/components/Clients/ClientDetails/ClientCalculationsTable";
import { ClientContractsCard } from "@/components/Clients/ClientDetails/ClientContractsCard";
import { ClientFilesTable } from "@/components/Clients/ClientDetails/ClientFilesTable";
import { ClientInformationCard } from "@/components/Clients/ClientDetails/ClientInformationCard";
import { ClientInstallationServicesCard } from "@/components/Clients/ClientDetails/ClientInstallationServicesCard";
import { ClientInstallationsTab } from "@/components/Clients/ClientDetails/ClientInstallationsTab";
import { ClientNotesCard } from "@/components/Clients/ClientDetails/ClientNotesCard";
import { ClientSurveyEntriesTab } from "@/components/Clients/ClientDetails/ClientSurveyEntriesTab";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { useApplicationPolicy } from "@/policies/application/useApplicationPolicy";
import { useAuditPolicy } from "@/policies/audit/useAuditPolicy";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { useInstallationServicePolicy } from "@/policies/installation/useInstallationServicePolicy";
import { useLeadActionPolicy } from "@/policies/lead/useLeadActionPolicy";
import { useLeadCalculationPolicy } from "@/policies/lead/useLeadCalculationPolicy";
import { useLeadFilePolicy } from "@/policies/lead/useLeadFilePolicy";
import { useLeadNotePolicy } from "@/policies/lead/useLeadNotePolicy";
import { useSurveyEntryPolicy } from "@/policies/useSurveyEntryPolicy";

export const ClientDetails = ({ client }) => {
  const { activeTab, setActiveTab } = useTabs("information");
  const contractPolicy = useContractPolicy();
  const clientNotePolicy = useLeadNotePolicy();
  const clientActionPolicy = useLeadActionPolicy();
  const clientFilePolicy = useLeadFilePolicy();
  const clientCalculationPolicy = useLeadCalculationPolicy();
  const auditPolicy = useAuditPolicy();
  const applicationPolicy = useApplicationPolicy();
  const installationPolicy = useInstallationPolicy();
  const installationServicePolicy = useInstallationServicePolicy();
  const surveyEntryPolicy = useSurveyEntryPolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      {(clientCalculationPolicy.viewAny() || clientFilePolicy.viewAny()) && (
        <TabsList className="mb-6">
          <TabsTrigger value="information">{i18n.t("Informacje")}</TabsTrigger>
          {clientFilePolicy.viewAny() && <TabsTrigger value="files">{i18n.t("Pliki")}</TabsTrigger>}
          {clientCalculationPolicy.viewAny() && <TabsTrigger value="calculations">{i18n.t("Kalkulacje")}</TabsTrigger>}
          {auditPolicy.view() && client.audit && <TabsTrigger value="audit">{i18n.t("Audyt")}</TabsTrigger>}
          {installationPolicy.viewAny() && <TabsTrigger value="installations">{i18n.t("Montaże")}</TabsTrigger>}
          {applicationPolicy.viewAny() && <TabsTrigger value="applications">{i18n.t("Wnioski")}</TabsTrigger>}
          {surveyEntryPolicy.viewAny() && <TabsTrigger value="surveys">{i18n.t("Ankiety")}</TabsTrigger>}
        </TabsList>
      )}
      <TabsContent value="information">
        <div className="flex flex-col lg:flex-row w-full gap-5">
          <ClientInformationCard client={client} />
          <div className="flex flex-1 flex-col gap-5 h-fit">
            {clientActionPolicy.viewAny() && <ClientActionsCard client={client} />}
            {clientNotePolicy.viewAny() && <ClientNotesCard />}
          </div>
          <div className="flex flex-1 flex-col gap-5 h-fit">
            {contractPolicy.viewAny() && <ClientContractsCard client={client} contracts={client.contracts} />}
            {installationServicePolicy.viewAny() && (
              <ClientInstallationServicesCard installationServices={client.installationServices} />
            )}
          </div>
        </div>
      </TabsContent>
      {clientFilePolicy.viewAny() && (
        <TabsContent value="files">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ClientFilesTable key="files_list" />
          </div>
        </TabsContent>
      )}
      {clientCalculationPolicy.viewAny() && (
        <TabsContent value="calculations">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ClientCalculationsTable key="calculations_list" />
          </div>
        </TabsContent>
      )}
      {auditPolicy.view() && client.audit && (
        <TabsContent value="audit">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <AuditInformationCard audit={client.audit} client={client} />
            <div className="flex-1"></div>
          </div>
        </TabsContent>
      )}
      {installationPolicy.viewAny() && (
        <TabsContent value="installations">
          <ClientInstallationsTab />
        </TabsContent>
      )}
      {applicationPolicy.viewAny() && (
        <TabsContent value="applications">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ClientApplicationsTab />
          </div>
        </TabsContent>
      )}
      {surveyEntryPolicy.viewAny() && (
        <TabsContent value="surveys">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <ClientSurveyEntriesTab />
          </div>
        </TabsContent>
      )}
    </Tabs>
  );
};
