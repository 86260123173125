import { FormCalculatorAdditionalOptionsField } from "@/components/forms/FormCalculatorAdditionalOptionsField";
import { FormCalculatorMultipleSelectField } from "@/components/forms/FormCalculatorMultipleSelectField";
import { FormCalculatorSelectField } from "@/components/forms/FormCalculatorSelectField";
import { FormCombobox } from "@/components/forms/FormCombobox";
import { FormNumberField } from "@/components/forms/FormNumberField";
import { FormSelect } from "@/components/forms/FormSelect";
import { ProductsContext } from "@/context/ProductsContext";
import { ProductTypeContext } from "@/context/ProductTypeContext";
import { CalculatorFieldType } from "@/enums/CalculatorFieldTypeEnum";
import { evaluateCondition } from "@/helpers/calculators/evaluateCondition";
import { formatMoney } from "@/helpers/formatMoney";
import { useCalculatorCustomFields } from "@/hooks/useCalculatorCustomFields";
import { cn } from "@/lib/utils";
import { useProductPolicy } from "@/policies/useProductPolicy";
import { useContext, useEffect, useState } from "react";
import { useFormContext, useWatch } from "react-hook-form";

export const CalculatorField = ({ field, sectionFields }) => {
  const { products, isLoading } = useContext(ProductsContext);
  const { types, isLoading: isLoadingTypes } = useContext(ProductTypeContext);
  const productPolicy = useProductPolicy();
  const { control, getValues, setValue, resetField } = useFormContext();
  const customFields = useCalculatorCustomFields();
  const [isVisible, setIsVisible] = useState(field.visible);

  const watchedDependencyValues = field.dependencies.map((dependency) => {
    const { attribute, depends_on_field } = dependency;
    const attributeKey =
      attribute === "quantity" ? `${depends_on_field.identifier}_quantity` : depends_on_field.identifier;
    return useWatch({ control, name: attributeKey });
  });

  const getProductOptions = (currentField) => {
    const filteredProducts = products.filter((product) => product.type.id === currentField.product_type);
    let defaultOptions = getProductFieldOptionsWithContent(currentField, filteredProducts);
    let options = defaultOptions;

    const selectedValues = sectionFields
      .filter((f) => f.type === "PRODUCT" && f.identifier !== currentField.identifier)
      .map((f) => getValues(f.identifier))
      .filter(Boolean);

    if (selectedValues.length > 0) {
      const lastSelectedValue = selectedValues[selectedValues.length - 1];
      const selectedProduct = products.find((product) => product.id === lastSelectedValue);

      if (selectedProduct && selectedProduct.productRelations?.length > 0) {
        const relatedProductTypeIds = new Set(
          selectedProduct.productRelations.map((relation) => relation.relatedProduct.type.id),
        );
        const productRelationProducts = selectedProduct.productRelations.flatMap((relation) => relation.relatedProduct);

        const relatedProducts = productRelationProducts.filter(
          (product) => relatedProductTypeIds.has(product.type.id) && product.type.id === currentField.product_type,
        );

        if (relatedProducts.length > 0) {
          options = getProductFieldOptionsWithContent(currentField, relatedProducts);
          const allowedValues = new Set(options.map((option) => option.value));
          if (getValues(currentField.identifier) && !allowedValues.has(getValues(currentField.identifier))) {
            resetField(currentField.identifier);
          }
        }
      }
    }

    return options;
  };

  useEffect(() => {
    if (field.dependencies.length > 0) {
      const isFieldVisible = field.dependencies.some((dependency) => {
        const { condition, action } = dependency;
        const attribute = condition.attribute;
        let fieldValue;
        let meetsCondition = null;

        if (attribute === "quantity") {
          fieldValue = getValues(`${dependency.depends_on_field.identifier}_quantity`);
          meetsCondition = evaluateCondition(fieldValue, condition);
        } else if (attribute === "value") {
          fieldValue = getValues(dependency.depends_on_field.identifier);
          meetsCondition = evaluateCondition(fieldValue, condition);
        } else if (attribute === "has_value") {
          fieldValue = getValues(dependency.depends_on_field.identifier);
          if (fieldValue && fieldValue !== "null") meetsCondition = true;
          else meetsCondition = false;
        } else if (attribute === "doesnt_have_value") {
          fieldValue = getValues(dependency.depends_on_field.identifier);
          if (!fieldValue || fieldValue === "null") meetsCondition = true;
          else meetsCondition = false;
        }

        return action === "SHOW" ? meetsCondition : !meetsCondition;
      });

      setIsVisible((prev) => {
        if (prev !== isFieldVisible) {
          if (!isFieldVisible) {
            setValue(field.identifier, undefined);
            if (field.has_quantity) {
              setValue(`${field.identifier}_quantity`, undefined);
            }
          }
          return isFieldVisible;
        }
        return prev;
      });
    }
  }, [watchedDependencyValues]);

  const renderFieldQuantityField = (field) => {
    if (field.has_quantity)
      return (
        <FormNumberField name={field.identifier + "_quantity"} label={field.unit ? `Ilość (${field.unit})` : "Ilość"} />
      );
    else return null;
  };

  useEffect(() => {
    setValue(`visibility.${field.identifier}`, !!isVisible);
  }, [isVisible]);

  const getProductFieldOptionsWithContent = (field, filteredProducts) => {
    let options = filteredProducts.map((product) => {
      if (productPolicy.create()) {
        if (product.warehouses_count) {
          if (product.total_quantity !== null) {
            product.content = (
              <span className="flex flex-col gap-1 justify-center">
                <p className={cn("font-semibold", product.total_quantity === 0 && "text-destructive")}>
                  {`${product.name} (${product.total_quantity} szt. w magazynach)`}
                </p>
                <p className="text-xs font-semibold">{formatMoney(product.price)}</p>
                <p className="text-xs text-muted-foreground">{product.description}</p>
              </span>
            );
          } else {
            product.content = (
              <span className="flex flex-col gap-1 justify-center">
                <p className="font-semibold">{`${product.name} (w magazynie zewnętrznym)`}</p>
                <p className="text-xs font-semibold">{formatMoney(product.price)}</p>
                <p className="text-xs text-muted-foreground">{product.description}</p>
              </span>
            );
          }
        } else {
          product.content = (
            <span className="flex flex-col gap-1 justify-center">
              <p className="font-semibold text-destructive">{`${product.name} (nie dodany)`}</p>
              <p className="text-xs font-semibold">{formatMoney(product.price)}</p>
              <p className="text-xs text-muted-foreground">{product.description}</p>
            </span>
          );
        }
      } else {
        product.content = (
          <span className="flex flex-col gap-1 justify-center">
            <p className="font-semibold">{product.name}</p>
            <p className="text-xs text-muted-foreground">{product.description}</p>
          </span>
        );
      }

      product.value = product.id;

      return product;
    });
    if (!field.required) {
      options = [{ name: "Brak", value: "null" }, ...options];
    }
    return options.map(({ name, value, content }) => ({ name, value, content }));
  };

  const renderProductFieldCustomFields = (field) => {
    if (isLoadingTypes) return null;
    const productType = types.find((type) => type.id === field.product_type);
    const matchedCustomField = Object.values(customFields).find(
      (field) => field.productType && field.productType === productType?.name,
    );
    if (matchedCustomField && matchedCustomField?.visible) {
      let calculatedValue;
      const fieldValue = getValues(field.identifier);
      const fieldQuantity = getValues(`${field.identifier}_quantity`);
      if (field.has_quantity) {
        if (fieldQuantity) {
          calculatedValue = matchedCustomField.calculate([
            { ...field, value: fieldValue, quantity: parseFloat(fieldQuantity) },
          ]);
        }
      } else {
        calculatedValue = matchedCustomField.calculate([{ ...field, value: fieldValue, quantity: 1 }]);
      }
      if (calculatedValue && calculatedValue != 0)
        return (
          <div className="text-primary mt-1 font-medium">
            {`${matchedCustomField.name}: ${calculatedValue} ${matchedCustomField.unit}`}
          </div>
        );
    }
    return null;
  };

  const renderFieldByType = (field) => {
    switch (field.type) {
      case CalculatorFieldType.LIST.value: {
        let options = field.field_options;
        if (!field.required) {
          options = [{ name: "Brak", value: "null" }, ...options];
        }
        return (
          <FormCalculatorSelectField
            options={options}
            defaultValue={options.find((option) => option.default)?.name ?? undefined}
            label={field.name}
            name={field.identifier}
            description={field.description}
          />
        );
      }
      case CalculatorFieldType.MULTIPLE.value: {
        let options = field.field_options;
        return (
          <FormCalculatorMultipleSelectField
            options={options}
            defaultValue={options.find((option) => option.default)?.name ?? undefined}
            label={field.name}
            name={field.identifier}
            description={field.description}
          />
        );
      }
      case CalculatorFieldType.ADDITIONAL.value: {
        return (
          <FormCalculatorAdditionalOptionsField
            description={field.description}
            label={field.name}
            name={field.identifier}
          />
        );
      }
      case CalculatorFieldType.PRODUCT.value: {
        const options = getProductOptions(field);
        return (
          <>
            <FormCombobox
              isLoading={isLoading || isLoadingTypes}
              description={field.description}
              options={options}
              label={field.name}
              name={field.identifier}
            />
            {renderProductFieldCustomFields(field)}
          </>
        );
      }
      case CalculatorFieldType.NUMBER.value:
        return <FormNumberField label={field.name} description={field.description} name={field.identifier} />;
      case CalculatorFieldType.VAT.value: {
        return (
          <FormSelect
            options={field.field_options}
            description={field.description}
            label={field.name}
            name={field.identifier}
          />
        );
      }
      case CalculatorFieldType.COMISSION.value: {
        return <FormNumberField label={field.name} description={field.description} name={field.identifier} />;
      }
      default:
        return null;
    }
  };

  const renderField = (field) => {
    const renderedField = renderFieldByType(field);
    const renderedQuantityField = renderFieldQuantityField(field);
    if (renderedField) {
      return isVisible ? (
        <div className="flex flex-row items-start gap-3">
          <div className="flex-1">{renderedField}</div>
          {renderedQuantityField && <div className="w-1/5">{renderedQuantityField}</div>}
        </div>
      ) : null;
    }
  };

  return renderField(field);
};
