import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { InstallationCrewDetails } from "@/components/Installations/Crews/Details/InstallationCrewDetails";
import { InstallationCrewEditDialog } from "@/components/Installations/Crews/InstallationCrewEditDialog";
import { Layout } from "@/components/layout/Layout";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useInstallationCrewPolicy } from "@/policies/installation/useInstallationCrewPolicy";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Pencil, Trash } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

export const InstallationCrewDetailsPage = () => {
  const { id: installationCrewId } = useParams();
  const navigate = useNavigate();
  const installationCrewPolicy = useInstallationCrewPolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["installationCrew", installationCrewId],
    queryFn: () => apiClient.getInstallationCrew(installationCrewId),
  });
  useDocumentTitle(response?.data ? `Ekipa montażowa ${response?.data?.name}` : null);

  const deleteInstallationCrewMutation = useMutation({
    mutationFn: apiClient.deleteInstallationCrew,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["installationCrew", installationCrewId] });
      if (res.ok) {
        return navigate(routes.installations);
      }
    },
  });

  const handleDelete = () => {
    deleteInstallationCrewMutation.mutate(installationCrewId);
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={response?.data?.name} />
        <div
          id="installation_crew_buttons"
          className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5"
        >
          <InstallationCrewEditDialog
            installationCrew={response?.data}
            trigger={<Button variant="outline" title="Edytuj ekipę" leftIcon={<Pencil size={20} />} />}
          />
          {installationCrewPolicy.destroy() && (
            <DeleteAlertDialog
              trigger={
                <Button
                  variant="outline"
                  title="Usuń ekipę"
                  isLoading={deleteInstallationCrewMutation.isPending}
                  leftIcon={<Trash size={20} />}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć tą ekipę montażową? Tej operacji nie można cofnąć"
            />
          )}
        </div>
      </div>
      <InstallationCrewDetails installationCrew={response?.data} />
    </Layout>
  );
};
