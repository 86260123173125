import mapPinClient from "@/assets/icons/mapPin/mapPinClient.svg";
import { ClientFilters } from "@/components/Clients/ClientFilters";
import { ClientPopup } from "@/components/Clients/ClientPopup";
import { TablePagination } from "@/components/Table/TablePagination";
import { config } from "@/config";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

export const ClientsMap = ({ clients, meta, table }) => {
  const mapStartingPoint = config("map.startingPoint");
  const mapZoom = config("map.zoom");

  const clientIcon = new Icon({
    iconUrl: mapPinClient,
    iconSize: [38, 38],
    iconAnchor: [19, 38],
    popupAnchor: [0, -19],
  });

  return (
    <div className="w-full">
      <ClientFilters table={table} withColumns={false} />
      <MapContainer center={mapStartingPoint} zoom={mapZoom}>
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        <MarkerClusterGroup chunkedLoading>
          {clients.map((client) => {
            if (client.address) {
              const { latitude, longitude } = client.address;
              if (!latitude || !longitude) return null;
              return (
                <Marker key={client.id} position={[parseFloat(latitude), parseFloat(longitude)]} icon={clientIcon}>
                  <Popup maxWidth={500}>
                    <ClientPopup client={client} />
                  </Popup>
                </Marker>
              );
            }
          })}
        </MarkerClusterGroup>
      </MapContainer>
      <TablePagination meta={meta} table={table} />
    </div>
  );
};
