import { flexRender } from "@tanstack/react-table";

import { Loader } from "@/components/Loader";
import { SortableRow } from "@/components/Table/SortableRow";
import { TableLoader } from "@/components/Table/TableLoader";
import { TablePagination } from "@/components/Table/TablePagination";
import { Table as ShadTable, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import i18n from "@/i18n";
import { closestCenter, DndContext, KeyboardSensor, MouseSensor, useSensor, useSensors } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useEffect, useState } from "react";

export function SortableTable({ table, meta, Filters = null, isLoading = false, isFetching = false, onOrderChange }) {
  const [rows, setRows] = useState(table.getRowModel().flatRows);

  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates }),
  );

  const handleDragEnd = ({ active, over }) => {
    if (active.id !== over.id) {
      const oldIndex = rows.findIndex((row) => row.id === active.id);
      const newIndex = rows.findIndex((row) => row.id === over.id);
      const newOrder = arrayMove(rows, oldIndex, newIndex);
      setRows(newOrder);
      onOrderChange && onOrderChange(newOrder);
    }
  };

  useEffect(() => {
    setRows(table.getRowModel().rows);
  }, [table.getRowModel().rows]);

  return (
    <div className="w-full overflow-x-clip">
      {Filters && Filters({ table })}
      <div className="rounded-md border">
        <ShadTable className="w-full overflow-x-clip">
          {isFetching && !isLoading && <TableLoader />}
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                <TableHead className="w-fit"></TableHead>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          {isLoading && (
            <TableBody>
              <TableRow className="w-full">
                <TableCell colSpan={table.getAllColumns().length}>
                  <Loader />
                </TableCell>
              </TableRow>
            </TableBody>
          )}
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={rows} strategy={verticalListSortingStrategy}>
              <TableBody className="overflow-x-clip">
                {rows?.length ? (
                  rows.map((row) => <SortableRow key={row.id} row={row} />)
                ) : (
                  <TableRow>
                    <TableCell colSpan={table.getAllColumns().length} className="h-24 text-center">
                      {i18n.t("Brak wyników.")}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </SortableContext>
          </DndContext>
        </ShadTable>
      </div>
      <TablePagination meta={meta} table={table} />
    </div>
  );
}
