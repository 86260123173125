import { Select } from "@/components/forms/Select";
import { ViewMode } from "@/enums/ViewModeEnum";
import { useEffect, useState } from "react";

export const useViewModes = (initialMode, modes = []) => {
  const [options, setOptions] = useState([]);
  const [viewMode, setViewMode] = useState(initialMode.value);

  useEffect(() => {
    if (modes.length) {
      const filteredModes = modes.map((m) => ViewMode[m.value]).filter(Boolean);
      setOptions(filteredModes);
    }
  }, []);

  const renderModeSwitcher = () => (
    <Select options={options} className="w-fit whitespace-nowrap gap-2" value={viewMode} setValue={setViewMode} />
  );

  console.log(viewMode);

  return { renderModeSwitcher, viewMode };
};
