import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { Layout } from "@/components/layout/Layout";
import { OrderFileCreateDialog } from "@/components/Order/OrderDetails/Files/OrderFileCreateDialog";
import { OrderDetails } from "@/components/Order/OrderDetails/OderDetails";
import { OrderEditDialog } from "@/components/Order/OrderEditDialog";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { WarningAlertDialog } from "@/components/WarningAlertDialog";
import { ButtonContext } from "@/context/ButtonContext";
import { OrderStatus } from "@/enums/OrderStatusEnum";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import { useTrackModelView } from "@/hooks/useTrackModelView";
import { useOrderPolicy } from "@/policies/order/useOrderPolicy";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ListChecks, PackageCheck, Pencil, Trash } from "lucide-react";
import { useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";

export const OrderDetailsPage = () => {
  const { id: orderId } = useParams();
  const navigate = useNavigate();
  const orderPolicy = useOrderPolicy();
  const queryClient = useQueryClient();
  const { buttons } = useContext(ButtonContext);
  const { activeTab, setActiveTab } = useTabs("information");

  const handleStatusClick = (status) => {
    if (status) {
      updateLeadStatusMutation.mutate({ orderId, data: { status } });
    }
  };

  const updateLeadStatusMutation = useMutation({
    mutationFn: apiClient.updateOrder,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
      queryClient.invalidateQueries({ queryKey: ["order", orderId] });
      if (!res.ok) {
        toast({ title: <ErrorToast title="Błąd podczas zapisywania." /> });
      } else {
        toast({ title: <SuccessToast title="Pomyślnie zapisano" /> });
      }
    },
  });

  const { isLoading, data: response } = useQuery({
    queryKey: ["order", orderId],
    queryFn: () => apiClient.getOrder(orderId),
  });
  useTrackModelView("Order", response?.data, response?.data?.user);
  useDocumentTitle(response?.data ? `Zamówienie ${response?.data?.contract?.identifier}` : null);

  const deleteOrderMutation = useMutation({
    mutationFn: apiClient.deleteOrder,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["orders"] });
      if (res.ok) {
        return navigate(routes.orders);
      }
    },
  });

  const handleDelete = () => {
    if (orderPolicy.destroy(response.data)) {
      deleteOrderMutation.mutate(orderId);
    }
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={`Zamówienie ${response?.data?.contract.name}`} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {buttons["addOrderFiles"] && <OrderFileCreateDialog />}
          {activeTab === "information" && response?.data?.status === OrderStatus.NEW.value && (
            <WarningAlertDialog
              message="Czy na pewno chcesz skompletować to zamówienie? Tej operacji nie można cofnąć i nie będzie już można wprowadzać zmian w sekcji realizacji zamówienia a stany magazynowe zostaną zaktualizowane"
              onConfirm={() => handleStatusClick(OrderStatus.COMPLETED.value)}
              trigger={
                <Button
                  leftIcon={<ListChecks size={20} />}
                  title="Zamówienie kompletne"
                  className="text-background dark:text-foreground bg-opacity-90"
                />
              }
            />
          )}
          {activeTab === "information" && response?.data?.status === OrderStatus.COMPLETED.value && (
            <WarningAlertDialog
              message="Czy na pewno chcesz oznaczyć to zamówienie jako wysłane? Tej operacji nie można cofnąć."
              onConfirm={() => handleStatusClick(OrderStatus.SHIPPED.value)}
              trigger={
                <Button
                  leftIcon={<PackageCheck size={20} />}
                  title="Zamówienie wysłane"
                  className="text-background dark:text-foreground bg-opacity-90"
                />
              }
            />
          )}
          {activeTab === "information" && (
            <OrderEditDialog
              order={response?.data}
              trigger={<Button variant="outline" title="Edytuj" leftIcon={<Pencil size={16} />} />}
            />
          )}
          {activeTab === "information" && orderPolicy.destroy(response?.data) && (
            <DeleteAlertDialog
              trigger={
                <Button
                  variant="outline"
                  title="Usuń zamówienie"
                  isLoading={deleteOrderMutation.isPending}
                  leftIcon={<Trash size={16} />}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć ten zamówienie? Tej operacji nie można cofnąć"
            />
          )}
        </div>
      </div>
      <OrderDetails order={response?.data} activeTab={activeTab} setActiveTab={setActiveTab} />
    </Layout>
  );
};
