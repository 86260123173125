import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { DashboardRoleBasedOverview } from "@/components/Dashboard/DashboardRoleBasedOverview";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent } from "@/components/ui/tabs";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import { useDashboardPolicy } from "@/policies/useDashboardPolicy";
import { useQuery } from "@tanstack/react-query";

export const DashboardPage = () => {
  useDocumentTitle("Pulpit");
  const { activeTab, setActiveTab } = useTabs("overview");
  const dashboardPolicy = useDashboardPolicy();

  const { isLoading, data: response } = useQuery({
    queryKey: ["dashboard", "overview"],
    queryFn: apiClient.getDashboardOverview,
    staleTime: 1000 * 60 * 10,
    enabled: dashboardPolicy.viewOverview(),
  });

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col justify-between gap-4">
        <Breadcrumbs />
        <Tabs value={activeTab} onValueChange={setActiveTab}>
          {/* {(dashboardPolicy.viewStats() || dashboardPolicy.viewOverview()) && (
            <TabsList className="mb-6">
              <TabsTrigger value="overview">{i18n.t("Przegląd")}</TabsTrigger>
              <TabsTrigger value="stats">{i18n.t("Statystyki")}</TabsTrigger>
            </TabsList>
          )} */}
          {dashboardPolicy.viewOverview() && response && (
            <TabsContent value="overview">
              <DashboardRoleBasedOverview data={response.data} />
            </TabsContent>
          )}
          {/* {dashboardPolicy.viewStats() && (
            <TabsContent value="stats">
              <DashboardStats />
            </TabsContent>
          )} */}
        </Tabs>
      </div>
    </Layout>
  );
};
