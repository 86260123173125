import { AuditStatusCreateDialog } from "@/components/Audits/Settings/Statuses/AuditStatusCreateDialog";
import { AuditStatuses } from "@/components/Audits/Settings/Statuses/AuditStatuses";
import { ProcessCreateDialog } from "@/components/Process/ProcessCreateDialog";
import { ProcessSettings } from "@/components/Process/ProcessSettings";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { useAuditStatusPolicy } from "@/policies/audit/useAuditStatusPolicy";
import { useProcessPolicy } from "@/policies/useProcessPolicy";

export const AuditSettings = () => {
  const { activeTab, setActiveTab } = useTabs("statuses");
  const auditStatusPolicy = useAuditStatusPolicy();
  const processPolicy = useProcessPolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList className="mb-6">
        {auditStatusPolicy.update() && <TabsTrigger value="statuses">{i18n.t("Statusy")}</TabsTrigger>}
        {processPolicy.create() && <TabsTrigger value="processes">{i18n.t("Procesy")}</TabsTrigger>}
      </TabsList>
      {auditStatusPolicy.create() && (
        <TabsContent value="statuses">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <AuditStatusCreateDialog />
          </div>
          <AuditStatuses />
        </TabsContent>
      )}
      {processPolicy.create() && (
        <TabsContent value="processes">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Audit" />
          </div>
          <ProcessSettings model="Audit" />
        </TabsContent>
      )}
    </Tabs>
  );
};
