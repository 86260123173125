import mapPinAudit from "@/assets/icons/mapPin/mapPinAudit.svg";
import { AuditFilters } from "@/components/Audits/AuditFilters";
import { AuditPopup } from "@/components/Audits/Map/AuditPopup";
import { Loader } from "@/components/Loader";
import { TablePagination } from "@/components/Table/TablePagination";
import { config } from "@/config";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-cluster";

export const AuditsMap = ({ audits, table, meta, isLoading, isFetching }) => {
  const mapStartingPoint = config("map.startingPoint");
  const mapZoom = config("map.zoom");

  const auditIcon = new Icon({
    iconUrl: mapPinAudit,
    iconSize: [45, 45],
    iconAnchor: [22, 45],
    popupAnchor: [0, -19],
  });

  return (
    <div className="w-full">
      <AuditFilters table={table} withColumns={false} withActions={false} />
      <MapContainer center={mapStartingPoint} zoom={mapZoom}>
        {isLoading || isFetching ? (
          <Loader />
        ) : (
          <>
            <TileLayer
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <MarkerClusterGroup chunkedLoading>
              {audits.map((audit) => {
                if (audit?.lead?.address) {
                  const { latitude, longitude } = audit.lead.address;
                  if (!latitude || !longitude) return null;
                  return (
                    <Marker key={audit.id} position={[parseFloat(latitude), parseFloat(longitude)]} icon={auditIcon}>
                      <Popup maxWidth={500}>
                        <AuditPopup audit={audit} />
                      </Popup>
                    </Marker>
                  );
                }
              })}
            </MarkerClusterGroup>
          </>
        )}
      </MapContainer>
      <TablePagination meta={meta} table={table} />
    </div>
  );
};
