import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { CalculatorAdditionalCostEditDialog } from "@/components/Calculators/Configuration/AdditionalCosts/CalculatorAdditionalCostEditDialog";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { toast } from "@/components/ui/use-toast";
import { CalculatorAdditionalCostType } from "@/enums/CalculatorAdditionalCostTypeEnum";
import { formatMoney } from "@/helpers/formatMoney";
import i18n from "@/i18n";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Trash } from "lucide-react";
import { useParams } from "react-router-dom";

export const CalculatorAdditionalCost = ({ cost }) => {
  const queryClient = useQueryClient();
  const { id: calculatorId } = useParams();

  const deleteCalculatorAdditionalCostMutation = useMutation({
    mutationFn: apiClient.deleteCalculatorAdditionalCost,
    onSuccess: (res) => {
      if (res.ok) {
        queryClient.invalidateQueries({ queryKey: ["calculator", calculatorId] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleDelete = () => {
    deleteCalculatorAdditionalCostMutation.mutate({ calculatorAdditionalCostId: cost.id, calculatorId });
  };

  return (
    <Card className="w-full h-fit break-inside-avoid">
      <CardHeader className="flex flex-row justify-between">
        <div className="flex flex-col gap-1.5">
          <CardTitle>{cost.name}</CardTitle>
          <CardDescription>{CalculatorAdditionalCostType[cost.type].name}</CardDescription>
        </div>
        <div className="flex flex-row justify-end items-start">
          <CalculatorAdditionalCostEditDialog cost={cost} />
          <DeleteAlertDialog
            trigger={
              <Button
                leftIcon={<Trash size={16} />}
                title="Usuń koszt"
                variant="outline"
                isLoading={deleteCalculatorAdditionalCostMutation.isPending}
              />
            }
            onConfirm={handleDelete}
            message="Czy na pewno chcesz usunąć ten koszt? Tej operacji nie można cofnąć"
          />
        </div>
      </CardHeader>
      <CardContent>
        {cost.type === CalculatorAdditionalCostType.CONDITIONAL.value && cost.condition && (
          <div className="flex flex-row gap-1 items-center flex-wrap">
            <p className="flex flex-row items-center font-medium">{i18n.t("Jeżeli")}</p>
            {(cost.condition.value || cost.condition.operator) && (
              <p className="font-medium whitespace-nowrap">
                {i18n.t("calculatorFields." + cost.condition.attribute).toLowerCase()}
              </p>
            )}
            <p className="font-medium whitespace-nowrap text-primary border py-0.5 px-1 rounded-md">
              {cost.condition.field}
            </p>
            {!cost.condition.value && !cost.condition.operator && (
              <p className="font-medium whitespace-nowrap">
                {i18n.t("calculatorFields." + cost.condition.attribute).toLowerCase()}
              </p>
            )}
            {(cost.condition.value || cost.condition.operator) && (
              <div className="flex flex-row gap-1 items-center">
                {cost.condition.operator && <p className="font-medium whitespace-nowrap">{cost.condition.operator}</p>}
                {cost.condition.value && <p className="font-medium whitespace-nowrap">{cost.condition.value}</p>}
              </div>
            )}
            <div className="flex flex-row w items-center">{i18n.t("to wtedy")}</div>
            <div className="font-medium whitespace-nowrap">{i18n.t("doliczenie")}</div>
            <div className="font-medium whitespace-nowrap border py-0.5 px-1 rounded-md text-primary">
              {formatMoney(cost.condition.price)}
            </div>
          </div>
        )}
        {cost.type === CalculatorAdditionalCostType.RANGE.value && cost.range_conditions && (
          <div className="flex flex-col gap-1">
            <div className="flex flex-row gap-1 items-center">
              <p className="flex flex-row w items-center font-medium">{i18n.t("Jeżeli wartość pola")}</p>
              <p className="font-medium border py-1 px-1 rounded-md text-primary">{cost.range_conditions.field}</p>
            </div>
            <div className="flex flex-col">
              {cost.range_conditions.ranges.map((range, i) => (
                <div key={"range" + i} className="flex flex-row gap-2 items-center">
                  <p>{i18n.t("W zakresie od")}</p>
                  <p className="font-medium border py-1 px-1 rounded-md text-primary">{range.from}</p>
                  <p>{i18n.t("do")}</p>
                  <p className="font-medium border py-1 px-1 rounded-md text-primary">{range.to}</p>
                  <p>{i18n.t("to wtedy doliczenie")}</p>
                  <p className="font-medium border py-1 px-1 rounded-md text-primary">{formatMoney(range.price)}</p>
                </div>
              ))}
            </div>
          </div>
        )}
        {cost.type === CalculatorAdditionalCostType.FIXED.value && (
          <div className="flex flex-row gap-1 items-center">
            <div className="font-medium">{i18n.t("Doliczenie")}</div>
            <div className="font-medium border py-1 px-1 rounded-md text-primary">{formatMoney(cost.price)}</div>
          </div>
        )}
        {cost.type === CalculatorAdditionalCostType.DYNAMIC.value && (
          <div className="flex flex-row gap-1 items-center">
            <div className="font-medium">{i18n.t("Doliczenie")}</div>
            <div className="font-medium border py-1 px-1 rounded-md text-primary">{cost.calculation_formula}</div>
          </div>
        )}
      </CardContent>
    </Card>
  );
};
