import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Layout } from "@/components/layout/Layout";
import { ProductsActualDemandTable } from "@/components/Product/ProductsActualDemandTable";
import { ProductsPotentialDemandTable } from "@/components/Product/ProductsPotentialDemandTable";
import { ProductsTable } from "@/components/Product/ProductsTable";
import { ProductTypeCreateDialog } from "@/components/Product/Types/ProductTypeCreateDialog";
import { ProductTypesTable } from "@/components/Product/Types/ProductTypesTable";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { toast } from "@/components/ui/use-toast";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { useProductPolicy } from "@/policies/useProductPolicy";
import { routes } from "@/routes";
import { format } from "date-fns";
import { saveAs } from "file-saver";
import { FileInput, FileOutput, Plus } from "lucide-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

export const ProductsPage = () => {
  useDocumentTitle("Produkty");
  const navigate = useNavigate();
  const productPolicy = useProductPolicy();
  const { activeTab, setActiveTab } = useTabs("table");

  const [exportState, setExportState] = useState("idle");

  const handleCreate = () => {
    return navigate(routes.productCreate);
  };

  const handleExport = async () => {
    if (productPolicy.exportProducts()) {
      setExportState(() => "loading");
      const response = await apiClient.exportProducts();
      if (response.ok) {
        setExportState(() => "success");
        saveAs(response.data, "produkty_" + format(new Date(), "yyyy-MM-dd_HH-mm-ss"));
        toast({ title: <SuccessToast title="Plik został pobrany" /> });
      } else {
        setExportState(() => "idle");
        toast({ title: <ErrorToast title="Błąd podczas pobierania pliku" /> });
      }
    }
  };

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {activeTab === "table" && productPolicy.create() && (
            <Button variant="outline" title="Dodaj produkt" leftIcon={<Plus size={20} />} onClick={handleCreate} />
          )}
          {activeTab === "table" && productPolicy.exportProducts() && (
            <Button
              title="Eksport"
              variant="outline"
              leftIcon={<FileOutput size={16} />}
              onClick={handleExport}
              isLoading={exportState === "loading"}
            />
          )}
          {activeTab === "table" && productPolicy.importProducts() && (
            <Button
              title="Import"
              variant="outline"
              leftIcon={<FileInput size={16} />}
              onClick={() => navigate(routes.productImport)}
            />
          )}
          {activeTab === "types" && <ProductTypeCreateDialog />}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        {productPolicy.create() && (
          <TabsList className="mb-6">
            <TabsTrigger value="table">{i18n.t("Tabela")}</TabsTrigger>
            {productPolicy.create() && (
              <TabsTrigger value="potential_demand">{i18n.t("Potencjalne zapotrzebowania")}</TabsTrigger>
            )}
            {productPolicy.create() && (
              <TabsTrigger value="actual_demand">{i18n.t("Faktyczne zapotrzebowania")}</TabsTrigger>
            )}
            {productPolicy.create() && <TabsTrigger value="types">{i18n.t("Typy produktów")}</TabsTrigger>}
          </TabsList>
        )}
        <TabsContent value="table">
          <ProductsTable />
        </TabsContent>
        {productPolicy.create() && (
          <TabsContent value="potential_demand">
            <ProductsPotentialDemandTable />
          </TabsContent>
        )}
        {productPolicy.create() && (
          <TabsContent value="actual_demand">
            <ProductsActualDemandTable />
          </TabsContent>
        )}
        {productPolicy.create() && (
          <TabsContent value="types">
            <ProductTypesTable />
          </TabsContent>
        )}
      </Tabs>
    </Layout>
  );
};
