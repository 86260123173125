const env = {
  api: {
    url: import.meta.env.VITE_API_URL || "http://localhost/api",
    timeout: Number(import.meta.env.VITE_API_TIMEOUT) || 20_000,
  },
  app: {
    url: import.meta.env.VITE_APP_URL || "http://localhost:5173",
    name: import.meta.env.VITE_APP_NAME || "EaseCRM",
    env: import.meta.env.VITE_APP_ENV || "local",
    branch: import.meta.env.VITE_APP_BRANCH || "develop",
  },
  sentry: {
    dsn: import.meta.env.VITE_SENTRY_DSN,
  },
  map: {
    startingPoint: import.meta.env.VITE_APP_MAP_STARTING_POINT
      ? String(import.meta.env.VITE_APP_MAP_STARTING_POINT).split(",")
      : [52.191097, 19.355406],
    zoom: import.meta.env.VITE_APP_MAP_ZOOM || 13,
  },
};

export const config = (key) => {
  const keys = key.split(".");
  let current = env;
  for (const key of keys) {
    if (!(key in current)) {
      throw new Error(`Missing config key: ${key}`);
    }
    current = current[key];
  }

  return current;
};
