import { BillingsTable } from "@/components/Billings/BillingsTable";
import { BillingGroupsTable } from "@/components/Billings/Groups/BillingGroupsTable";
import { BillingSettingCreateDialog } from "@/components/Billings/Settings/BillingSettingCreateDialog";
import { BillingSettingsTable } from "@/components/Billings/Settings/BillingSettingsTable";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { useBillingGroupPolicy } from "@/policies/billing/useBillingGroupPolicy";
import { useBillingPolicy } from "@/policies/billing/useBillingPolicy";
import { useBillingSettingPolicy } from "@/policies/billing/useBillingSettingPolicy";
import { routes } from "@/routes";
import { FilePlus, Plus } from "lucide-react";
import { useNavigate } from "react-router-dom";

export const BillingsPage = () => {
  useDocumentTitle("Rozliczenia");
  const navigate = useNavigate();
  const { activeTab, setActiveTab } = useTabs("billings");
  const billingPolicy = useBillingPolicy();
  const billingGroupPolicy = useBillingGroupPolicy();
  const billingSettingPolicy = useBillingSettingPolicy();

  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {activeTab === "billings" && billingPolicy.create() && (
            <Button
              title="Dodaj rozliczenie"
              variant="outline"
              leftIcon={<Plus size={20} />}
              onClick={() => {
                navigate(routes.billingCreate);
              }}
            />
          )}
          {activeTab === "billing_groups" && billingGroupPolicy.create() && (
            <Button
              title="Dodaj fakturę rozliczeniową"
              variant="outline"
              leftIcon={<FilePlus size={20} />}
              onClick={() => navigate(routes.billingGroupCreate)}
            />
          )}
          {activeTab === "billing_settings" && <BillingSettingCreateDialog />}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        {billingGroupPolicy.viewAny() && (
          <TabsList className="mb-6">
            <TabsTrigger value="billings">{i18n.t("Rozliczenia")}</TabsTrigger>
            {billingGroupPolicy.viewAny() && <TabsTrigger value="billing_groups">{i18n.t("Faktury")}</TabsTrigger>}
            {billingSettingPolicy.viewAny() && (
              <TabsTrigger value="billing_settings">{i18n.t("Modele rozliczeń")}</TabsTrigger>
            )}
          </TabsList>
        )}
        <TabsContent value="billings">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <BillingsTable />
          </div>
        </TabsContent>
        {billingGroupPolicy.viewAny() && (
          <TabsContent value="billing_groups">
            <div className="flex flex-col lg:flex-row w-full gap-5">
              <BillingGroupsTable />
            </div>
          </TabsContent>
        )}
        {billingSettingPolicy.viewAny() && (
          <TabsContent value="billing_settings">
            <div className="flex flex-col lg:flex-row w-full gap-5">
              <BillingSettingsTable />
            </div>
          </TabsContent>
        )}
      </Tabs>
    </Layout>
  );
};
