import apiClient from "@/api/apiClient";
import { DesktopMenu } from "@/components/layout/Navbar/DesktopMenu";
import { MobileMenu } from "@/components/layout/Navbar/MobileMenu";
import { useAuth } from "@/hooks/useAuth";
import { useApplicationPolicy } from "@/policies/application/useApplicationPolicy";
import { useAuditPolicy } from "@/policies/audit/useAuditPolicy";
import { useBillingPolicy } from "@/policies/billing/useBillingPolicy";
import { useContractPolicy } from "@/policies/contracts/useContractPolicy";
import { useFilePolicy } from "@/policies/file/useFilePolicy";
import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { useClientPolicy } from "@/policies/lead/useClientPolicy";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { useCalculatorPolicy } from "@/policies/useCalculatorPolicy";
import { useDashboardPolicy } from "@/policies/useDashboardPolicy";
import { useDepartmentPolicy } from "@/policies/useDepartmentPolicy";
import { useProductPolicy } from "@/policies/useProductPolicy";
import { useSurveyPolicy } from "@/policies/useSurveyPolicy";
import { useTaskPolicy } from "@/policies/useTaskPolicy";
import { useUserPolicy } from "@/policies/user/useUserPolicy";
import { useWarehousePolicy } from "@/policies/warehouse/useWarehousePolicy";
import { routes } from "@/routes";
import { useQuery } from "@tanstack/react-query";
import {
  Boxes,
  Calculator,
  Calendar,
  CircleCheck,
  CircleDollarSign,
  ClipboardList,
  ClipboardPen,
  Download,
  FileSignature,
  FileUser,
  Handshake,
  LayoutDashboard,
  Package,
  PackageSearch,
  Settings,
  User,
  Users,
  Wrench,
} from "lucide-react";
import { useEffect, useState } from "react";
import { FaFilterCircleDollar } from "react-icons/fa6";
import { LuWarehouse } from "react-icons/lu";
import { useLocation } from "react-router-dom";

export const Sidebar = () => {
  const { hasPermission } = useAuth();
  const departmentPolicy = useDepartmentPolicy();
  const userPolicy = useUserPolicy();
  const leadPolicy = useLeadPolicy();
  const contractPolicy = useContractPolicy();
  const taskPolicy = useTaskPolicy();
  const filePolicy = useFilePolicy();
  const dashboardPolicy = useDashboardPolicy();
  const warehousePolicy = useWarehousePolicy();
  const productPolicy = useProductPolicy();
  const billingPolicy = useBillingPolicy();
  const calculatorPolicy = useCalculatorPolicy();
  const installationPolicy = useInstallationPolicy();
  const auditPolicy = useAuditPolicy();
  const applicationPolicy = useApplicationPolicy();
  const clientPolicy = useClientPolicy();
  const surveyPolicy = useSurveyPolicy();
  const location = useLocation();

  const defaultCalculatorSubmenu = {
    title: "Ustawienia",
    visible: calculatorPolicy.create(),
    to: routes.calculatorsSettings,
    icon: (props) => <Settings {...props} />,
  };

  const [calculatorSubmenu, setCalculatorSubmenu] = useState([defaultCalculatorSubmenu]);

  const {
    data: calculators,
    isLoading: isLoadingCalculators,
    error: errorCalculators,
  } = useQuery({
    queryKey: ["calculators"],
    queryFn: apiClient.getCalculators,
    staleTime: 1000 * 60 * 5,
    enabled: calculatorPolicy.viewAny(),
  });

  const groupedMenuItems = {
    Główne: [
      {
        title: "Pulpit",
        groupName: "Główne",
        visible: dashboardPolicy.view(),
        to: routes.dashboard,
        icon: (props) => <LayoutDashboard {...props} />,
      },
      {
        title: "Oddziały",
        groupName: "Główne",
        visible: departmentPolicy.viewAny(),
        to: routes.departments,
        icon: (props) => <Users {...props} />,
      },
      {
        title: "Użytkownicy",
        groupName: "Główne",
        visible: userPolicy.viewAny(),
        to: routes.users,
        icon: (props) => <User {...props} />,
      },
      {
        title: "Klienci",
        groupName: "Główne",
        visible: clientPolicy.viewAny(),
        to: routes.clients,
        icon: (props) => <Handshake {...props} />,
      },
      {
        title: "Zadania",
        groupName: "Główne",
        visible: taskPolicy.viewAny(),
        to: routes.tasks,
        icon: (props) => <CircleCheck {...props} />,
      },
      {
        title: "Pliki",
        groupName: "Główne",
        visible: filePolicy.viewAny(),
        to: routes.files,
        icon: (props) => <Download {...props} />,
      },
      {
        title: "Kalendarz",
        groupName: "Główne",
        visible: hasPermission("access_calendar"),
        to: routes.calendar,
        icon: (props) => <Calendar {...props} />,
      },
    ],
    Sprzedaż: [
      {
        title: "Audyty",
        groupName: "Sprzedaż",
        visible: auditPolicy.viewAny(),
        to: routes.audits,
        icon: (props) => <ClipboardList {...props} />,
      },
      {
        title: "Kontakty",
        groupName: "Sprzedaż",
        visible: leadPolicy.viewAny(),
        to: routes.leads,
        icon: FaFilterCircleDollar,
      },
      {
        title: "Rozliczenia",
        groupName: "Sprzedaż",
        visible: billingPolicy.viewAny(),
        to: routes.billings,
        icon: (props) => <CircleDollarSign {...props} />,
      },
      {
        title: "Kalkulatory",
        groupName: "Sprzedaż",
        visible: (calculatorPolicy.viewAny() && calculators?.data?.length > 0) || calculatorPolicy.viewAll(),
        icon: (props) => <Calculator {...props} />,
        submenu: calculatorSubmenu,
        to: routes.calculators,
      },
      {
        title: "Ankiety",
        groupName: "Sprzedaż",
        visible: surveyPolicy.viewAny(),
        to: routes.surveys,
        icon: (props) => <ClipboardPen {...props} />,
      },
    ],
    Realizacja: [
      {
        title: "ERP",
        groupName: "Realizacja",
        visible: warehousePolicy.viewAny() || productPolicy.viewAny(),
        to: routes.erp,
        icon: (props) => <Boxes {...props} />,
        submenu: [
          {
            title: "Magazyny",
            visible: warehousePolicy.viewAny(),
            to: routes.warehouses,
            icon: LuWarehouse,
          },
          {
            title: "Produkty",
            visible: productPolicy.viewAny(),
            to: routes.products,
            icon: (props) => <Package {...props} />,
          },
          {
            title: "Zamówienia",
            visible: warehousePolicy.viewAny(),
            to: routes.orders,
            icon: (props) => <PackageSearch {...props} />,
          },
        ],
      },
      {
        title: "Umowy",
        groupName: "Realizacja",
        visible: contractPolicy.viewAny(),
        to: routes.contracts,
        icon: (props) => <FileSignature {...props} />,
      },
      {
        title: "Wnioski",
        groupName: "Realizacja",
        visible: applicationPolicy.viewAny(),
        to: routes.applications,
        icon: (props) => <FileUser {...props} />,
      },
      {
        title: "Montaże i serwisy",
        groupName: "Realizacja",
        visible: installationPolicy.viewAny(),
        to: routes.installationsAndServices,
        icon: (props) => <Wrench {...props} />,
      },
    ],
  };

  const getActive = (item) => {
    let isActive = `/${location.pathname.split("/")[1]}` === item.to;
    if (item.submenu) {
      const pathname = location.pathname.split("/")[1];
      const index = `/${pathname}`.search(item.to ?? null);
      isActive = index >= 0;
    }
    if (item.parent) {
      const uuid = `${location.pathname.split("/")[2]}`;
      if (uuid.length === 36) {
        isActive = `${location.pathname}` === item.to;
      }
    }
    return isActive;
  };

  useEffect(() => {
    if (calculators && !isLoadingCalculators && !errorCalculators) {
      setCalculatorSubmenu([
        defaultCalculatorSubmenu,
        ...calculators.data.map((calculator) => ({
          title: calculator.name,
          visible: calculatorPolicy.viewAny(),
          to: `${routes.calculators}/${calculator.id}`,
          icon: (props) => <Calculator {...props} />,
          parent: routes.calculators,
        })),
      ]);
    }
  }, [calculators, isLoadingCalculators, errorCalculators]);

  return (
    <>
      <DesktopMenu groupedMenuItems={groupedMenuItems} getActive={getActive} />
      <MobileMenu groupedMenuItems={groupedMenuItems} getActive={getActive} />
    </>
  );
};
