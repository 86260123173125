import { CalculationFieldsSummary } from "@/components/Calculations/CalculationDetails/CalculationFieldsSummary";
import { CalculationPriceSummaryCardContent } from "@/components/Calculators/CalculationPriceSummaryCardContent";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import i18n from "@/i18n";
import { useCalculationPolicy } from "@/policies/useCalculationPolicy";

export const CalculationDetails = ({ calculation }) => {
  const calculationPolicy = useCalculationPolicy();

  return (
    <div className="columns-1 lg:columns-2 gap-x-5">
      <CalculationFieldsSummary calculation={calculation} />
      {calculationPolicy.view(calculation) ? (
        <Card className="w-full h-fit break-inside-avoid mb-5">
          <CardHeader>
            <CardTitle>{i18n.t("Podsumowanie")}</CardTitle>
            <CardDescription>{i18n.t("Kwoty oraz pola dodatkowe wyliczone podczas kalkulacji")}</CardDescription>
          </CardHeader>
          <CardContent>
            <CalculationPriceSummaryCardContent calculator={calculation.calculator} />
          </CardContent>
        </Card>
      ) : (
        <div className="flex-1"></div>
      )}
    </div>
  );
};
