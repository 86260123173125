import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { PostalCodeFilter } from "@/components/Filters/PostalCodeFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { SelectFilter } from "@/components/Filters/SelectFilter";
import { VoivodeshipFilter } from "@/components/Filters/VoivodeshipFilter";
import { ContractProductContext } from "@/context/ContractProductContext";
import { ContractStatusContext } from "@/context/ContractStatusContext";
import { UsersContext } from "@/context/UsersContext";
import { ContractPaymentType } from "@/enums/ContractPaymentTypeEnum";
import { useFilters } from "@/hooks/table/useFilters";
import { useLeadPolicy } from "@/policies/lead/useLeadPolicy";
import { useContext } from "react";

export const ContractFilters = ({ table, withColumns = true }) => {
  const leadPolicy = useLeadPolicy();
  const { filter, setFilter } = useFilters(table);
  const { statusOptions, isLoading } = useContext(ContractStatusContext);
  const { productOptions, isLoading: isLoadingProducts } = useContext(ContractProductContext);
  const { userOptions, isLoading: isLoadingUsers } = useContext(UsersContext);

  return (
    <div className="flex flex-row flex-wrap gap-3 mb-3">
      <div className="flex flex-row gap-3 w-full lg:max-w-sm">
        <ClearFiltersButton table={table} />
        <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
      </div>
      <MultipleSelectFilter
        options={statusOptions}
        setValue={(value) => setFilter("status", value)}
        title="Status"
        isLoading={isLoading}
        value={filter("status")}
      />
      <VoivodeshipFilter filter={filter} setFilter={setFilter} />
      <PostalCodeFilter value={filter("postalCode")} onChange={(value) => setFilter("postalCode", value)} />
      <MultipleSelectFilter
        options={productOptions}
        isLoading={isLoadingProducts}
        setValue={(value) => setFilter("products", value)}
        title="Produkt"
        value={filter("products")}
      />
      <MultipleSelectFilter
        options={ContractPaymentType.getValues()}
        title="Typ płatności"
        value={filter("payment_type")}
        setValue={(value) => setFilter("payment_type", value)}
      />
      <SelectFilter
        title="Postęp płatności"
        value={filter("payment_progress")}
        setValue={(value) => setFilter("payment_progress", value)}
        options={[
          { name: "Nieopłacona", value: "NOT_PAID" },
          { name: "Częściowo opłacona", value: "PARTIALLY_PAID" },
          { name: "Opłacona w całości", value: "PAID" },
        ]}
      />
      {leadPolicy.assignUserLeads() && (
        <SelectFilter
          options={userOptions}
          isLoading={isLoadingUsers}
          setValue={(value) => setFilter("creator", value)}
          title="Użytkownik"
          value={filter("creator")}
        />
      )}
      {withColumns && <ColumnFilter table={table} />}
    </div>
  );
};
