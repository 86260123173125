import { OrderFilesTable } from "@/components/Order/OrderDetails/Files/OrderFilesTable";
import { OrderItemsCard } from "@/components/Order/OrderDetails/Items/OrderItemsCard";
import { OrderInformationCard } from "@/components/Order/OrderDetails/OrderInformationCard";
import { OrderNotesCard } from "@/components/Order/OrderDetails/OrderNotesCard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import i18n from "@/i18n";
import { useOrderFilePolicy } from "@/policies/order/useOrderFilePolicy";
import { useOrderNotePolicy } from "@/policies/order/useOrderNotePolicy";

export const OrderDetails = ({ order, activeTab, setActiveTab }) => {
  const orderNotePolicy = useOrderNotePolicy();
  const orderFilePolicy = useOrderFilePolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      {orderFilePolicy.viewAny() && (
        <TabsList className="mb-6">
          <TabsTrigger value="information">{i18n.t("Informacje")}</TabsTrigger>
          {orderFilePolicy.viewAny() && <TabsTrigger value="files">{i18n.t("Pliki")}</TabsTrigger>}
        </TabsList>
      )}
      <TabsContent value="information" className="flex flex-col gap-5">
        <div className="flex flex-col lg:flex-row w-full gap-5">
          <OrderInformationCard order={order} />
          <div className="flex flex-1 flex-col gap-5 h-fit">{orderNotePolicy.viewAny() && <OrderNotesCard />}</div>
        </div>
        <OrderItemsCard order={order} />
      </TabsContent>
      {orderFilePolicy.viewAny() && (
        <TabsContent value="files">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <OrderFilesTable key="files_list" />
          </div>
        </TabsContent>
      )}
    </Tabs>
  );
};
