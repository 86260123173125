import apiClient from "@/api/apiClient";
import { ProductActualDemandFilters } from "@/components/Product/ProductActualDemandFilters";
import { Table } from "@/components/Table/Table";
import { useProductActualDemandTableColumns } from "@/hooks/table/useProductActualDemandTableColumns";
import { useQuery } from "@tanstack/react-query";
import { getCoreRowModel, useReactTable } from "@tanstack/react-table";
import { useMemo, useState } from "react";

export const ProductsActualDemandTable = () => {
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });

  const {
    isLoading,
    isFetching,
    data: response,
  } = useQuery({
    queryKey: ["productActualDemands", pagination, sorting, columnFilters],
    queryFn: () => apiClient.getProductsActualDemand({ pagination, sorting, columnFilters }),
    staleTime: 1000 * 60 * 5,
  });

  const { columns } = useProductActualDemandTableColumns();
  const memoColumns = useMemo(() => columns, [columns]);

  const table = useReactTable({
    data: isLoading ? [] : response.data,
    columns: memoColumns,
    getCoreRowModel: getCoreRowModel(),
    rowCount: response?.meta?.total ?? response?.data?.length,
    onPaginationChange: setPagination,
    manualPagination: true,
    onSortingChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setSorting((old) => (updater instanceof Function ? updater(old) : updater));
    },
    onColumnFiltersChange: (updater) => {
      setPagination({ pageIndex: 0, pageSize: pagination.pageSize });
      setColumnFilters((old) => (updater instanceof Function ? updater(old) : updater));
    },
    state: {
      pagination,
      sorting,
      columnFilters,
    },
  });

  return (
    <Table
      table={table}
      Filters={ProductActualDemandFilters}
      meta={response?.meta}
      isFetching={isFetching}
      isLoading={isLoading}
    />
  );
};
