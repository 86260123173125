/* eslint-disable no-useless-escape */
import { CalculatorAdditionalCostType } from "@/enums/CalculatorAdditionalCostTypeEnum";
import { CalculatorFieldType } from "@/enums/CalculatorFieldTypeEnum";
import { evaluateCondition } from "@/helpers/calculators/evaluateCondition";
import { isEmpty } from "@/helpers/isEmpty";
import { evaluate } from "mathjs";

class CalculatorBuilder {
  constructor(calculator, products, customFields) {
    this.calculator = calculator;
    this.products = products;
    this.customFields = customFields;
    this.fields = calculator.sections.flatMap((section) => section.fields);
    this.calculationContext = {};
  }

  resetContext() {
    this.calculationContext = {};
  }

  setValues(values) {
    this.resetContext();
    this.calculator.sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (values[field.identifier] !== undefined) {
          field.value = values[field.identifier];
        }
        if (field.has_quantity && values[`${field.identifier}_quantity`] !== undefined) {
          field.quantity = values[`${field.identifier}_quantity`];
        } else {
          field.quantity = 1;
        }
      });
    });
    if (values.grants?.length > 0) {
      this.calculator.grants.forEach((grant, index) => {
        grant.applied = !!values.grants[index];
      });
    }
    return this;
  }

  setCalculatedPrices() {
    this.calculator.sections.forEach((section) => {
      section.fields.forEach((field) => {
        field.calculatedPrice = this.getFieldPrice(field);
      });
    });
    return this;
  }

  setMargins() {
    this.calculator.sections.forEach((section) => {
      section.fields.forEach((field) => {
        field.marginValue = this.getMarginValue(field);
      });
    });
    return this;
  }

  getMarginValue(field) {
    if (!field.margin || !field.calculatedPrice) return 0;
    return field.margin ? field.calculatedPrice * (field.margin / 100) : 0;
  }

  getFieldPrice(field) {
    let basePrice;
    if (field.calculation_formula) {
      return this.evaluateCalculationFormula(field.calculation_formula);
    }
    if (!field.value || !field.calculable) return 0;

    switch (field.type) {
      case CalculatorFieldType.LIST.value: {
        if (field?.field_options) {
          const option = field.field_options.find((option) => option.name === field.value);
          basePrice = option ? parseFloat(option.value) : 0;
        }
        break;
      }
      case CalculatorFieldType.MULTIPLE.value: {
        if (field?.field_options) {
          const options = field.field_options.filter((option) => field.value.includes(option.name));
          basePrice = options.reduce((acc, current) => acc + parseFloat(current.value), 0);
        }
        break;
      }
      case CalculatorFieldType.ADDITIONAL.value: {
        const options = field.value;
        basePrice = options.reduce((acc, current) => acc + parseFloat(current.value), 0);
        if (!basePrice) basePrice = 0;
        break;
      }
      case CalculatorFieldType.PRODUCT.value: {
        const product = this.products.find((product) => product.id === field.value);
        console.log(product);
        basePrice = product ? parseFloat(product.price) : 0;
        break;
      }
      case CalculatorFieldType.NUMBER.value:
        basePrice = field.price ? parseFloat(field.value * field.price) : parseFloat(field.value);
        break;
      case CalculatorFieldType.COMISSION.value:
        basePrice = parseFloat(field.value ?? 0);
        break;
      case CalculatorFieldType.VAT.value:
        basePrice = 0;
        break;
      default:
        throw new Error(`Unhandled field type ${field.type}`);
    }

    basePrice *= 1 + field.multiplier / 100;
    return basePrice * (field.quantity || 1);
  }

  evaluateCalculationFormula(formula) {
    const variables = this.buildCalculationContext();
    try {
      return evaluate(formula, variables);
    } catch (error) {
      console.error(`Error evaluating formula ${formula}:`, error);
      return 0;
    }
  }

  buildCalculationContext() {
    if (isEmpty(this.calculationContext)) {
      this.fields.forEach((field) => {
        this.calculationContext[field.identifier] = field.value ?? 0;
        this.calculationContext[`${field.identifier}_ilosc`] = field.quantity ?? 1;
        this.calculationContext[`${field.identifier}_cena`] = field.price ?? 0;
      });
      Object.entries(this.customFields).forEach(([key, value]) => {
        this.calculationContext[key] = value.calculate ? value.calculate(this.fields) : value;
      });
    }
    return this.calculationContext;
  }

  getFieldValueFromContext(identifier, attribute) {
    this.buildCalculationContext();
    const context = this.calculationContext;

    if (attribute === "has_value") {
      return context[key] !== null && context[key] !== undefined && context[key] !== "null";
    }

    if (attribute === "doesnt_have_value") {
      return context[key] === null || context[key] === undefined || context[key] === "null";
    }

    const key = attribute === "quantity" ? `${identifier}_quantity` : identifier;
    return context[key] ?? null;
  }

  calculateAdditionalCosts() {
    this.calculator.additionalCosts.forEach((cost) => {
      switch (cost.type) {
        case CalculatorAdditionalCostType.FIXED.value: {
          const price = cost.price;
          cost.calculatedPrice = price;
          if (cost.margin) {
            cost.marginValue = cost.calculatedPrice * (cost.margin / 100);
          } else {
            cost.marginValue = 0;
          }
          break;
        }
        case CalculatorAdditionalCostType.DYNAMIC.value: {
          const price = this.evaluateCalculationFormula(cost.calculation_formula);
          cost.calculatedPrice = price;
          if (cost.margin) {
            cost.marginValue = cost.calculatedPrice * (cost.margin / 100);
          } else {
            cost.marginValue = 0;
          }
          break;
        }
        case CalculatorAdditionalCostType.CONDITIONAL.value: {
          const { attribute, field, operator, price, value } = cost.condition;
          const fieldValue = this.getFieldValueFromContext(field, attribute);

          if (fieldValue === null || fieldValue === undefined) {
            cost.calculatedPrice = 0;
            cost.marginValue = 0;
            return;
          }

          const meetsCondition = evaluateCondition(fieldValue, { operator, value });
          if (meetsCondition) {
            if (this.isCalculationFormula(price)) {
              cost.calculatedPrice = this.evaluateCalculationFormula(price);
            } else {
              cost.calculatedPrice = parseFloat(price);
            }
            cost.marginValue = cost.calculatedPrice * (cost.margin / 100 || 0);
          } else {
            cost.calculatedPrice = 0;
            cost.marginValue = 0;
          }
          break;
        }
        case CalculatorAdditionalCostType.RANGE.value: {
          const { field, ranges } = cost.range_conditions;
          const dependentValue = this.getFieldValueFromContext(field, "value");

          if (dependentValue !== null && dependentValue !== undefined) {
            const matchedRange = ranges.find(({ from, to }) => {
              const fromValue = parseFloat(from);
              const toValue = parseFloat(to);
              return dependentValue > fromValue && dependentValue <= toValue;
            });

            if (matchedRange) {
              if (this.isCalculationFormula(matchedRange.price)) {
                cost.calculatedPrice = this.evaluateCalculationFormula(matchedRange.price);
              } else if (matchedRange.price) {
                if (matchedRange.price < 1) {
                  cost.calculatedPrice = this.calculator.netPrice * matchedRange.price;
                } else {
                  cost.calculatedPrice = parseFloat(matchedRange.price) || 0;
                }
              }
              cost.marginValue = cost.calculatedPrice * (cost.margin / 100 || 0);
            } else {
              cost.calculatedPrice = 0;
              cost.marginValue = 0;
            }
          } else {
            cost.calculatedPrice = 0;
            cost.marginValue = 0;
          }
          break;
        }
        default:
          break;
      }
    });
    return this;
  }

  setProductBundles() {
    this.calculator.sections.forEach((section) => {
      section.fields.forEach((field) => {
        if (field.type === CalculatorFieldType.PRODUCT.value && field.value) {
          const product = this.products.find((p) => p.id === field.value);
          if (product?.productBundles?.length > 0) {
            field.bundledProducts = product.productBundles
              .map(({ bundledProduct }) => {
                const bundledProductData = this.products.find((p) => p.id === bundledProduct.id);
                if (!bundledProductData) return null;
                const bundledProductPrice = this.getFieldPrice({
                  type: CalculatorFieldType.PRODUCT.value,
                  value: bundledProductData.id,
                  quantity: field.quantity,
                  calculable: field.calculable,
                  multiplier: 0,
                });

                const bundledField = {
                  name: bundledProductData.name,
                  value: bundledProductData.id,
                  quantity: field.quantity,
                  calculable: field.calculable,
                  calculatedPrice: bundledProductPrice,
                  marginValue: this.getMarginValue({
                    calculatedPrice: bundledProductPrice,
                    margin: field.margin,
                  }),
                };
                return bundledField;
              })
              .filter(Boolean);
          }
        }
      });
    });
    return this;
  }

  isCalculationFormula(price) {
    const formulaPattern = /[\+\-\*/\s]/;
    return typeof price === "string" && formulaPattern.test(price);
  }

  getFields() {
    return this.calculator.sections.flatMap((section) =>
      section.fields.flatMap((field) => [field, ...(field.bundledProducts || [])]),
    );
  }

  setTotals() {
    const fields = this.getFields();
    console.log(fields);
    const additionalCosts = this.calculator.additionalCosts;

    let netPrice = fields
      .filter((field) => field.calculable)
      .reduce((sum, field) => sum + (field.calculatedPrice + field.marginValue) || 0, 0);
    netPrice += additionalCosts.reduce((sum, cost) => sum + (cost.calculatedPrice + cost.marginValue) || 0, 0);

    let marginValue = fields.reduce((sum, field) => sum + field.marginValue, 0);
    marginValue += additionalCosts.reduce((sum, cost) => sum + cost.marginValue, 0);

    const vatField = fields.find((field) => field.type === CalculatorFieldType.VAT.value) ?? 0;
    const comissionField = fields.find((field) => field.type === CalculatorFieldType.COMISSION.value);

    this.calculator.netPrice = parseFloat(netPrice.toFixed(2));
    this.calculator.marginValue = parseFloat(marginValue.toFixed(2));
    this.calculator.vat = vatField ? parseInt(vatField.value) : 0;
    this.calculator.comission = comissionField ? parseFloat(comissionField.calculatedPrice.toFixed(2)) : 0;
    this.calculator.grossPrice = vatField ? netPrice * (1 + parseInt(vatField.value) / 100) : netPrice;
    this.calculator.grossPrice = parseFloat(this.calculator.grossPrice.toFixed(2));

    return this;
  }

  setCustomFields() {
    this.calculator.customFields = Object.keys(this.customFields).reduce((acc, key) => {
      const field = this.customFields[key];
      acc[key] = {
        name: field.name,
        value: field.value || field.calculate(this.getFields()),
        unit: field.unit,
        visible: field.visible,
        productType: field.productType,
      };
      return acc;
    }, {});

    return this;
  }

  applyDiscounts() {
    this.calculator.discounts.forEach((discount) => {
      const { field, ranges } = discount.range_conditions;
      const dependentValue = this.getFieldValueFromContext(field, "value");
      discount.applied = false;

      if (dependentValue !== null && dependentValue !== undefined) {
        const matchedRange = ranges.find(({ from, to }) => {
          const fromValue = parseFloat(from);
          const toValue = parseFloat(to);
          return dependentValue > fromValue && dependentValue <= toValue;
        });

        if (matchedRange) {
          if (matchedRange.amount < 1) {
            discount.calculatedPrice = this.calculator.netPrice * matchedRange.amount;
          } else {
            discount.calculatedPrice = parseFloat(matchedRange.amount) || 0;
          }
          if (this.calculator.marginValue - discount.calculatedPrice > 0)
            this.calculator.marginValue = this.calculator.marginValue - discount.calculatedPrice;
          else this.calculator.marginValue = 0;
          discount.applied = true;
        } else {
          discount.calculatedPrice = 0;
        }
      } else {
        discount.calculatedPrice = 0;
      }
      if (discount.applied) {
        this.calculator.netPrice -= discount.calculatedPrice;
        this.calculator.grossPrice = this.calculator.netPrice * (1 + this.calculator.vat / 100);
      }
    });
    return this;
  }

  calculateGrants() {
    let grossPriceAfterGrants = this.calculator.grossPrice;

    const groupedGrants = this.calculator.grants
      .filter((grant) => grant.applied)
      .reduce((acc, grant) => {
        if (!acc[grant.name]) {
          acc[grant.name] = { ...grant, max_amount: grant.max_amount, calculatedPrice: 0 };
        } else {
          acc[grant.name].max_amount += grant.max_amount;
        }
        return acc;
      }, {});

    Object.values(groupedGrants).forEach((grant) => {
      let calculatedPrice = 0;

      if (grant.type === "PERCENT") {
        const percentageValue = (grossPriceAfterGrants * grant.value) / 100;
        if (grant.max_amount) {
          calculatedPrice = Math.min(percentageValue, grant.max_amount);
        } else {
          calculatedPrice = percentageValue;
        }
      } else if (grant.type === "FIXED") {
        if (grant.max_amount) {
          calculatedPrice = Math.min(grant.value, grant.max_amount);
        } else {
          calculatedPrice = grant.value;
        }
      }

      grant.calculatedPrice = calculatedPrice;

      grossPriceAfterGrants -= calculatedPrice;
    });

    this.calculator.groupedGrants = groupedGrants;

    this.calculator.grants.forEach((grant) => {
      const groupedGrant = groupedGrants[grant.name];
      if (groupedGrant) {
        grant.calculatedPrice = groupedGrant.calculatedPrice;
      }
    });

    this.calculator.grossPriceAfterGrants = grossPriceAfterGrants;

    return this;
  }

  build() {
    return this.calculator;
  }
}

export default CalculatorBuilder;
