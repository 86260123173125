import { UserRole } from "@/enums/UserRoleEnum";
import { useAuth } from "@/hooks/useAuth";

export const useUserPolicy = () => {
  const { hasPermission, hasAnyPermission, user: authUser, userHasRole } = useAuth();

  const viewAny = () => {
    return hasAnyPermission(["access_all_users", "access_department_users"]);
  };

  const view = (user) => {
    if (hasPermission("show_any_user")) return true;
    if (hasPermission("show_department_user")) return user?.department?.id === authUser?.department?.id;
    return authUser.id === user.id;
  };

  const create = () => {
    return hasAnyPermission(["create_any_user", "create_department_user"]);
  };

  const update = (user) => {
    if (authUser?.id === user?.id) {
      return false;
    }

    if (userHasRole(user, UserRole.SUPERADMIN.value)) {
      return false;
    }

    if (hasPermission("update_any_user")) {
      return true;
    }

    return hasPermission("update_department_user") && user?.department?.id === authUser?.department?.id;
  };

  const destroy = (user) => {
    if (authUser?.id === user?.id) {
      return false;
    }

    if (userHasRole(user, UserRole.SUPERADMIN.value)) {
      return false;
    }

    if (hasPermission("delete_any_user")) {
      return true;
    }

    return hasPermission("delete_department_user") && user?.department?.id === authUser?.department?.id;
  };

  const impersonate = (user) => {
    if (authUser?.id === user?.id) {
      return false;
    }

    if (userHasRole(user, [UserRole.SUPERADMIN.value, UserRole.ADMIN.value])) {
      return false;
    }

    return hasPermission("impersonate_users");
  };

  const assignLeads = () => {
    return hasPermission("assign_user_leads");
  };

  const viewCalendar = () => {
    return hasPermission("access_user_calendar");
  };

  const viewTasks = () => {
    return hasPermission("access_user_tasks");
  };

  const viewStats = () => {
    return hasPermission("access_user_stats");
  };

  return {
    viewAny,
    view,
    create,
    update,
    destroy,
    impersonate,
    assignLeads,
    viewCalendar,
    viewTasks,
    viewStats,
  };
};
