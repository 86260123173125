import apiClient from "@/api/apiClient";
import { Button } from "@/components/Button";
import { CalculatorFieldEditDialog } from "@/components/Calculators/Configuration/Fields/CalculatorFieldEditDialog";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { ErrorToast } from "@/components/toast/ErrorToast";
import { SuccessToast } from "@/components/toast/SuccessToast";
import { toast } from "@/components/ui/use-toast";
import { CalculatorFieldType } from "@/enums/CalculatorFieldTypeEnum";
import { capitalize } from "@/helpers/capitalize";
import i18n from "@/i18n";
import { useSortable } from "@dnd-kit/sortable";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Asterisk, Copy, GripVertical, Trash } from "lucide-react";
import { useParams } from "react-router-dom";

export const CalculatorField = ({ field }) => {
  const type = CalculatorFieldType[field.type];
  const { id: calculatorId } = useParams();
  const queryClient = useQueryClient();
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: field.id });

  const style = {
    transform: transform ? `translate(${transform.x}px, ${transform.y}px)` : undefined,
    transition,
  };

  const deleteCalculatorFieldMutation = useMutation({
    mutationFn: apiClient.deleteCalculatorField,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie usunięto" /> });
        queryClient.invalidateQueries({ queryKey: ["calculator", calculatorId] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const duplicateCalculatorFieldMutation = useMutation({
    mutationFn: apiClient.duplicateCalculatorField,
    onSuccess: (res) => {
      if (res.ok) {
        toast({ title: <SuccessToast title="Pomyślnie zduplikowano" /> });
        queryClient.invalidateQueries({ queryKey: ["calculator", calculatorId] });
      } else {
        toast({ title: <ErrorToast title="Coś poszło nie tak" /> });
      }
    },
  });

  const handleDuplicate = () => {
    duplicateCalculatorFieldMutation.mutate({ calculatorSectionId: field.section_id, calculatorFieldId: field.id });
  };

  const handleDelete = () => {
    deleteCalculatorFieldMutation.mutate({ calculatorSectionId: field.section_id, calculatorFieldId: field.id });
  };

  return (
    <div ref={setNodeRef} style={style} className="flex flex-row items-start justify-between first:pt-0 py-4">
      <div className="flex flex-col gap-3">
        <p
          className="flex flex-row gap-1 items-center cursor-move font-medium w-fit truncate"
          {...attributes}
          {...listeners}
        >
          <GripVertical size={16} className="shrink-0" />
          {capitalize(field.name)}
        </p>
        <span className="flex flex-row gap-2 items-center">
          {i18n.t("Identyfikator pola") + ":"}
          <p className="font-medium border py-1 px-2 rounded-md text-primary">{field.identifier}</p>
        </span>
        <div className="flex flex-row gap-2 items-center">
          <span className="flex flex-row items-center border rounded-md px-2 py-1">
            {<type.icon />}
            {type.name}
          </span>
          <span className="flex flex-row gap-1 items-center border rounded-md px-2 py-1">
            {field.required ? <Asterisk size={16} className="text-destructive" /> : null}
            {field.required ? i18n.t("Wymagane") : i18n.t("Niewymagane")}
          </span>
        </div>
      </div>
      <div className="flex flex-row gap-2 items-center">
        <Button
          leftIcon={<Copy size={16} />}
          variant="outline"
          tooltip="Duplikuj"
          onClick={handleDuplicate}
          isLoading={duplicateCalculatorFieldMutation.isPending}
        />
        <CalculatorFieldEditDialog field={field} />
        <DeleteAlertDialog
          message="Czy na pewno chcesz usunąć to pole? Tej operacji nie można cofnąć"
          onConfirm={handleDelete}
          trigger={
            <Button
              variant="outline"
              leftIcon={<Trash size={16} />}
              isLoading={deleteCalculatorFieldMutation.isPending}
            />
          }
        />
      </div>
    </div>
  );
};
