import apiClient from "@/api/apiClient";
import { Breadcrumbs } from "@/components/Breadcrumbs";
import { Button } from "@/components/Button";
import { DeleteAlertDialog } from "@/components/DeleteAlertDialog";
import { Layout } from "@/components/layout/Layout";
import { ProductDetails } from "@/components/Product/ProductDetails/ProductDetails";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useProductPolicy } from "@/policies/useProductPolicy";
import { routes } from "@/routes";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Pencil, Trash } from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";

export const ProductDetailsPage = () => {
  const { id: productId } = useParams();
  const navigate = useNavigate();
  const productPolicy = useProductPolicy();
  const queryClient = useQueryClient();

  const { isLoading, data: response } = useQuery({
    queryKey: ["product", productId],
    queryFn: () => apiClient.getProduct(productId),
  });
  useDocumentTitle(response?.data ? `Produkt ${response?.data?.name}` : null);

  const deleteProductMutation = useMutation({
    mutationFn: apiClient.deleteProduct,
    onSuccess: (res) => {
      queryClient.invalidateQueries({ queryKey: ["products"] });
      if (res.ok) {
        return navigate(routes.products);
      }
    },
  });

  const handleDelete = () => {
    if (productPolicy.destroy(response.data)) {
      deleteProductMutation.mutate(productId);
    }
  };

  const handleEdit = () => {
    if (productPolicy.update()) {
      return navigate(`${routes.products}/${productId}/edytuj`);
    }
  };

  return (
    <Layout isLoading={isLoading}>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs idAlias={response?.data?.name} />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {productPolicy.update() && (
            <Button variant="outline" title="Edytuj" leftIcon={<Pencil size={16} />} onClick={handleEdit} />
          )}
          {productPolicy.destroy(response?.data) && (
            <DeleteAlertDialog
              trigger={
                <Button
                  variant="outline"
                  title="Usuń produkt"
                  isLoading={deleteProductMutation.isPending}
                  leftIcon={<Trash size={16} />}
                />
              }
              onConfirm={handleDelete}
              message="Czy na pewno chcesz usunąć ten produkt? Tej operacji nie można cofnąć"
            />
          )}
        </div>
      </div>
      <ProductDetails product={response?.data} />
    </Layout>
  );
};
