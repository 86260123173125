import { Breadcrumbs } from "@/components/Breadcrumbs";
import { FileCategoriesTable } from "@/components/File/Categories/FileCategoriesTable";
import { FileCategoryCreateDialog } from "@/components/File/Categories/FileCategoryCreateDialog";
import { FileCreateDialog } from "@/components/File/FileCreateDialog";
import { FilesTable } from "@/components/File/FilesTable";
import { Layout } from "@/components/layout/Layout";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useDocumentTitle } from "@/hooks/useDocumentTitle";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { useFileCategoryPolicy } from "@/policies/file/useFileCategoryPolicy";
import { useFilePolicy } from "@/policies/file/useFilePolicy";

export const FilesPage = () => {
  useDocumentTitle("Pliki");
  const { activeTab, setActiveTab } = useTabs("table");
  const filePolicy = useFilePolicy();
  const fileCategoryPolicy = useFileCategoryPolicy();
  return (
    <Layout>
      <div className="flex flex-col md:flex-row justify-between gap-3 mb-5">
        <Breadcrumbs />
        <div className="grid grid-cols-2 md:flex md:flex-row md:auto-rows-fr gap-4 md:gap-3 md:my-0 mt-5 mb-5">
          {activeTab === "table" && <FileCreateDialog />}
          {activeTab === "categories" && <FileCategoryCreateDialog />}
        </div>
      </div>
      <Tabs value={activeTab} onValueChange={setActiveTab}>
        {(filePolicy.viewAny() || fileCategoryPolicy.update()) && (
          <TabsList className="mb-6">
            {filePolicy.viewAny() && <TabsTrigger value="table">{i18n.t("Tabela")}</TabsTrigger>}
            {fileCategoryPolicy.update() && <TabsTrigger value="categories">{i18n.t("Kategorie")}</TabsTrigger>}
          </TabsList>
        )}
        {filePolicy.viewAny() && (
          <TabsContent value="table">
            <div className="flex flex-col lg:flex-row w-full gap-5">
              <FilesTable />
            </div>
          </TabsContent>
        )}
        {fileCategoryPolicy.update() && (
          <TabsContent value="categories">
            <FileCategoriesTable />
          </TabsContent>
        )}
      </Tabs>
    </Layout>
  );
};
