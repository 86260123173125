import apiClient from "@/api/apiClient";
import { ClearFiltersButton } from "@/components/Filters/ClearFiltersButton";
import { ColumnFilter } from "@/components/Filters/ColumnFilter";
import { MultipleSelectFilter } from "@/components/Filters/MultipleSelectFilter";
import { PostalCodeFilter } from "@/components/Filters/PostalCodeFilter";
import { SearchFilter } from "@/components/Filters/SearchFilter";
import { SelectFilter } from "@/components/Filters/SelectFilter";
import { VoivodeshipFilter } from "@/components/Filters/VoivodeshipFilter";
import { appendEmptyOption } from "@/helpers/appendEmptyOption";
import { useFilters } from "@/hooks/table/useFilters";
import { useInstallationCrewPolicy } from "@/policies/installation/useInstallationCrewPolicy";
import { useInstallationPolicy } from "@/policies/installation/useInstallationPolicy";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

export const InstallationServiceFilters = ({ table }) => {
  const installationPolicy = useInstallationPolicy();
  const installationCrewPolicy = useInstallationCrewPolicy();
  const { filter, setFilter } = useFilters(table);

  const [contractOptions, setContractOptions] = useState([]);
  const [crewOptions, setCrewOptions] = useState([]);

  const {
    isLoading: isLoadingContracts,
    data: contracts,
    error: errorContracts,
  } = useQuery({
    queryKey: ["contracts"],
    queryFn: () => apiClient.getContracts({ hasInstallations: true }),
    staleTime: 1000 * 60 * 5,
    enabled: installationPolicy.viewAll(),
  });

  const {
    isLoading: isLoadingCrews,
    data: crews,
    error: errorCrews,
  } = useQuery({
    queryKey: ["installationCrews"],
    queryFn: apiClient.getInstallationCrews,
  });

  useEffect(() => {
    if (!isLoadingContracts && !errorContracts && contracts) {
      setContractOptions(
        contracts.data.map((contract) => ({
          name: `${contract.name} (${contract.identifier})`,
          value: contract.id,
        })),
      );
    }
    if (!isLoadingCrews && !errorCrews && crews) {
      setCrewOptions(crews.data.map((crew) => ({ name: crew.name, value: crew.id })));
    }
  }, [isLoadingContracts, errorContracts, contracts, isLoadingCrews, errorCrews, crews]);

  return (
    <div className="flex flex-row flex-wrap gap-3 mb-3">
      <div className="flex flex-row gap-3 w-full lg:max-w-sm">
        <ClearFiltersButton table={table} />
        <SearchFilter value={filter("search")} onChange={(value) => setFilter("search", value)} />
      </div>
      <VoivodeshipFilter filter={filter} setFilter={setFilter} />
      <PostalCodeFilter value={filter("postalCode")} onChange={(value) => setFilter("postalCode", value)} />
      {installationCrewPolicy.viewAny() && (
        <SelectFilter
          options={appendEmptyOption(crewOptions, "Brak ekipy")}
          setValue={(value) => setFilter("crew", value)}
          title="Ekipa"
          isLoading={isLoadingCrews}
          value={filter("crew")}
        />
      )}
      {installationPolicy.viewAll() && (
        <MultipleSelectFilter
          options={contractOptions}
          setValue={(value) => setFilter("contract", value)}
          title="Umowa"
          isLoading={isLoadingContracts}
          value={filter("contract")}
        />
      )}
      <ColumnFilter table={table} />
    </div>
  );
};
