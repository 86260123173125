import { InstallationFilesTable } from "@/components/Installations/InstallationDetails/Files/InstallationFilesTable";
import { InstallationActionsCard } from "@/components/Installations/InstallationDetails/InstallationActionsCard";
import { InstallationCrewCard } from "@/components/Installations/InstallationDetails/InstallationCrewCard";
import { InstallationInformationCard } from "@/components/Installations/InstallationDetails/InstallationInformationCard";
import { InstallationNotesCard } from "@/components/Installations/InstallationDetails/InstallationNotesCard";
import { InstallationProcessesCard } from "@/components/Installations/InstallationDetails/InstallationProcessesCard";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { useInstallationActionPolicy } from "@/policies/installation/useInstallationActionPolicy";
import { useInstallationCrewPolicy } from "@/policies/installation/useInstallationCrewPolicy";
import { useInstallationFilePolicy } from "@/policies/installation/useInstallationFilePolicy";
import { useInstallationNotePolicy } from "@/policies/installation/useInstallationNotePolicy";

export const InstallationDetails = ({ installation }) => {
  const { activeTab, setActiveTab } = useTabs("information");
  const installationNotePolicy = useInstallationNotePolicy();
  const installationFilePolicy = useInstallationFilePolicy();
  const installationActionPolicy = useInstallationActionPolicy();
  const installationCrewPolicy = useInstallationCrewPolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      {installationFilePolicy.viewAny() && (
        <TabsList className="mb-6">
          <TabsTrigger value="information">{i18n.t("Informacje")}</TabsTrigger>
          {installationFilePolicy.viewAny() && <TabsTrigger value="files">{i18n.t("Pliki")}</TabsTrigger>}
        </TabsList>
      )}
      <TabsContent value="information">
        <div className="flex flex-col lg:flex-row w-full gap-5">
          <InstallationInformationCard installation={installation} />
          <div className="flex flex-1 flex-col gap-5 h-fit">
            {installationActionPolicy.viewAny() && <InstallationActionsCard />}
            {installationNotePolicy.viewAny() && <InstallationNotesCard />}
          </div>
          <div className="flex flex-1 flex-col gap-5 h-fit">
            {installationCrewPolicy.viewAny() && <InstallationCrewCard installation={installation} />}
            <InstallationProcessesCard />
          </div>
        </div>
      </TabsContent>
      {installationFilePolicy.viewAny() && (
        <TabsContent value="files">
          <div className="flex flex-col lg:flex-row w-full gap-5">
            <InstallationFilesTable key="files_list" />
          </div>
        </TabsContent>
      )}
    </Tabs>
  );
};
