import { ContractFileCategories } from "@/components/Contracts/Settings/FileCategories/ContractFileCategories";
import { ContractFileCategoryCreateDialog } from "@/components/Contracts/Settings/FileCategories/ContractFileCategoryCreateDialog";
import { ContractProductCreateDialog } from "@/components/Contracts/Settings/Products/ContractProductCreateDialog";
import { ContractProducts } from "@/components/Contracts/Settings/Products/ContractProducts";
import { ContractStatusCreateDialog } from "@/components/Contracts/Settings/Statuses/ContractStatusCreateDialog";
import { ContractStatuses } from "@/components/Contracts/Settings/Statuses/ContractStatuses";
import { ContractTemplateCreateDialog } from "@/components/Contracts/Settings/Templates/ContractTemplateCreateDialog";
import { ContractTemplateInformationDialog } from "@/components/Contracts/Settings/Templates/ContractTemplateInformationDialog";
import { ContractTemplates } from "@/components/Contracts/Settings/Templates/ContractTemplates";
import { ProcessCreateDialog } from "@/components/Process/ProcessCreateDialog";
import { ProcessSettings } from "@/components/Process/ProcessSettings";
import { SettingsTable } from "@/components/Settings/SettingsTable";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SettingGroup } from "@/enums/SettingGroupEnum";
import { useSettings } from "@/hooks/useSettings";
import { useTabs } from "@/hooks/useTabs";
import i18n from "@/i18n";
import { useContractFileCategoryPolicy } from "@/policies/contracts/useContractFileCategoryPolicy";
import { useContractProductPolicy } from "@/policies/contracts/useContractProductPolicy";
import { useContractStatusPolicy } from "@/policies/contracts/useContractStatusPolicy";
import { useContractTemplatePolicy } from "@/policies/contracts/useContractTemplatePolicy";
import { useProcessPolicy } from "@/policies/useProcessPolicy";
import { useSettingPolicy } from "@/policies/useSettingPolicy";

export const ContractSettings = () => {
  const { activeTab, setActiveTab } = useTabs("statuses");
  const { getSettingGroup } = useSettings();
  const contractStatusPolicy = useContractStatusPolicy();
  const contractProductPolicy = useContractProductPolicy();
  const contractFileCategoryPolicy = useContractFileCategoryPolicy();
  const settingPolicy = useSettingPolicy();
  const processPolicy = useProcessPolicy();
  const contractTemplatePolicy = useContractTemplatePolicy();

  return (
    <Tabs value={activeTab} onValueChange={setActiveTab}>
      <TabsList className="mb-6">
        {contractStatusPolicy.create() && <TabsTrigger value="statuses">{i18n.t("Statusy")}</TabsTrigger>}
        {contractProductPolicy.create() && <TabsTrigger value="products">{i18n.t("Produkty")}</TabsTrigger>}
        {contractFileCategoryPolicy.create() && (
          <TabsTrigger value="file_categories">{i18n.t("Kategorie plików")}</TabsTrigger>
        )}
        {processPolicy.create() && <TabsTrigger value="processes">{i18n.t("Procesy")}</TabsTrigger>}
        {contractTemplatePolicy.viewAny() && <TabsTrigger value="templates">{i18n.t("Szablony")}</TabsTrigger>}
        {settingPolicy.update() && <TabsTrigger value="settings">{i18n.t("Pozostałe")}</TabsTrigger>}
      </TabsList>
      {contractStatusPolicy.create() && (
        <TabsContent value="statuses">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Statusy")}</h2>
            <ContractStatusCreateDialog />
          </div>
          <ContractStatuses />
        </TabsContent>
      )}
      {contractProductPolicy.create() && (
        <TabsContent value="products">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Produkty")}</h2>
            <ContractProductCreateDialog />
          </div>
          <ContractProducts />
        </TabsContent>
      )}
      {contractFileCategoryPolicy.create() && (
        <TabsContent value="file_categories">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Kategorie plików")}</h2>
            <ContractFileCategoryCreateDialog />
          </div>
          <ContractFileCategories />
        </TabsContent>
      )}
      {processPolicy.create() && (
        <TabsContent value="processes">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Procesy")}</h2>
            <ProcessCreateDialog appliesTo="Contract" />
          </div>
          <ProcessSettings model="Contract" />
        </TabsContent>
      )}
      {contractTemplatePolicy.viewAny() && (
        <TabsContent value="templates">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Szablony")}</h2>
            <div className="flex flex-row items-center gap-3">
              <ContractTemplateInformationDialog />
              <ContractTemplateCreateDialog />
            </div>
          </div>
          <ContractTemplates />
        </TabsContent>
      )}
      {settingPolicy.update() && (
        <TabsContent value="settings">
          <div className="flex flex-row justify-between w-full items-end mb-5">
            <h2 className="text-lg font-semibold leading-none tracking-tight">{i18n.t("Pozostałe ustawienia")}</h2>
          </div>
          <SettingsTable settings={getSettingGroup(SettingGroup.CONTRACT)} />
        </TabsContent>
      )}
    </Tabs>
  );
};
